import React, { useState, useEffect, useRef } from 'react';

const Calendar = () => {
  const daysOfWeek = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
  const dayAbbreviations = daysOfWeek.map(day => day.charAt(0));
  const months = [
    'January', 'February', 'March', 'April', 'May', 'June',
    'July', 'August', 'September', 'October', 'November', 'December'
  ];

  const [date, setDate] = useState(new Date());
  const [selectedDate, setSelectedDate] = useState(null);

  const daysInMonth = (year, month) => {
    return new Date(year, month + 1, 0).getDate();
  };

  const firstDayOfMonth = () => {
    const firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
    return firstDay.getDay();
  };

  const handlePrevMonth = () => {
    setDate(new Date(date.getFullYear(), date.getMonth() - 1, 1));
  };

  const handleNextMonth = () => {
    setDate(new Date(date.getFullYear(), date.getMonth() + 1, 1));
  };

  const isCurrentDate = (day) => {
    const today = new Date();
    return (
      day === today.getDate() &&
      date.getMonth() === today.getMonth() &&
      date.getFullYear() === today.getFullYear()
    );
  };

  const handleClickDay = (day) => {
    setSelectedDate(day);
  };

  const calendarRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (calendarRef.current && !calendarRef.current.contains(event.target)) {
        setSelectedDate(null);
      }
    };

    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  const renderDays = () => {
    const totalDays = daysInMonth(date.getFullYear(), date.getMonth());
    const firstDay = firstDayOfMonth();
    const days = [];

    for (let i = 0; i < firstDay; i++) {
      days.push(<div key={`empty-${i}`} className="rounded-lg bg-gray-200"></div>);
    }

    for (let day = 1; day <= totalDays; day++) {
      days.push(
        <div
          key={day}
          className={`calendar-day p-2 text-center 
            ${isCurrentDate(day) ? 'bg-moss-green' : ''} 
            ${selectedDate === day ? 'bg-moss-green' : ''} 
            rounded-lg cursor-pointer hover:bg-moss-green`}
          onClick={() => handleClickDay(day)}
        >
          {day}
        </div>
      );
    }

    return days;
  };

  return (
    <div className="bg-white rounded-lg" ref={calendarRef}>
      <div className="calendar-header bg-sovereign rounded-t-lg text-white p-2 flex justify-between items-center">
        <button onClick={handlePrevMonth}>&lt;</button>
        <h2>{months[date.getMonth()]} {date.getFullYear()}</h2>
        <button onClick={handleNextMonth}>&gt;</button>
      </div>
      <div className="calendar-days grid grid-cols-7 gap-1 px-1 pb-2">
        {dayAbbreviations.map((dayAbbr, index) => (
          <div key={dayAbbr} className={`calendar-day-header font-bold p-2 text-center ${index >= 1 ? 'hidden md:block' : ''} ${index >= 4 ? 'hidden lg:block' : ''}`}>
            {dayAbbr}
          </div>
        ))}
        {renderDays()}
      </div>
    </div>
  );
};

export default Calendar;
