import { faInfoCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import useAuth from '../hooks/useAuth'
import useAxiosPrivate from '../hooks/useAxiosPrivate'

function AppUpdate() {
  const { setShowUpdate, setNotNow } = useAuth()
  const axios = useAxiosPrivate()

  const updateApp = async () => {
    try {
      const response = await axios.get(`/users/run-update`)
      if (!response.data.error) {
        setShowUpdate(false)
        setNotNow(false)
        window.location.reload()
      }
    } catch (error) {}
  }

  const notNow = async () => {
    setShowUpdate(false)
    setNotNow(true)
  }

  return (
    <div
      className="fixed top-0 left-0 w-screen h-screen flex justify-center items-center"
      style={{ zIndex: 999 }}
    >
      <div className="bg-slate-700/50 w-full h-full">&nbsp;</div>
      <div className="flex flex-col absolute p-6 bg-white w-[550px] rounded-xl shadow-md">
        <span className="flex justify-center mb-6 text-blue-500">
          <FontAwesomeIcon icon={faInfoCircle} style={{ fontSize: 70 }} />
        </span>
        <h2 className="text-xl font-bold text-center pb-2">
          There is a new version of this app available. Click below to update
          now.
        </h2>
        <div className="mt-6 flex space-x-6 justify-center">
          <button className="btn green w-1/2" onClick={updateApp}>
            Update Now!
          </button>
          <button className="btn clear w-1/2" onClick={notNow}>
            Not Now
          </button>
        </div>
      </div>
    </div>
  )
}
export default AppUpdate
