import { faEyeSlash } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useEffect, useState } from 'react'
import useAuth from '../hooks/useAuth'
import useAxiosPrivate from '../hooks/useAxiosPrivate'

function SystemNotification({ notificationList, clearNotification }) {
  const { auth } = useAuth()
  const axios = useAxiosPrivate()

  let notifications = notificationList

  return (
    <section>
      {notifications.length > 0 ? (
        notifications.map((notification) => {
          return (
            <article
              key={notification.id}
              title="Click to mark as read"
              className={`article-item notification-list cursor-pointer hover:border-slate-600 ${notification.type.toLowerCase()}`}
              onClick={() => clearNotification(notification)}
            >
              <span className="absolute top-1 right-2 text-xs text-gray-400">
                <FontAwesomeIcon icon={faEyeSlash} />
              </span>
              <h3 className="text-lg font-semibold leading-5">
                {notification.type}: {notification.title}
              </h3>
              <hr className="mt-2" />
              <p className="text-md mt-2">{notification.body}</p>
            </article>
          )
        })
      ) : (
        <h2 className="mb-2">There are no notifications.</h2>
      )}
    </section>
  )
}

export default SystemNotification
