import {
  useNavigate,
  useParams,
  useLocation,
  useOutletContext,
} from 'react-router-dom'
import { useState, useRef, useEffect } from 'react'
import moment from 'moment'
import MainTitle from '../../components/MainTitle'
import Modal from '../../components/Modal'
import ClientNav from '../../components/ClientNav'
import useAxiosPrivate from '../../hooks/useAxiosPrivate'
import useAuth from '../../hooks/useAuth'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faChevronLeft,
  faTrashCan,
  faUpload,
} from '@fortawesome/free-solid-svg-icons'

const ViewQuote = () => {
  const navigation = useNavigate()
  const { setShowLoader } = useAuth()
  const [collapsed, setCollapsed] = useOutletContext()
  const { id, quoteId } = useParams()
  const location = useLocation()
  const axios = useAxiosPrivate()
  const [screenWidth, setScreenWidth] = useState(window.innerWidth)
  const [val, setVal] = useState('')
  const [originalRecords, setOriginalRecords] = useState([])
  const [clientData, setClientData] = useState({
    name: '',
    reference: 'WBA-1000',
  })
  const [occupationsList, setoccupationsList] = useState([])
  const [duration, setDuration] = useState(12)
  const [policyStart, setPolicyStart] = useState('')
  const [policyEnd, setPolicyEnd] = useState('')
  const [premium, setPremium] = useState(100)
  const currentdate = new Date()
  const [loaderIcon, setLoaderIcon] = useState(false)
  const datetime =
    currentdate.getDate() +
    '/' +
    (currentdate.getMonth() + 1) +
    '/' +
    currentdate.getFullYear() +
    ' @ ' +
    currentdate.getHours() +
    ':' +
    currentdate.getMinutes() +
    ':' +
    currentdate.getSeconds()

  const defaultQuoteItem = {
    breakdown: {
      base: 0,
      beforeAutoDiscount: 0,
      withAutoDiscount: 0,
      premium: 0,
      maternity: '0.00',
    },
    staffType: 'Accountant/Accountancy Assistant',
    staffGrade: '4',
    override: false,
    selected: true,
    benefit: 80,
    fte: '1.00',
    excess: 7,
    period: '--',
    stress: 30,
    maternity: '0.00',
    maternityCover: 'Excluded',
    premium: 100,
  }

  const isSmallScreen = screenWidth < 870
  const barPosition = isSmallScreen
    ? 'pl-0 mb-20'
    : collapsed
    ? 'ml-4 mr-4 mb-4'
    : 'ml-80 mr-4 mb-4'
  // Modal
  const [showModal, setShowModal] = useState(false)
  const [modalTitle, setModalTitle] = useState('')
  const [quoteItems, setQuoteItems] = useState([defaultQuoteItem])
  const [currentQuote, setCurrenQuote] = useState({
    product: 'SRM Policy',
    productMeta: '',
    team: '0',
    auth: {
      id: 0,
      name: '',
      request: '',
      status: '',
    },
    status: 'draft',
    type: 'single',
    department: 'SRM',
    owner: 'Online Form',
    referenceNumber: '0',
    schools: [
      {
        id: clientData.academy_id,
        name: clientData.academy_name,
        status: 'draft',
        acceptance: [],
        premiumSecondary: 0,
        discountReason: 'Claims',
        discountUnit: 'percent',
        loadingReason: 'Claims',
        loadingUnit: 'percent',
        loading: 0,
        discount: 0,
        premium: 0,
        duration: 12,
        ref: clientData.academy_urn,
        absences: [],
        coverStartDate: moment().format('YYYY-MM-DD'),
        additionalCover: '',
        coverEndDate: moment().format('YYYY-MM-DD'),
        autoDiscount: {
          total: 0,
          good_school: 0,
          good_area: 0,
          FTE_ratio: 0,
          MAT: 0,
        },
        absenceAverage: 100,
        items: quoteItems,
      },
    ],
    variations: [],
    variationLive: '0',
    MATpolicyManagement: '',
    MATcoverLevel: '',
    absences: [],
    marketing: {
      hearAboutUs: '--',
      hearAboutUsOther: '',
      currentInsurer: '',
      currentPremium: '',
      referralCode: '',
      nahtMember: false,
    },
    nahtMember: false,
    referralCode: '',
    discountReason: 'Claims',
    discountUnit: 'percent',
    loading: 0,
    loadingReason: 'Claims',
    loadingUnit: 'percent',
    matCreated: false,
    meta: {
      startDateTime: datetime,
      endDateTime: '',
      emailText: '',
      quoteText: '',
      notesPrivate: '',
      notesPublic: '',
    },
  })

  //drag and drop
  const wrapperRef = useRef(null)
  const [fileList, setFileList] = useState([])

  const toggleNewRow = (schoolIndex) => {
    if (currentQuote.schools.length === 0) return

    const tempArr = [...currentQuote.schools]
    tempArr[schoolIndex].items.push({
      breakdown: {
        base: 0,
        beforeAutoDiscount: 0,
        withAutoDiscount: 0,
        premium: 0,
        maternity: '0.00',
      },
      staffType: 'Accountant/Accountancy Assistant',
      staffGrade: '4',
      selected: true,
      benefit: 80,
      fte: '1.00',
      excess: '7',
      period: '--',
      stress: '30',
      maternity: '0.00',
      maternityCover: 'Excluded',
      premium: 100,
    })
    let total = 1
    let itemsLength = tempArr[schoolIndex].items.length
    let index = itemsLength - total
    setCurrenQuote({ ...currentQuote, schools: tempArr })
    calculatePremium(0, index, 'Accountant/Accountancy Assistant')
  }

  const updateCoverDates = (schoolIndex, value, type) => {
    let duration = 0
    const tempArr = [...currentQuote.schools]
    let start = moment(tempArr[schoolIndex].coverStartDate)
    let end = moment(tempArr[schoolIndex].coverEndDate)
    if (type === 'start') {
      start = moment(value)
    }

    if (type === 'end') {
      end = moment(value)
    }
    if (start && end) {
      duration = end.diff(start, 'months')
      tempArr[schoolIndex].duration = duration
      tempArr[schoolIndex].coverStartDate = start.format('YYYY-MM-DD')
    } else {
      duration = 12
      tempArr[schoolIndex].duration = duration
      tempArr[schoolIndex].coverStartDate = start.format('YYYY-MM-DD')
    }
    setDuration(duration)
    setPolicyStart(start.format('ddd MMM DD YYYY'))
    if (type === 'end') {
      setPolicyEnd(end.format('ddd MMM DD YYYY'))
      tempArr[schoolIndex].items.forEach((item, itemIndex) => {
        calculatePremium(
          0,
          itemIndex,
          tempArr[schoolIndex].items[itemIndex].staffType
        )
      })
    }
    setCurrenQuote({ ...currentQuote, schools: tempArr })
    console.log(currentQuote)
  }

  const updateBenefit = (value, schoolIndex, itemIndex) => {
    const tempArr = [...currentQuote.schools]
    tempArr[schoolIndex].items[itemIndex].benefit = value
    setCurrenQuote({ ...currentQuote, schools: tempArr })
    calculatePremium(
      schoolIndex,
      itemIndex,
      tempArr[schoolIndex].items[itemIndex].staffType
    )
  }

  const updateExcess = (value, schoolIndex, itemIndex) => {
    const tempArr = [...currentQuote.schools]
    tempArr[schoolIndex].items[itemIndex].excess = value
    setCurrenQuote({ ...currentQuote, schools: tempArr })
    calculatePremium(
      schoolIndex,
      itemIndex,
      tempArr[schoolIndex].items[itemIndex].staffType
    )
  }

  const updateFte = (value, schoolIndex, itemIndex) => {
    const tempArr = [...currentQuote.schools]
    tempArr[schoolIndex].items[itemIndex].fte = value
    setCurrenQuote({ ...currentQuote, schools: tempArr })
    calculatePremium(
      schoolIndex,
      itemIndex,
      tempArr[schoolIndex].items[itemIndex].staffType
    )
  }

  const updateOccupation = (value, schoolIndex, itemIndex) => {
    let grade = 0
    for (let i = 0; i < occupationsList.length; i++) {
      if (occupationsList[i].title === value) {
        grade = occupationsList[i].code
      }
    }
    const tempArr = [...currentQuote.schools]
    tempArr[schoolIndex].items[itemIndex].staffGrade = grade
    setCurrenQuote({ ...currentQuote, schools: tempArr })
    calculatePremium(
      schoolIndex,
      itemIndex,
      tempArr[schoolIndex].items[itemIndex].staffType
    )
  }

  const updateStress = (value, schoolIndex, itemIndex) => {
    const tempArr = [...currentQuote.schools]
    tempArr[schoolIndex].items[itemIndex].stress = value
    setCurrenQuote({ ...currentQuote, schools: tempArr })
    calculatePremium(
      schoolIndex,
      itemIndex,
      tempArr[schoolIndex].items[itemIndex].staffType
    )
  }

  const removeStaffTypeItem = (i, n) => {
    const tempArr = [...currentQuote.schools]
    tempArr[i].items.splice(n, 1)
    setCurrenQuote({ ...currentQuote, schools: tempArr })
  }

  const onDrop = (e) => {
    e.preventDefault()
    wrapperRef.current.classList.remove('dragover')
    onFileDrop(e.nativeEvent.dataTransfer.files)
  }

  const onFileUpload = async (e) => {
    e.preventDefault()

    const newFile = e.target.files[0]
    if (newFile) {
      const updatedList = [...fileList, newFile]
      setFileList(updatedList)
    }
  }

  const onFileDrop = async (files) => {
    const newFile = files[0]
    if (newFile) {
      const updatedList = [...fileList, newFile]
      setFileList(updatedList)
    }
  }

  const fileRemove = (file) => {
    console.log(file)
    const updatedList = [...fileList]
    updatedList.splice(fileList.indexOf(file), 1)
    setFileList(updatedList)
  }

  const getOccupations = async () => {
    setShowLoader(true)
    try {
      const response = await axios.get(`/brokers/occupations`)
      await setoccupationsList(response.data.result)
      setShowLoader(false)
    } catch (error) {
      setShowLoader(false)
    }
  }

  const calculatePremium = async (schoolIndex, itemIndex, type) => {
    setLoaderIcon(true)
    try {
      let data = {
        quote: JSON.stringify(currentQuote),
      }
      const response = await axios.post(`/brokers/quote/calculate`, data)
      let schools = response.data.data.schools
      let premium = schools[schoolIndex].costings.items[itemIndex].premium
      const tempArr = [...currentQuote.schools]
      tempArr[schoolIndex].items[itemIndex].premium = premium
      tempArr[schoolIndex].items[itemIndex].breakdown.premium = premium
      updatePremium(0)
      setCurrenQuote({ ...currentQuote, schools: tempArr })
      setShowLoader(false)
    } catch (error) {
      setShowLoader(false)
    }
  }

  const updatePremium = (schoolIndex) => {
    let totalPremium = 0
    const tempArr = [...currentQuote.schools]
    tempArr[schoolIndex].items.forEach((item, itemIndex) => {
      totalPremium += item.premium
    })
    let premiumTotal = totalPremium.toFixed(2)
    setPremium(premiumTotal)
    setLoaderIcon(false)
  }
  const updateScreenWidth = () => {
    setScreenWidth(window.innerWidth)
  }

  useEffect(() => {
    if (id) {
      getOccupations()
    }

    getQuote()

    if (!location.state) {
      getClientById()
    } else {
      setClientData(location.state)
    }
    calculatePremium(0, 0, 'Accountant/Accountancy Assistant')

    window.addEventListener('resize', updateScreenWidth)

    return () => {
      window.removeEventListener('resize', updateScreenWidth)
    }
  }, [id])

  useEffect(() => {
    console.log(collapsed)
  }, [collapsed])

  const getClientById = async () => {
    try {
      setShowLoader(true)
      const response = await axios.get(`/brokers/clients/${id}`)
      await setClientData(response.data.result)
      await setOriginalRecords(response.data.result)
      setShowLoader(false)
    } catch (error) {}
  }

  const [editAbsence, setEditAbsence] = useState(false)
  const [viewPremium, setViewPremium] = useState(false)
  const [quoteInfo, setQuoteInfo] = useState({})

  const addAbsenceClicked = () => {
    setEditAbsence(true)
    setModalTitle(`Upload Absence Documents`)
    setShowModal(true)
  }

  const viewPremiumClicked = () => {
    setViewPremium(true)
    setModalTitle(`Premium and IPT`)
    setShowModal(true)
  }

  const ModalBody = () => {
    if (editAbsence) {
      return <EditAbsenceInfoElement />
    }

    if (viewPremium) {
      return <ViewPremiumElement />
    }
  }

  const ViewPremiumElement = () => {
    return (
      <form>
        <div className="mt-2 w-full space-y-4">
          <div>
            <dt className="text-base font-semibold text-yellow-darker">
              Premium (inc IPT)
            </dt>
            <p className="text-gray-800 font-bold text-lg">£{premium}</p>
          </div>
          <div>
            <dt className="text-base font-semibold text-yellow-darker">
              IPT Percent
            </dt>
            <p className="text-gray-800 font-bold text-lg">12%</p>
          </div>
          <button
            type="button"
            className="border-2 border-backdrop-600 text-gray-800 bg-backdrop hover:bg-white hover:text-backdrop-600 font-medium rounded-lg p-2 mt-4 w-full"
            onClick={resetModal}
          >
            Cancel
          </button>
        </div>
      </form>
    )
  }

  const EditAbsenceInfoElement = () => {
    // const addRisk = async (e) => {
    //   e.preventDefault()
    //   setShowLoader(true)
    //   try {
    //     const response = await axios.post(
    //       `/clients/${clientData.id}/risk/add`,
    //       {
    //         risk_id: assignRisk.id,
    //         session_id: 60,
    //       }
    //     )
    //     createInfo('success', `Assigned New Risk Level`)
    //     setClientRisk([...clientRisk, response.data.result])
    //     resetModal(false)
    //   } catch (error) {}
    //   setShowLoader(false)
    // }

    return (
      <form>
        <section className="">
          <label
            htmlFor="file-upload"
            className="col-span-full"
            ref={wrapperRef}
            onDrop={onDrop}
          >
            <label
              htmlFor="cover-photo"
              className="block text-sm font-medium leading-6 text-gray-900"
            ></label>
            <div className="mt-2 flex justify-center rounded-lg border border-dashed border-gray-900/25 px-6 py-10">
              <div className="text-center">
                {' '}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="currentColor"
                  className="mx-auto h-12 w-12 text-gray-300"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M2.25 15.75l5.159-5.159a2.25 2.25 0 013.182 0l5.159 5.159m-1.5-1.5l1.409-1.409a2.25 2.25 0 013.182 0l2.909 2.909m-18 3.75h16.5a1.5 1.5 0 001.5-1.5V6a1.5 1.5 0 00-1.5-1.5H3.75A1.5 1.5 0 002.25 6v12a1.5 1.5 0 001.5 1.5zm10.5-11.25h.008v.008h-.008V8.25zm.375 0a.375.375 0 11-.75 0 .375.375 0 01.75 0z"
                  />
                </svg>
                <div className="mt-4 flex text-sm leading-6 text-gray-600 justify-center">
                  <div className="relative cursor-pointer rounded-md bg-white font-semibold text-blue-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-indigo-600 focus-within:ring-offset-2 hover:text-blue-500">
                    <span>Drag and Drop or click to upload a file</span>
                    <input
                      id="file-upload"
                      name="file-upload"
                      type="file"
                      multiple
                      className="sr-only"
                      onChange={onFileUpload}
                    />
                  </div>
                </div>
                <p className="text-xs leading-5 text-gray-600">
                  PNG, JPG, GIF up to 10MB
                </p>
                {/* <p className="error-message">{errorMsg}</p> */}
              </div>
            </div>
          </label>
        </section>

        <div className="flex mt-2 w-full space-x-4">
          <button
            type="button"
            className="border-2 border-backdrop-600 text-gray-800 bg-backdrop hover:bg-white hover:text-backdrop-600 font-medium rounded-lg p-2 mt-4 w-1/2"
            onClick={resetModal}
          >
            Cancel
          </button>
          <button className="border-2 border-moss-darker text-white bg-moss-darker hover:bg-transparent hover:text-moss-darker font-medium rounded-lg p-2 mt-4 mr-4 w-1/2">
            Upload
          </button>
        </div>
      </form>
    )
  }

  const resetModal = () => {
    setEditAbsence(false)
    setShowModal(false)
  }

  const getQuote = async () => {
    try {
      setShowLoader(true)
      const response = await axios.get(`/brokers/quote/${quoteId}`)
      console.log(response.data.data)
      setCurrenQuote(JSON.parse(response.data.data))
      console.log('here')

      setShowLoader(false)
    } catch (error) {}
  }

  return (
    <section>
      {showModal && (
        <Modal title={modalTitle} body={<ModalBody />} show={resetModal} />
      )}

      <MainTitle
        title={`Client: ${clientData.academy_name} (${clientData.academy_urn})`}
        backButton
      />
      <ClientNav active="quotes" client={clientData} />

      <div className="mx-auto pl-6 font-montserrat">
        <div className="mr-6">
          <div className="bg-white rounded-xl shadow">
            <div className="grid grid-cols-2 gap-4 bg-sovereign p-4 rounded-xl">
              <div className="mb-3">
                <label
                  htmlFor="reference"
                  className="block font-medium text-white text-sm"
                >
                  Company Name
                </label>
                <div className="mt-1 relative rounded-md">
                  <input
                    required
                    disabled
                    defaultValue={clientData.academy_name}
                    type="text"
                    name="reference"
                    id="reference"
                    className="px-4 py-2 block w-full border border-gray-300 cursor-not-allowed bg-gray-200 rounded-md shadow-md"
                  />
                </div>
              </div>
              <div className="mb-3">
                <label
                  htmlFor="reference"
                  className="block font-medium text-white text-sm"
                >
                  Company Postcode
                </label>
                <div className="mt-1 relative rounded-md">
                  <input
                    required
                    disabled
                    defaultValue={clientData.academy_postcode}
                    type="text"
                    name="reference"
                    id="reference"
                    className="px-4 py-2 block w-full border border-gray-300 bg-gray-200 rounded-md shadow-md"
                  />
                </div>
              </div>
            </div>
          </div>

          {currentQuote.schools.map((school, index) => {
            return (
              <div className="bg-white rounded-xl shadow mt-16">
                <div className="grid grid-cols-2 gap-4 bg-sovereign p-4 rounded-t-xl">
                  <div className="mb-3">
                    <label
                      htmlFor="reference"
                      className="block font-medium text-white text-sm"
                    >
                      Cover Start Date
                    </label>
                    <div className="mt-1 relative rounded-md">
                      <input
                        required
                        onChange={(e) =>
                          updateCoverDates(0, e.target.value, 'start')
                        }
                        type="date"
                        name="reference"
                        id="reference"
                        className="px-4 py-2 block w-full border border-gray-300 rounded-md shadow-md"
                      />
                    </div>
                  </div>
                  <div className="mb-3">
                    <label
                      htmlFor="reference"
                      className="block font-medium text-white text-sm"
                    >
                      Cover End Date
                    </label>
                    <div className="mt-1 relative rounded-md">
                      <input
                        required
                        onChange={(e) =>
                          updateCoverDates(0, e.target.value, 'end')
                        }
                        type="date"
                        name="reference"
                        id="reference"
                        className="px-4 py-2 block w-full border border-gray-300 rounded-md shadow-md"
                      />
                    </div>
                  </div>
                </div>
                <div className="flex justify-between mx-4 my-4">
                  <div>This quote is for a {duration} month Policy</div>
                </div>

                {school.items.map((item, itemIndex) => {
                  return (
                    <div
                      className={`grid grid-cols-${
                        itemIndex > 0 ? 7 : 6
                      } gap-2 pt-2 p-4`}
                    >
                      <div className="mb-3">
                        <label
                          htmlFor="reference"
                          className="block font-medium text-gray-600 text-sm"
                        >
                          Occupation
                        </label>
                        <div className="mt-1 relative rounded-md">
                          {item.override ? (
                            <input
                              required
                              type="text"
                              name="reference"
                              id="reference"
                              className="px-4 py-2 block w-full border border-gray-300 rounded-md shadow-md"
                            />
                          ) : (
                            <select
                              className="px-4 py-2 block w-full border border-gray-300 rounded-md shadow-md"
                              required
                              onChange={(e) => {
                                updateOccupation(
                                  e.target.value,
                                  index,
                                  itemIndex
                                )
                              }}
                            >
                              {occupationsList?.map((occupation) => {
                                return <option>{occupation.title}</option>
                              })}
                            </select>
                          )}
                        </div>
                      </div>
                      <div className="mb-3">
                        <label
                          htmlFor="reference"
                          className="block font-medium text-gray-600 text-sm"
                        >
                          FTE
                        </label>
                        <div className="mt-1 relative rounded-md">
                          <input
                            required
                            value={item.fte}
                            onChange={(e) => {
                              updateFte(e.target.value, index, itemIndex)
                            }}
                            type="number"
                            className="px-4 py-2 block w-full border border-gray-300 rounded-md shadow-md"
                          />
                        </div>
                      </div>
                      <div className="mb-3">
                        <label
                          htmlFor="reference"
                          className="block font-medium text-gray-600 text-sm"
                        >
                          Daily Benefit £
                        </label>
                        <div className="mt-1 relative rounded-md">
                          <input
                            required
                            value={item.benefit}
                            onChange={(e) => {
                              updateBenefit(e.target.value, index, itemIndex)
                            }}
                            type="number"
                            min={80}
                            name="benefit"
                            id="reference"
                            className="px-4 py-2 block w-full border border-gray-300 rounded-md shadow-md"
                          />
                        </div>
                      </div>
                      <div className="mb-3">
                        <label
                          htmlFor="name"
                          className="block font-medium pb-1 text-gray-600 text-sm"
                        >
                          Excess Period
                        </label>

                        <select
                          className="px-2 py-2 block w-full border border-gray-300 rounded-md shadow-md"
                          required
                          value={item.excess}
                          onChange={(e) => {
                            updateExcess(e.target.value, index, itemIndex)
                          }}
                        >
                          <option value={7}>7</option>
                          <option value={14}>14</option>
                          <option value={21}>21</option>
                          <option value={28}>28</option>
                          <option value={35}>35</option>
                          <option value={42}>42</option>
                        </select>
                      </div>
                      <div className="mb-3">
                        <label
                          htmlFor="stress"
                          className="block font-medium text-gray-600 text-sm"
                        >
                          Stress
                        </label>
                        <div className="mt-1 relative rounded-md">
                          <select
                            className="px-2 py-2 block w-full border border-gray-300 rounded-md shadow-md"
                            required
                            value={item.excess}
                            onChange={(e) => {
                              updateStress(e.target.value, index, itemIndex)
                            }}
                          >
                            <option value={30}>30</option>
                            <option value={260}>260</option>
                          </select>
                        </div>
                      </div>
                      <div className="mb-3">
                        <label
                          htmlFor="reference"
                          className="block font-medium text-gray-600 text-sm"
                        >
                          Premium £
                        </label>
                        <div className="mt-1 relative rounded-md">
                          <input
                            required
                            disabled
                            value={item.premium}
                            type="text"
                            name="premium"
                            id="premium"
                            className="px-4 py-2 block w-full border border-gray-300 rounded-md shadow-md"
                          />
                        </div>
                      </div>
                      <div className="mb-3">
                        <div className="mt-1 relative rounded-md items-center">
                          {itemIndex > 0 && (
                            <button
                              onClick={() => {
                                removeStaffTypeItem(index, itemIndex)
                              }}
                            >
                              <div className="flex justify-between mt-8 space-x-4 pl-6">
                                <div>
                                  {loaderIcon && (
                                    <div className="loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-5 w-5 mb-2"></div>
                                  )}
                                </div>
                                <div>
                                  <FontAwesomeIcon
                                    icon={faTrashCan}
                                    className="hover:text-red-800"
                                  />
                                </div>
                              </div>
                            </button>
                          )}
                        </div>
                      </div>
                    </div>
                  )
                })}

                <div className="flex justify-end my-4 mx-4 py-4">
                  <button
                    onClick={() => {
                      toggleNewRow(index)
                    }}
                    className="flex items-center border-2 border-white hover:border-sovereign rounded-xl px-2 py-2"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="w-6 h-6"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M12 9v6m3-3H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
                      />
                    </svg>
                    <span>Add Occupation</span>
                  </button>
                </div>
              </div>
            )
          })}

          <div className="bg-white rounded-xl shadow mt-16 mb-24">
            <div className="bg-sovereign p-4 rounded-t-xl text-white font-medium mb-6">
              Add Additional Information
            </div>
            <section className="p-4 flex w-full space-x-4">
              <div className="w-1/2">
                <div className="mb-3">
                  <label
                    htmlFor="reference"
                    className="block font-medium text-gray-600 text-sm"
                  >
                    Loading
                  </label>
                  <div className="mt-1 relative rounded-md">
                    <input
                      required
                      type="text"
                      name="reference"
                      id="reference"
                      className="px-4 py-2 block w-full border border-gray-300 rounded-md shadow-md"
                    />
                  </div>
                </div>
                <div className="mb-3">
                  <label
                    htmlFor="type"
                    className="block font-medium text-gray-600 text-sm"
                  >
                    Type
                  </label>
                  <div className="mt-1 relative rounded-md">
                    <select className="px-2 py-2 block w-full border border-gray-300 rounded-md shadow-md">
                      <option value="percent">%</option>
                      <option value="pound">£</option>
                    </select>
                  </div>
                </div>
                <div className="mb-3">
                  <label
                    htmlFor="name"
                    className="block mb-2 font-medium text-gray-600 text-sm"
                  >
                    Reason
                  </label>
                  <select className="px-2 py-2 block w-full border border-gray-300 rounded-md shadow-md">
                    <option value="claims">Claims</option>
                  </select>
                </div>
              </div>

              <div className="w-1/2">
                <div className="mb-3">
                  <label
                    htmlFor="reference"
                    className="block font-medium text-gray-600 text-sm"
                  >
                    Discount
                  </label>
                  <div className="mt-1 relative rounded-md">
                    <input
                      required
                      type="text"
                      name="reference"
                      id="reference"
                      className="px-4 py-2 block w-full border border-gray-300 rounded-md shadow-md"
                    />
                  </div>
                </div>
                <div className="mb-3">
                  <label
                    htmlFor="discount_type"
                    className="block font-medium text-gray-600 text-sm"
                  >
                    Type
                  </label>
                  <div className="mt-1 relative rounded-md">
                    <select className="px-2 py-2 block w-full border border-gray-300 rounded-md shadow-md">
                      <option value="percent">%</option>
                      <option value="pound">£</option>
                    </select>
                  </div>
                </div>
                <div className="mb-3">
                  <label
                    htmlFor="name"
                    className="block mb-2 font-medium text-gray-600 text-sm"
                  >
                    Reason
                  </label>
                  <select className="px-2 py-2 block w-full border border-gray-300 rounded-md shadow-md">
                    <option value="claims">Claims</option>
                    <option value="commercial">Commercial</option>
                  </select>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>

      <div
        className={`fixed bottom-0 left-0 right-0 bg-white shadow-md rounded-lg px-2 py-0 flex justify-between ${barPosition}`}
      >
        <div className="flex items-center space-x-4">
          <button
            onClick={addAbsenceClicked}
            className="bg-moss-darker text-white py-3 px-6 rounded-md hover:bg-blue-600 focus:outline-none ml-6 lg:ml-2"
          >
            Absence Information
          </button>
        </div>
        <div className="flex justify-end">
          <div className="bottom-0 left-0 right-0 p-4 flex justify-between">
            <div>
              <button className="border-2 border-moss-darker text-white bg-moss-darker hover:bg-transparent hover:text-moss-darker font-medium rounded-lg py-3 px-6">
                Save Quote
              </button>
            </div>
            <div className="ml-2 cursor-pointer">
              <div
                onClick={viewPremiumClicked}
                className="border-2 border-green-500 text-white bg-green-500 hover:bg-transparent hover:text-green-500 font-medium rounded-lg py-3 px-6"
              >
                £{premium}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default ViewQuote
