// import { faTimes } from "@fortawesome/free-solid-svg-icons"
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import { faTimes } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

function Popout({ title, body, hide }) {
  return (
    <div
      className="fixed top-0 left-0 w-screen h-screen "
      style={{ zIndex: 999 }}
    >
      <div className="flex flex-col absolute p-6 top-0 left-0 bg-white w-full h-full overflow-y-scroll">
        <FontAwesomeIcon
          className="absolute right-5 top-5 text-2xl cursor-pointer"
          icon={faTimes}
          onClick={hide}
        />
        <h2 className="text-center text-wba-primary text-2xl font-bold border-b border-b-slate-300 pb-6">
          {title}
        </h2>
        <div className="mt-4 max-w-2xl mx-auto w-full">{body}</div>
      </div>
    </div>
  )
}

export default Popout
