import { Link } from 'react-router-dom'
import useAuth from '../hooks/useAuth'

function ClientNav({ active = null, client = {} }) {
  const { admin } = useAuth()

  return (
    <div className="bg-white rounded-md shadow-md py-3 px-4 mb-8 ml-6 lg:ml-0">
      <ul className="flex flex-col md:flex-row flex-wrap list-none px-0">
        <Link to={`/clients/${client.academy_id}`} state={client}>
          <li className={`tab-btn ${active === 'details' ? 'active' : ''}`}>
            Details
          </li>
        </Link>
        <Link to={`/clients/${client.academy_id}/claims`} state={client}>
          <li
            className={`tab-btn ${
              active === 'claims' ||
              window.location.href.indexOf('claims') !== -1
                ? 'active'
                : ''
            }`}
          >
            Claims
          </li>
        </Link>
        {/* <Link to={`/clients/${client.academy_id}/calendar`}>
          <li className={`tab-btn ${active === 'calendar' ? 'active' : ''}`}>
            Booking Calendar
          </li>
        </Link> */}
        <Link to={`/clients/${client.academy_id}/quotes`} state={client}>
          <li
            className={`tab-btn ${
              active === 'quotes' ? 'active' : ''
            }`}
          >
            Quotes
          </li>
        </Link>
        <Link to={`/clients/${client.academy_id}/policies`} state={client}>
          <li
            className={`tab-btn ${
              active === 'policies' ? 'active' : ''
            }`}
          >
            Policies
          </li>
        </Link>
        {/* <Link to={`/clients/${client.academy_id}/messages`} state={client}>
          <li className={`tab-btn ${active === 'messages' ? 'active' : ''}`}>
            Messages
          </li>
        </Link> */}
        {/* <Link to={`/clients/${client.academy_id}/notes`} state={client}>
          <li className={`tab-btn ${active === 'notes' ? 'active' : ''}`}>
            Notes
          </li>
        </Link> */}

        <Link to={`/clients/${client.academy_id}/shared-documents`} state={client}>
          <li
            className={`tab-btn ${
              active === 'shared-documents' ? 'active' : ''
            }`}
          >
            Documents
          </li>
        </Link>
      </ul>
    </div>
  )
}

export default ClientNav
