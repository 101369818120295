import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faDownload,
} from '@fortawesome/free-solid-svg-icons'

const reports = [{ id: 1, title: 'Master Sales Report', description: 'Click below to download an Excel Report of the master Sales report', download: 'masterreport.csv',}, { id: 2, title: 'Master Claims Report', description: 'Click below to download an Excel Report of the master claims report', download: 'masterreport.csv', }, { id: 3, title: 'Enquiry Stats CSV Report', description: 'Click below to download an Excel Report of the enquiry stats report', download: 'enquirystats.csv', }, { id: 4, title: 'Accounts Report', description: 'Click below to download an Excel Report of the accounts report', download: 'masterreport.csv', }, { id: 5, title: 'Document Report', description: 'Click below to download an Excel Report of the document report', download: 'masterreport.csv', },]

const Reports = () => {
  return (
    <div className="mx-auto font-montserrat">
      <div className='flex justify-between items-center pt-12'>
          <div className='font-semibold text-4xl'>Reports</div>
          <div>
            {/* <button className='bg-moss-green rounded-lg px-4 py-2 shadow font-semibold text-moss-darker hover:bg-transparent hover:border-2 hover:border-moss-green flex items-center'><PlusIcon className="h-5 w-5" aria-hidden="true" /> Create Report</button> */}
          </div>
      </div>
      <div className='grid grid-cols-3 gap-4 pt-20'>
      {reports.map((report) => (
        <div className='bg-white shadow-md rounded-2xl p-4'><div className='text-center font-medium text-lg'>{report.title}</div>
        <div className='py-2 text-sm'>{report.description}</div>
          <div className='flex justify-end'>
              <button className='rounded-xl px-4 py-2 bg-bluey text-sm flex items-center'>  <FontAwesomeIcon icon={faDownload} className="h-4 w-4" aria-hidden="true" /> <span className='pl-1'>Download</span></button>
            
            </div>
        </div>
      ))}
      </div>

    </div>
  )
}

export default Reports