import React from 'react'
import { useState, useEffect, useRef } from 'react'
import useAxiosPrivate from '../hooks/useAxiosPrivate'
import useAuth from '../hooks/useAuth'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Pagination from '../components/Pagination'
import Modal from '../components/Modal'
import {
  faTrashCan,
  faMagnifyingGlass,
  faSliders,
  faPoundSign,
  faEye,
} from '@fortawesome/free-solid-svg-icons'
import { useNavigate, useParams } from 'react-router-dom'
import moment from 'moment'

function ClaimSessions({ sessions }) {
  // Auth
  const { clientId, claimId } = useParams()
  const { setShowLoader, can, createInfo } = useAuth()
  const axios = useAxiosPrivate()
  const navigate = useNavigate()

  const [currentRecords, setCurrentRecords] = useState([])

  // search
  const [filteredRecords, setFilteredRecords] = useState([])
  const [nPages, setNumberOfPages] = useState(0)

  const [currentPage, setCurrentPage] = useState(1)
  // No of Records to be displayed on each page
  const [recordsPerPage] = useState(10)

  const [indexOfLastRecord, setIndexOfLastRecord] = useState(10)
  const [indexOfFirstRecord, setIndexOfFirstRecord] = useState(0)

  useEffect(() => {
    if (sessions) {
      setFilteredRecords(sessions)
      setCurrentRecords(sessions)
    } else {
      navigate(`/clients/${clientId}`)
    }
  }, [sessions])

  const handleSearch = (handle) => {
    setIndexOfFirstRecord(0)
    setIndexOfLastRecord(10)
    setCurrentPage(0)
    setCurrentRecords(filteredRecords.slice(0, 10))

    let searchValue = handle.target.value
    let searchString = searchValue.trim().toLowerCase()
    if (searchString.length > 0) {
      let records = sessions.filter((e) => {
        return (
          e.user.name.toLowerCase().match(searchString) ||
          e.service.title.toLowerCase().match(searchString)
        )
      })
      setFilteredRecords(records)
      setCurrentRecords(records.slice(indexOfFirstRecord, indexOfLastRecord))
      setNumberOfPages(Math.ceil(records.length / recordsPerPage))
    } else {
      setFilteredRecords(sessions)
      setCurrentRecords(sessions.slice(indexOfFirstRecord, indexOfLastRecord))
      setNumberOfPages(Math.ceil(sessions.length / recordsPerPage))
    }
  }

  const nextPage = () => {
    if (currentPage < nPages) {
      const newPage = currentPage + 1
      const indexFirst = newPage * 10 - 10
      const indexLast = newPage * 10

      setIndexOfFirstRecord(indexFirst)
      setIndexOfLastRecord(indexLast)
      setCurrentPage(newPage)
      setCurrentRecords(filteredRecords.slice(indexFirst, indexLast))
    }
  }
  const previousPage = () => {
    if (currentPage > 1) {
      const newPage = currentPage - 1
      const indexFirst = newPage * 10 - 10
      const indexLast = newPage * 10

      setIndexOfFirstRecord(indexFirst)
      setIndexOfLastRecord(indexLast)
      setCurrentPage(newPage)
      setCurrentRecords(filteredRecords.slice(indexFirst, indexLast))
    }
  }

  const viewSession = (sessionId) => {
    navigate(`/clients/${clientId}/claims/${claimId}/sessions/${sessionId}`)
  }

  return (
    <section className="w-full">
      <h2 className="font-medium text-lg mb-4">Sessions</h2>
      <div>
        <div className="pb-4 flex justify-between">
          <div className="relative mt-1">
            <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
              <FontAwesomeIcon
                icon={faMagnifyingGlass}
                onClick={handleSearch}
              />
            </div>
            <input
              type="text"
              id="table-search"
              onChange={handleSearch}
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-80 pl-10 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="Search Sessions"
            />
          </div>
        </div>

        <table className="table-main">
          <thead>
            <tr>
              <th scope="col">Session Type</th>
              <th scope="col">Service</th>
              <th scope="col">Practitioner</th>
              <th scope="col">Date</th>
              <th scope="col">Status</th>
              <th scope="col">Actions</th>
            </tr>
          </thead>
          <tbody>
            {currentRecords.length > 0 ? (
              currentRecords?.map((session) => {
                return (
                  <tr key={session.id}>
                    <td>
                      {session.type_id === 2 ? 'Assessment' : 'Appointment'}
                    </td>
                    <td>{session.service.title}</td>
                    <td>{session.user.name}</td>
                    <td>
                      {moment(session.start_time).format('DD/MM/YYYY HH:mm')}
                    </td>
                    <td>
                      <div className={`status ${session.status.toLowerCase()}`}>
                        {session.status}
                      </div>
                    </td>
                    <td>
                      <div className="flex justify-center">
                        <span
                          title="View Session"
                          onClick={() => viewSession(session.id)}
                          className="flex justify-center items-center bg-blue-400 rounded-md text-blue-800 h-9 w-12 mx-2  hover:bg-blue-500 cursor-pointer"
                        >
                          <FontAwesomeIcon icon={faEye} />
                        </span>
                      </div>
                    </td>
                  </tr>
                )
              })
            ) : (
              <tr>
                <td colSpan={5}>
                  <p className="no-records">No sessions found</p>
                </td>
              </tr>
            )}
          </tbody>
        </table>
        <Pagination
          next={nextPage}
          prev={previousPage}
          first={indexOfFirstRecord}
          last={indexOfLastRecord}
          total={filteredRecords.length}
        />
      </div>
    </section>
  )
}

export default ClaimSessions
