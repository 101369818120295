import React, { useState } from 'react';

const Transactions = () => {
  const diarylist = [
    {
      id: 1,
      name: 'Client Review and Feedback',
      description: 'A bit more about what needs to be done in this current note',
      date: '10th Nov 2023',
      status: 'due',
    },
    {
      id: 2,
      name: 'Meeting with Client',
      description: 'A bit more about what needs to be done in this current note',
      date: '1st Nov 2023',
      status: 'overdue',
    },
    {
      id: 3,
      name: 'Presentation for prospective client',
      description: 'A bit more about what needs to be done in this current note',
      date: '20 Nov 2023',
      status: 'important',
    },
  ];

  const statusStyles = {
    due: 'text-green-700 bg-green-200',
    overdue: 'text-red-700 bg-red-200',
    important: 'text-amber-700 bg-amber-200',
  };

  function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
  }

  return (
    <div className="mt-6 space-y-4">
      {diarylist.map((event) => (
        <div
          className={`bg-backdrop rounded-xl px-4 py-2 flex items-center justify-between shadow-md ${
            event.completed ? 'bg-green-100' : ''
          }`}
          key={event.id}
        >
          <div>
            <div className={`font-medium ${event.completed ? 'line-through' : ''}`}>
              {event.name}
            </div>
            <div className="text-sm">{event.description}</div>
          </div>
          <div className="text-end">
            <div className={classNames(
                statusStyles[event.status],
                'text-xs px-3 py-1 text-center rounded-full font-medium'
              )}> Due: {event.date}</div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default Transactions;
