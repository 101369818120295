/* eslint-disable */
import MainTitle from '../components/MainTitle'
import { NavLink } from 'react-router-dom'
import CardButton from '../components/CardButton'
import useAuth from '../hooks/useAuth'

import { useState, useEffect } from 'react'
import useAxiosPrivate from '../hooks/useAxiosPrivate'

import moment from 'moment'
import NotificationItem from '../components/NotificationItem'

function Notifications() {
  const axios = useAxiosPrivate()
  const { setShowLoader, can } = useAuth()

  const [notifications, setNotifications] = useState([])

  useEffect(() => {
    init()
  }, [])

  const init = async () => {
    setShowLoader(true)
    getNotifications()
  }

  const getNotifications = async () => {
    try {
      const response = await axios.get('/notifications/user')
      setNotifications(response.data.result)
    } catch (error) {}
    setShowLoader(false)
  }

  return (
    <section>
      <MainTitle title="Notifications" />
      <section>
        <div className="flex flex-wrap">
          {notifications?.map((notification) => {
            return (
              <div key={notification.id} className="w-1/2 notification-parent">
                <NotificationItem notification={notification} />
              </div>
            )
          })}
        </div>
      </section>
    </section>
  )
}

export default Notifications
