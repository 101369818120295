import React from 'react'
import { useState, useEffect, useRef } from 'react'
import useAxiosPrivate from '../hooks/useAxiosPrivate'
import useAuth from '../hooks/useAuth'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Pagination from '../components/Pagination'
import Modal from '../components/Modal'
import {
  faTrashCan,
  faMagnifyingGlass,
  faEye,
  faSortDown,
  faSortUp,
  faSort
} from '@fortawesome/free-solid-svg-icons'
import { Link } from 'react-router-dom'

function ClinicPractitioners({ clinicId }) {
  // Auth
  const { setShowLoader, can, createInfo } = useAuth()
  const axios = useAxiosPrivate()

  //practitioner
  const [addPractitioner, setAddPractitioner] = useState(false)
  const [newPractitioner, setNewPractitioner] = useState({})
  const [removePractitioner, setRemovePractitioner] = useState(false)
  const [deletePractitioner, setDeletePractitioner] = useState({})
  const [practitioners, setPractitioners] = useState([])

  //practitioners
  const [currentRecords, setCurrentRecords] = useState([])

  // search
  const [filteredRecords, setFilteredRecords] = useState([])
  const [nPages, setNumberOfPages] = useState(0)

  const [showModal, setShowModal] = useState(false)
  const [modalTitle, setModalTitle] = useState('')

  const [currentPage, setCurrentPage] = useState(1)
  // No of Records to be displayed on each page
  const [recordsPerPage] = useState(10)

  const [indexOfLastRecord, setIndexOfLastRecord] = useState(10)
  const [indexOfFirstRecord, setIndexOfFirstRecord] = useState(0)

  const [originalRecords, setOriginalRecords] = useState([]);
  const [columnClickCounts, setColumnClickCounts] = useState({});
  const [sortConfig, setSortConfig] = useState({
    key: null,
    direction: 'ascending',
  });

  useEffect(() => {
    if (clinicId) {
      getPractitioners()
    }
  }, [clinicId])

  const handleSearch = (handle) => {
    setIndexOfFirstRecord(0)
    setIndexOfLastRecord(10)
    setCurrentPage(0)
    setCurrentRecords(filteredRecords.slice(0, 10))
    let searchValue = handle.target.value
    let searchString = searchValue.trim().toLowerCase()
    if (searchString.length > 0) {
      let records = practitioners.filter((e) => {
        return (
          e.name.toLowerCase().match(searchString) ||
          e.email.toLowerCase().match(searchString)
        )
      })
      setFilteredRecords(records)
      setCurrentRecords(records.slice(indexOfFirstRecord, indexOfLastRecord))
      setNumberOfPages(Math.ceil(records.length / recordsPerPage))
    } else {
      setFilteredRecords(practitioners)
      setCurrentRecords(
        practitioners.slice(indexOfFirstRecord, indexOfLastRecord)
      )
      setNumberOfPages(Math.ceil(practitioners.length / recordsPerPage))
    }
  }

  const sortArray = (array, key, direction) => {
    return [...array].sort((a, b) => {
      if (a[key] < b[key]) return direction === 'ascending' ? -1 : 1;
      if (a[key] > b[key]) return direction === 'ascending' ? 1 : -1;
      return 0;
    });
  };

  const renderSortArrow = (columnKey) => {
    if (sortConfig.key === columnKey) {
      if (sortConfig.direction === 'ascending') {
        return <FontAwesomeIcon icon={faSortUp} />;
      } else {
        return <FontAwesomeIcon icon={faSortDown} />;
      }
    }
    return <FontAwesomeIcon icon={faSort} />;
  };

  const handleSort = (key) => {
    let newColumnClickCounts = { ...columnClickCounts };
    if (!newColumnClickCounts[key]) {
      newColumnClickCounts[key] = 1;
    } else {
      newColumnClickCounts[key]++;
    }
  
    if (newColumnClickCounts[key] === 3) {
      setSortConfig({
        key: null,
        direction: 'descending',
      });
      newColumnClickCounts[key] = 0;
      setCurrentRecords(originalRecords.slice(
        indexOfFirstRecord,
        indexOfLastRecord
      ));
    } else {
      let direction =
        sortConfig.direction === 'ascending'
          ? 'descending'
          : 'ascending';
      setSortConfig({ key, direction });
  
      const sortedRecords = sortArray(
        originalRecords,
        key,
        direction
      );
      setCurrentRecords(sortedRecords.slice(
        indexOfFirstRecord,
        indexOfLastRecord
      ));
    }
    setColumnClickCounts(newColumnClickCounts);
  };

  const nextPage = () => {
    if (currentPage < nPages) {
      const newPage = currentPage + 1
      const indexFirst = newPage * recordsPerPage - recordsPerPage;
      const indexLast = newPage * recordsPerPage;

      setIndexOfFirstRecord(indexFirst)
      setIndexOfLastRecord(indexLast)
      setCurrentPage(newPage)
      const sortedRecords = sortArray(originalRecords, sortConfig.key, sortConfig.direction);
      setCurrentRecords(sortedRecords.slice(indexFirst, indexLast))
    }
  }
  const previousPage = () => {
    if (currentPage > 1) {
      const newPage = currentPage - 1
      const indexFirst = (newPage - 1) * recordsPerPage;
      const indexLast = newPage * recordsPerPage;

      setIndexOfFirstRecord(indexFirst)
      setIndexOfLastRecord(indexLast)
      setCurrentPage(newPage)
      const sortedRecords = sortArray(originalRecords, sortConfig.key, sortConfig.direction);
      setCurrentRecords(sortedRecords.slice(indexFirst, indexLast))
    }
  }

  const ModalBody = () => {
    if (addPractitioner) {
      return <AddPractitionerElement />
    }

    if (removePractitioner) {
      return <RemovePractitionerElement />
    }
  }

  const getPractitioners = async () => {
    try {
      const response = await axios.get(`/companies/practitioners/` + clinicId)
      if (response.data.result) {
        await setPractitioners(response.data.result)
        await setFilteredRecords(response.data.result)
        await setOriginalRecords(response.data.result);
        await setCurrentRecords(response.data.result.slice(
          indexOfFirstRecord,
          indexOfLastRecord
        ))
        await setNumberOfPages(
          Math.ceil(response.data.result.length / recordsPerPage)
        );
      }
    } catch (error) {}
  }

  const AddPractitionerElement = () => {
    const addPractitioner = async (e) => {
      e.preventDefault()
      setShowLoader(true)
      try {
        const response = await axios.post('/companies/practitioner/add', {
          companyId: clinicId,
          user: newPractitioner,
        })
        if (response.data.error === false) {
          createInfo('success', `Created Practitioner: ${newPractitioner.name}`)
          setCurrentRecords([...currentRecords, response.data.result])
          setPractitioners([...practitioners, response.data.result])
          setAddPractitioner(false)
          setNewPractitioner({})
          resetModal()
        }
      } catch (error) {}
      setShowLoader(false)
    }
    return (
      <form onSubmit={addPractitioner}>
        <div className="mb-3">
          <label
            htmlFor="name"
            className="block font-medium text-gray-600 text-sm"
          >
            Name
          </label>
          <div className="mt-1 relative rounded-md">
            <input
              required
              type="text"
              name="name"
              id="name"
              onChange={(e) => (newPractitioner.name = e.target.value)}
              className="px-4 py-2 block w-full border border-gray-300 rounded-md shadow-md"
            />
          </div>
        </div>
        <div className="mb-3">
          <label
            htmlFor="email"
            className="block font-medium text-gray-600 text-sm"
          >
            Email
          </label>
          <div className="mt-1 relative rounded-md">
            <input
              type="email"
              required
              name="email"
              id="email"
              onChange={(e) => (newPractitioner.email = e.target.value)}
              className="px-4 py-2 block w-full border border-gray-300 rounded-md shadow-md"
            />
          </div>
        </div>
        <div className="mb-3">
          <label
            htmlFor="phone_number"
            className="block font-medium text-gray-600 text-sm"
          >
            Phone Number{' '}
            <span className="text-blue-500 text-xs">
              (Please use +44 and no spaces)
            </span>
          </label>
          <div className="mt-1 relative rounded-md">
            <input
              type="text"
              required
              name="phone_number"
              id="phone_number"
              onChange={(e) => (newPractitioner.phone_number = e.target.value)}
              className="px-4 py-2 block w-full border border-gray-300 rounded-md shadow-md"
            />
          </div>
        </div>
        <div className="flex mt-2 w-full">
          <button className="btn mt-4 mr-4 w-1/2">Add Practitioner</button>

          <button
            type="button"
            className="btn red mt-4 w-1/2"
            onClick={resetModal}
          >
            Cancel
          </button>
        </div>
      </form>
    )
  }

  const RemovePractitionerElement = () => {
    const removePractitioner = async (e) => {
      setShowModal(false)
      setShowLoader(true)
      const response = await axios.post('/companies/practitioner/remove', {
        userId: deletePractitioner.id,
        companyId: clinicId,
      })
      createInfo('error', `Removed Practitioner: ${deletePractitioner.name}`)
      setCurrentRecords(
        currentRecords.filter((practitioner) => {
          return practitioner.id !== deletePractitioner.id
        })
      )
      setPractitioners(
        practitioners.filter((practitioner) => {
          return practitioner.id !== deletePractitioner.id
        })
      )
      setDeletePractitioner({})
      setShowLoader(false)
    }
    return (
      <form onSubmit={removePractitioner} className="justify-center flex">
        <div className="mb-3">
          <p className="text-lg font-bold my-8 text-center">
            Are you sure you want to remove this practitioner?
          </p>
          <div className="flex mt-2 w-full">
            <button className="btn red mt-4 mr-4 w-1/2">
              Remove Practitioner
            </button>

            <button
              type="button"
              className="btn mt-4 w-1/2"
              onClick={resetModal}
            >
              Cancel
            </button>
          </div>
        </div>
      </form>
    )
  }

  const removePractitionerClicked = (user) => {
    setDeletePractitioner({ ...user })
    setRemovePractitioner(true)
    setModalTitle(`Remove Practitioner: ${user.name}`)
    setShowModal(true)
  }

  const addPractitionerClicked = () => {
    setAddPractitioner(true)
    setModalTitle(`Add Practitioner`)
    setShowModal(true)
  }

  const resetModal = () => {
    setAddPractitioner(false)
    setRemovePractitioner(false)
    setShowModal(false)
    setModalTitle('')
  }

  return (
    <section className="w-full">
      {showModal && (
        <Modal title={modalTitle} body={<ModalBody />} show={resetModal} />
      )}

      {can('view clinic practitioners') && (
        <div>
          <div className="pb-4 flex justify-between">
            <div className="relative mt-1">
              <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                <FontAwesomeIcon
                  icon={faMagnifyingGlass}
                  onClick={handleSearch}
                />
              </div>
              <input
                type="text"
                id="table-search"
                onChange={handleSearch}
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-80 pl-10 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="Search Practitioners"
              />
            </div>
            {can('add clinic services') && (
              <div
                className="cursor-pointer ml-4 text-blue-500 font-bold items-center flex btn"
                onClick={addPractitionerClicked}
              >
                <span>Add Practitioner</span>
              </div>
            )}
          </div>

          <table className="table-main">
            <thead>
              <tr>
                <th scope="col">Profile Picture</th>
                <th className='cursor-pointer' scope="col" onClick={() => handleSort('name')}>Name {renderSortArrow('name')}</th>
                <th className='cursor-pointer' scope="col" onClick={() => handleSort('email')}>Email {renderSortArrow('email')}</th>
                <th className='cursor-pointer' scope="col" onClick={() => handleSort('status')}>Status {renderSortArrow('status')}</th>
                <th className='cursor-pointer' scope="col" onClick={() => handleSort('contact number')}>Contact Number {renderSortArrow('contact number')}</th>
                {can('remove clinic practitioners') && (
                  <th scope="col">Actions</th>
                )}
              </tr>
            </thead>
            <tbody>
              {currentRecords.length > 0 ? (
                currentRecords?.map((user) => {
                  return (
                    <tr key={user.id}>
                      <td>
                        <img
                          width={40}
                          height={40}
                          src={user.profile_pic}
                          alt={user.name}
                          className="mx-auto"
                        />
                      </td>
                      <td>{user.name}</td>
                      <td>{user.email}</td>
                      <td>
                        <div className={`status ${user.status.toLowerCase()}`}>
                          {user.status}
                        </div>
                      </td>
                      <td>{user.phone_number}</td>

                      <td>
                        <div className="flex justify-center">
                          <Link to={`/users/${user.id}`}>
                            <span className="flex justify-center items-center bg-blue-400 rounded-md text-blue-800 h-9 w-12 mx-2  hover:bg-blue-500 cursor-pointer">
                              <FontAwesomeIcon icon={faEye} />
                            </span>
                          </Link>
                          {can('remove clinic practitioners') && (
                            <span
                              title="Remove Practitioner"
                              onClick={() => removePractitionerClicked(user)}
                              className="flex justify-center items-center bg-red-400 rounded-md text-red-800 h-9 w-12 mx-2  hover:bg-red-500 cursor-pointer"
                            >
                              <FontAwesomeIcon icon={faTrashCan} />
                            </span>
                          )}
                        </div>
                      </td>
                    </tr>
                  )
                })
              ) : (
                <tr>
                  <td colSpan={6}>
                    <p className="no-records">No practitioners found</p>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
          <Pagination
            next={nextPage}
            prev={previousPage}
            first={indexOfFirstRecord}
            last={indexOfLastRecord}
            total={filteredRecords.length}
          />
        </div>
      )}
    </section>
  )
}

export default ClinicPractitioners
