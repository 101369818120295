import { NavLink, Link } from 'react-router-dom'
import logo from '../img/mercer-logo-01.png'
import { useState, useEffect } from 'react'
import { faRightFromBracket, faUser } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import useLogout from '../hooks/useLogout'
import useAuth from '../hooks/useAuth'

import AdminNav from '../components/Admin/Nav'
import ClinicNav from '../components/Clinic/Nav'

const navClasses =
  'w-full text-white py-3 px-3 flex relative rounded-md items-center'

function LeftBar({ collapsed, toggleSidebar }) {
  const { admin, auth } = useAuth();
  const logout = useLogout();
  
  const [isSmallScreen, setIsSmallScreen] = useState(false);

  useEffect(() => {
    const checkScreenSize = () => {
      if (window.innerWidth <= 870) {
        setIsSmallScreen(true);
      } else {
        setIsSmallScreen(false);
      }
    };

    checkScreenSize();
    window.addEventListener('resize', checkScreenSize);

    return () => {
      window.removeEventListener('resize', checkScreenSize);
    };
  }, []);

  return (
    <div>
      {isSmallScreen ? (
        <ul className="mb-4 flex flex-col gap-1 mt-4">
          <ClinicNav />
        </ul>
      ) : (
        <aside className={`bg-sovereign fixed inset-0 z-50 my-4 ml-4 h-[calc(100vh-32px)] w-72 rounded-xl shadow-md ${collapsed ? 'hidden' : 'block'} ${isSmallScreen ? 'hidden' : ''}`}>
        <div className="relative border-b border-b-slate-600 mx-4">
          <Link to="/" className="flex items-center py-4 my-2 px-8">
            <img src={logo} alt="" className='w-full' />
          </Link>
        </div>
        <div className="mx-4 mb-4 flex flex-col justify-between relative h-[calc(100%-150px)]">
          <ul className="mb-4 flex flex-col gap-1 mt-4">
            <ClinicNav />
          </ul>
          <ul className="border-t border-t-slate-600 pt-4">
            <li className="mb-2">
              <NavLink
                to={`/users/${auth.user.id}`}
                className={({ isActive }) =>
                  isActive
                    ? 'bg-blue-500 ' + navClasses + ' pointer-events-none'
                    : navClasses + ' hover:bg-peach'
                }
              >
                <span className="flex justify-start w-10">
                  <FontAwesomeIcon icon={faUser} className="mr-4 text-xl" />
                </span>
                Profile
              </NavLink>
            </li>
            <li className="mb-2">
              <button
                onClick={logout}
                className="w-full text-white py-3 px-3 flex relative rounded-md items-center hover:bg-peach"
              >
                <span className="flex justify-start w-10">
                  <FontAwesomeIcon
                    icon={faRightFromBracket}
                    className="mr-4 text-xl"
                  />
                </span>
                Logout
              </button>
            </li>
          </ul>
        </div>
        <div className="absolute bottom-0 mb-4 ml-4">
          <button
            onClick={toggleSidebar}
            className="text-white p-2 rounded-md bg-blue-700 hover:bg-peach"
          >
            {collapsed ? "Open Sidebar" : "Close Sidebar"}
          </button>
        </div>
        </aside>
      )}
  </div>
);
}

export default LeftBar
