/* eslint-disable */
import MainTitle from '../../components/MainTitle'
import { NavLink, useParams, useNavigate, useLocation } from 'react-router-dom'
import CardButton from '../../components/CardButton'
import useAuth from '../../hooks/useAuth'
import moment from 'moment'
import { useState, useEffect } from 'react'
import useAxiosPrivate from '../../hooks/useAxiosPrivate'
import ClientNav from '../../components/ClientNav'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Pagination from '../../components/Pagination'
import {
  faMagnifyingGlass,
  faEye,
  faPlus,
  faSortDown,
  faSortUp,
  faSort,
  faFileCircleCheck
} from '@fortawesome/free-solid-svg-icons'
import Modal from '../../components/Modal'
import { Link } from 'react-router-dom'

function Quotes() {
  const { id } = useParams()
  const axios = useAxiosPrivate()
  const location = useLocation()
  const { setShowLoader, can, createInfo, auth } = useAuth()
  const navigate = useNavigate()
  const [quotes, setQuotes] = useState([])
  const [deleteQuote, setDeleteQuote] = useState({})
  const [showModal, setShowModal] = useState(false)

  const [search, setSearch] = useState('')

  const [currentRecords, setCurrentRecords] = useState([])
  const [filteredRecords, setFilteredRecords] = useState([])
  const [nPages, setNumberOfPages] = useState(0)

  const [currentPage, setCurrentPage] = useState(1)
  // No of Records to be displayed on each page
  const [recordsPerPage] = useState(10)

  const [indexOfLastRecord, setIndexOfLastRecord] = useState(10)
  const [indexOfFirstRecord, setIndexOfFirstRecord] = useState(0)

  const [modalTitle, setModalTitle] = useState('')
  const [addQuote, setAddQuote] = useState(false)

  const [originalRecords, setOriginalRecords] = useState([]);
  const [columnClickCounts, setColumnClickCounts] = useState({});
  const [sortConfig, setSortConfig] = useState({
    key: null,
    direction: 'ascending',
  });
  const [clientData, setClientData] = useState({
    name: '',
    reference: 'WBA-1000',
  })

  useEffect(() => {
    init()
    setClientData(location.state)
  }, [])

  const getClientById = async () => {
    try {
      setShowLoader(true)
      const response = await axios.get(`/brokers/clients/${id}`)
      await setClientData(response.data.result)
      await setOriginalRecords(response.data.result);
      setShowLoader(false)
    } catch (error) {}
  }

  const init = async () => {
    setShowLoader(true)
    getQuotes()
    
  }

  const handleSearch = (handle) => {
    setIndexOfFirstRecord(0)
    setIndexOfLastRecord(10)
    setCurrentPage(0)
    setCurrentRecords(filteredRecords.slice(0, 10))

    let searchValue = handle.target.value
    setSearch(searchValue)
    let searchString = searchValue.trim().toLowerCase()
    if (searchString.length > 0) {
      let records = quotes.filter((e) => {
        return e.name.toLowerCase().match(searchString)
      })
      setFilteredRecords(records)
      setCurrentRecords(records.slice(indexOfFirstRecord, indexOfLastRecord))
      setNumberOfPages(Math.ceil(records.length / recordsPerPage))
    } else {
      setFilteredRecords(quotes)
      setCurrentRecords(quotes.slice(indexOfFirstRecord, indexOfLastRecord))
      setNumberOfPages(Math.ceil(quotes.length / recordsPerPage))
    }
  }

  const sortArray = (array, key, direction) => {
    return [...array].sort((a, b) => {
      if (a[key] < b[key]) return direction === 'ascending' ? -1 : 1;
      if (a[key] > b[key]) return direction === 'ascending' ? 1 : -1;
      return 0;
    });
  };

  const renderSortArrow = (columnKey) => {
    if (sortConfig.key === columnKey) {
      if (sortConfig.direction === 'ascending') {
        return <FontAwesomeIcon icon={faSortUp} />;
      } else {
        return <FontAwesomeIcon icon={faSortDown} />;
      }
    }
    return <FontAwesomeIcon icon={faSort} />;
  };

  const handleSort = (key) => {
    let newColumnClickCounts = { ...columnClickCounts };
    if (!newColumnClickCounts[key]) {
      newColumnClickCounts[key] = 1;
    } else {
      newColumnClickCounts[key]++;
    }
  
    if (newColumnClickCounts[key] === 3) {
      setSortConfig({
        key: null,
        direction: 'descending',
      });
      newColumnClickCounts[key] = 0;
      setCurrentRecords(originalRecords.slice(
        indexOfFirstRecord,
        indexOfLastRecord
      ));
    } else {
      let direction =
        sortConfig.direction === 'ascending'
          ? 'descending'
          : 'ascending';
      setSortConfig({ key, direction });
  
      const sortedRecords = sortArray(
        originalRecords,
        key,
        direction
      );
      setCurrentRecords(sortedRecords.slice(
        indexOfFirstRecord,
        indexOfLastRecord
      ));
    }
    setColumnClickCounts(newColumnClickCounts);
  };

  const nextPage = () => {
    if (currentPage < nPages) {
      const newPage = currentPage + 1;
      const indexFirst = newPage * recordsPerPage - recordsPerPage;
      const indexLast = newPage * recordsPerPage;
  
      setIndexOfFirstRecord(indexFirst);
      setIndexOfLastRecord(indexLast);
      setCurrentPage(newPage);

      const sortedRecords = sortArray(originalRecords, sortConfig.key, sortConfig.direction);
      setCurrentRecords(sortedRecords.slice(indexFirst, indexLast));
    }
  };
  
  const previousPage = () => {
    if (currentPage > 1) {
      const newPage = currentPage - 1;
      const indexFirst = (newPage - 1) * recordsPerPage;
      const indexLast = newPage * recordsPerPage;
  
      setIndexOfFirstRecord(indexFirst);
      setIndexOfLastRecord(indexLast);
      setCurrentPage(newPage);
  
      const sortedRecords = sortArray(originalRecords, sortConfig.key, sortConfig.direction);
      setCurrentRecords(sortedRecords.slice(indexFirst, indexLast));
    }
  };

  const getQuotes = async () => {
    try {
      const response = await axios.get(`/brokers/clients/${id}/quotes`)
      setQuotes(response.data.result)
      setFilteredRecords(response.data.result)
      setCurrentRecords(
        response.data.result.slice(indexOfFirstRecord, indexOfLastRecord)
      )
      setNumberOfPages(Math.ceil(response.data.result.length / recordsPerPage))
    } catch (error) {}
    setShowLoader(false)
  }

  const addQuoteClicked = () => {
    navigate(`/clients/${id}/quotes/new`)
    setShowModal(true)
  }

  const ModalBody = () => {
    if(addQuote) {
      return <AddQuoteElement />
    }
  }

  const AddQuoteElement = () => {
    return (
      <form >
         <div className="mb-3">
          <label
            htmlFor="reference"
            className="block font-medium text-gray-600 text-sm"
          >
            Name
          </label>
          <div className="mt-1 relative rounded-md">
            <input
              required
              type="text"
              name="reference"
              id="reference"
              className="px-4 py-2 block w-full border border-gray-300 rounded-md shadow-md"
            />
          </div>
        </div>
        <div className="mb-3">
          <label
            htmlFor="reference"
            className="block font-medium text-gray-600 text-sm"
          >
            Email
          </label>
          <div className="mt-1 relative rounded-md">
            <input
              required
              type="text"
              name="reference"
              id="reference"
              className="px-4 py-2 block w-full border border-gray-300 rounded-md shadow-md"
            />
          </div>
        </div>
        <div className="mb-3">
          <label
            htmlFor="name"
            className="block font-medium text-gray-600 text-sm"
          >
            Pollicy Type
          </label>

          <select
            className="px-2 py-2 block w-full border border-gray-300 rounded-md shadow-md"
            required
          >
            <option>Select option</option>
            <option>Basic</option>
            <option>Multi</option>
            <option>Premium</option>
          </select>
        </div>
        <div className="mb-3">
          <label
            htmlFor="reference"
            className="block font-medium text-gray-600 text-sm"
          >
            External Reference
          </label>
          <div className="mt-1 relative rounded-md">
            <input
              required
              type="text"
              name="reference"
              id="reference"
              className="px-4 py-2 block w-full border border-gray-300 rounded-md shadow-md"
            />
          </div>
        </div>

        <div className="flex mt-2 w-full space-x-4">
        <button
            type="button"
            className="border-2 border-backdrop-600 text-gray-800 bg-backdrop hover:bg-white hover:text-backdrop-600 font-medium rounded-lg p-2 mt-4 w-1/2"
            onClick={resetModal}
          >
            Cancel
          </button>
          <button className="border-2 border-moss-darker text-white bg-moss-darker hover:bg-transparent hover:text-moss-darker font-medium rounded-lg p-2 mt-4 mr-4 w-1/2">Add Quote</button>
        </div>
      </form>
    )
  }

  const resetModal = (resetQuotes = true) => {
    setDeleteQuote({})
    setAddQuote(false)
    setShowModal(false)
    if (resetQuotes) {
      setQuotes(quotes)
    }
  }

  return (
    <section>
      {showModal && (
        <Modal title={modalTitle} body={<ModalBody />} show={resetModal} />
      )}
      <MainTitle
        title={`Client: ${clientData.academy_name} (${clientData.academy_urn})`}
        backButton
      />

      <ClientNav active="quotes" client={clientData} />

      <section>
        {showModal && (
          <Modal title={modalTitle} body={<ModalBody />} show={resetModal} />
        )}
        <ul className="flex just-between flex-wrap">
          {can('create quotes') && (
            <li className="w-1/4 mb-6 mr-3">
              <NavLink to="new">
                <CardButton icon={faPlus} title="Create New Quote" />
              </NavLink>
            </li>
          )}

          <div className="overflow-hidden rounded-2xl py-1 bg-moss-green shadow-md w-1/3">
            <div className="p-6">
              <div className="flex items-center justify-end">
                <div className="flex-shrink-0">
                  <FontAwesomeIcon
                    icon={faFileCircleCheck}
                    className="h-10 w-10 text-moss-darker"
                  />
                </div>
                <div className="ml-5 w-0 flex-1">
                  <dl className=''>
                    <dt className="truncate text-lg font-medium text-moss-darker">Total Quotes</dt>
                    <div className="font-medium text-grey-800">
                      {quotes.length}
                    </div>
                  </dl>
                </div>
              </div>
            </div>
          </div>
        </ul>
      </section>

      <div className='flex justify-end'>
        <button
          onClick={addQuoteClicked}
          className='bg-moss-green rounded-lg px-4 py-2 shadow font-semibold text-moss-darker'>Create New Quote</button>
      </div>

      <div className="py-4">
        <div className="relative mt-1">
          <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
            <FontAwesomeIcon icon={faMagnifyingGlass} onClick={handleSearch} />
          </div>
          <input
            type="text"
            id="table-search"
            onChange={handleSearch}
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-80 pl-10 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Search Quotes"
          />
        </div>
      </div>

      <table className="table-main">
        <thead>
          <tr>
            <th className='cursor-pointer' scope="col" onClick={() => handleSort('reference')}>Reference {renderSortArrow('reference')}</th>
            <th className='cursor-pointer' scope="col" onClick={() => handleSort('premium')}>Premium {renderSortArrow('premium')}</th>
            <th className='cursor-pointer' scope="col" onClick={() => handleSort('status')}>Status {renderSortArrow('status')}</th>
            <th className='cursor-pointer' scope="col" onClick={() => handleSort('owner')}>Owner {renderSortArrow('owner')}</th>
            <th className='cursor-pointer' scope="col" onClick={() => handleSort('created_at')}>Created At {renderSortArrow('created_at')}</th>
            <th scope="col">Actions</th>
          </tr>
        </thead>
        <tbody className="table-main">
          {currentRecords.length > 0 ? (
            currentRecords.map((quote) => {
              return (
                <tr key={quote.quote_id}>
                  <td>{quote.quote_reference}</td>
                  <td>{quote.premium}</td>
                  <td>{quote.quote_status}</td>
                  <td>{quote.owner_name}</td>
                  <td>{moment(quote.created_at).format('DD/MM/YYYY')}</td>
                  <td>
                    <div className="flex justify-center">
                      <Link to={`/clients/${id}/quotes/${quote.quote_id}`}>
                        <span className="flex justify-center items-center bg-blue-400 rounded-md text-blue-800 h-9 w-12 mx-2  hover:bg-blue-500 cursor-pointer">
                          <FontAwesomeIcon icon={faEye} />
                        </span>
                      </Link>
                    </div>
                  </td>
                </tr>
              )
            })
          ) : (
            <tr>
              <td colSpan={7}>
                <p className="no-records">No quotes found</p>
              </td>
            </tr>
          )}
        </tbody>
      </table>
      <Pagination
        next={nextPage}
        prev={previousPage}
        first={indexOfFirstRecord}
        last={indexOfLastRecord}
        total={filteredRecords.length}
        resultsPerPage={recordsPerPage}
      />
    </section>
  )
}

export default Quotes
