import { useState, useEffect, useRef } from 'react'
import useAxiosPrivate from '../../hooks/useAxiosPrivate'
import MainTitle from '../../components/MainTitle'
import { Link, useParams, useLocation, useNavigate } from 'react-router-dom'
import useAuth from '../../hooks/useAuth'
import ClientNav from '../../components/ClientNav'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrashCan } from '@fortawesome/free-solid-svg-icons'
import moment from 'moment'
import Modal from '../../components/Modal'
function Notes() {
  const [notes, setNotes] = useState([])
  const [deleteNote, setDeleteNote] = useState({})
  const [addNote, setAddNote] = useState({})
  const [addNoteTitle, setAddNoteTitle] = useState('')
  const [addNoteBody, setAddNoteBody] = useState('')
  const [addNotePrivate, setAddNotePrivate] = useState(1)
  const { id } = useParams()
  const { setShowLoader, can, createInfo, auth } = useAuth()

  const [clientData, setClientData] = useState({
    name: '',
    reference: 'WBA-1000',
  })
  
  const [showModal, setShowModal] = useState(false)
  const [modalTitle, setModalTitle] = useState('')
  const axios = useAxiosPrivate()
  const location = useLocation()
  const navigate = useNavigate()

  const getNotesByClientId = async () => {
    try {
      setShowLoader(true)
      const response = await axios.get(`/clients/${id}/notes`)
      if (response.data.result) {
        await setNotes(response.data.result)
      }
    } catch (error) {}
    setShowLoader(false)
  }

  const resetModal = (resetNotes = true) => {
    setDeleteNote({})
    setShowModal(false)
    if (resetNotes) {
      setNotes(notes)
    }
  }

  const createNote = async (e) => {
    e.preventDefault()
    setShowLoader(true)
    try {
      const response = await axios.post(`/clients/${clientData.id}/notes/add`, {
        created_by: auth.user.id,
        note_title: addNoteTitle,
        note_body: addNoteBody,
        private: addNotePrivate,
      })
      if (response.data.error === false) {
        createInfo('success', `Note Added`)
        setNotes([...notes, response.data.result])
        setAddNoteTitle('')
        setAddNoteBody('')
        setAddNotePrivate(1)
      }
    } catch (error) {
      createInfo('error', `Failed to create note`)
    }
    setShowLoader(false)
  }

  const DeleteNoteElement = () => {
    const deleteNoteFn = async (e) => {
      e.preventDefault()
      setShowLoader(true)
      resetModal(false)
      try {
        const response = await axios.post(
          '/clients/' + clientData.id + '/notes/delete?filter=all',
          {
            note_id: deleteNote.id,
          }
        )
        createInfo('error', `Note Deleted: ${deleteNote.title}`)
        setNotes(response.data.result)
        setShowLoader(false)
      } catch (error) {
        setShowLoader(false)
      }
    }

    return (
      <form onSubmit={deleteNoteFn}>
        <div className="mb-3">
          <p className="text-lg font-bold my-8 text-center">
            Are you sure you want to delete this note?
          </p>
          <div className="flex mt-2 w-full">
            <button className="btn red mt-4 mr-4 w-1/2">Delete Note</button>

            <button
              type="button"
              className="btn mt-4 w-1/2"
              onClick={resetModal}
            >
              Cancel
            </button>
          </div>
        </div>
      </form>
    )
  }

  const ModalBody = () => {
    if (deleteNote.id) {
      return <DeleteNoteElement />
    }
  }

  const deleteNoteClicked = (note) => {
    setDeleteNote({ ...note })
    setModalTitle(`Delete Note: ${note.title}`)
    setShowModal(true)
  }

  useEffect(() => {
    setClientData(location.state)
    if (!location.state) {
      navigate(`/clients/${id}`)
    } else {
      getNotesByClientId()
    }
  }, [])
  return (
    <section className="relative">
      {showModal && (
        <Modal title={modalTitle} body={<ModalBody />} show={resetModal} />
      )}
      <MainTitle
        title={`Client: ${clientData.name} (${clientData.reference})`}
        backButton
        child={clientData.child}
      />
      <ClientNav active="notes" client={clientData} />

      <section>
        <section className="w-full bg-white rounded-xl shadow-md py-4 px-4 mb-8">
          <h2 className="font-bold text-xl mb-2 mt-2">Notes</h2>
          <div className="flex flex-row">
            <div className="w-1/2">
              <form onSubmit={createNote}>
                <div>
                  <input
                    className="py-4 px-4 border-t border-l border-r w-full"
                    placeholder="Please enter a note title"
                    value={addNoteTitle}
                    onChange={(e) => {
                      setAddNoteTitle(e.target.value)
                    }}
                    required
                  ></input>
                  <textarea
                    required
                    className="w-full border rounded-sm py-4 px-4"
                    value={addNoteBody}
                    onChange={(e) => {
                      setAddNoteBody(e.target.value)
                    }}
                    placeholder="Please type here to add notes"
                  ></textarea>
                  { can("add private note") && (<div className="flex items-center mb-4">
                    <input
                      id="note-checkbox"
                      type="checkbox"
                      checked={addNotePrivate}
                      onChange={(e) => {
                        setAddNotePrivate(e.target.checked)
                      }}
                      className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                    />
                    <label
                      htmlFor="note-checkbox"
                      className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                    >
                      Make note private
                    </label>
                  </div>)}
                  <div className=" flex justify-end">
                    <button className="btn mt-4 mr-4">Add Note</button>
                  </div>
                </div>
              </form>
            </div>

            <div className="px-4 w-1/2 overflow-y-scroll mr-2 max-h-96">
              <div>
                {notes?.map((note) => {
                  return note.private === 1 && can("view private notes") ? (
                        <div className="border rounded px-4 mr-2 mb-2">
                      <div className="flex justify-between py-2">
                        <div className="">
                          <span className="py-2">{note.title}</span>
                        </div>
                        {can('delete notes') && (<div className="justify-end">
                          <FontAwesomeIcon
                            icon={faTrashCan}
                            className="text-red-500"
                            onClick={() => deleteNoteClicked(note)}
                          />
                        </div>)}
                      </div>
                      <hr />
                      <p className="py-2">{note.body}</p>
                      <div className="py-2 flex justify-between">
                        <div>
                          <span className="text-xs py-2">
                            created By {note.created_by_user} on&nbsp;
                            {moment(note.created_at).format('DD/MM/YYYY HH:mm')}
                          </span>
                        </div>
                        <div className="justify-end">
                          {note.private === 1 ? (
                            <span className="status private">Private</span>
                          ) : (
                            <span className="status public"> Public</span>
                          )}
                        </div>
                      </div>
                    </div>
                  )  : (note.private === 0 ?  <div className="border rounded px-4 mr-2 mb-2">
                  <div className="flex justify-between py-2">
                    <div className="">
                      <span className="py-2">{note.title}</span>
                    </div>
                    <div className="justify-end">
                      <FontAwesomeIcon
                        icon={faTrashCan}
                        className="text-red-500"
                        onClick={() => deleteNoteClicked(note)}
                      />
                    </div>
                  </div>
                  <hr />
                  <p className="py-2">{note.body}</p>
                  <div className="py-2 flex justify-between">
                    <div>
                      <span className="text-xs py-2">
                        created By {note.created_by_user} on&nbsp;
                        {moment(note.created_at).format('DD/MM/YYYY HH:mm')}
                      </span>
                    </div>
                    <div className="justify-end">
                      {note.private === 1 ? (
                        <span className="status private">Private</span>
                      ) : (
                        <span className="status public"> Public</span>
                      )}
                    </div>
                  </div>
                </div>
                    : null
                    )
                
                })}
              </div>
            </div>
          </div>
        </section>
      </section>
    </section>
  )
}

export default Notes
