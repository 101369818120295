import { useState, useEffect } from 'react'
import { Chart } from "react-google-charts";
import useAxiosPrivate from '../../hooks/useAxiosPrivate'
import useAuth from '../../hooks/useAuth'
import DiaryList from '../Partials/DiaryList';
import Tasks from '../Partials/Tasks';
import DueTasks from '../Partials/DueTasks';
import OverdueTasks from '../Partials/OverdueTasks';
import ImportantTasks from '../Partials/ImportantTasks';
import Calendar from '../Partials/Calendar';
import Modal from '../../components/Modal'
import moment from 'moment'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faScaleBalanced,
  faFileContract,
  faFolderOpen,
  faMoneyBill1Wave,
  faPlusCircle,
  faEnvelope
} from '@fortawesome/free-solid-svg-icons'
import { useNavigate, useParams } from 'react-router-dom'

const cards = [ { name: 'GWP', icon: faScaleBalanced, amount: '£30,659.45' },]
const transactions = [ { id: 1, name: 'Payment to Capital Group', amount: '£20,000', currency: 'GBP', status: 'success', date: '11/07/2023',},]
const statusStyles = { success: 'bg-green-100 text-green-800', processing: 'bg-yellow-100 text-yellow-800', failed: 'bg-gray-100 text-gray-800', }
const deals = [{ id: 1, name: 'Open Deals', amount: '23', month: '20',}, { id: 2, name: 'My Untouched Deals', amount: '3', month: '2',}, { id: 3, name: 'Deals Created', amount: '32', month: '27',}, { id: 4, name: 'Deals Closing', amount: '5', month: '5',}, { id: 5, name: 'Leads', amount: '15', month: '10',},]

export const options = {
  title: "Monthly Profits",
  pieHole: 0.4,
  is3D: false,
  colors: ['#1e40af', '#3b82f6', '#93c5fd', '#dbeafe', '#dbeafe'],
  backgroundColor: '#f1f5f9'
};

export const options1 = {
  title: "Claims",
  curveType: "function",
  lineWidth: 4,
  is3D: false,
  colors: ['#1d4ed8'],
  backgroundColor: '#f1f5f9',
};

export const options2 = {
  title: "Claims",
  curveType: "function",
  lineWidth: 4,
  is3D: false,
  colors: ['#E6DFCF'],
  backgroundColor: '#f8fafc',
  vAxes: {
    0: {
        textPosition: 'none'
    },
  
    },
    vAxis: {
        gridlines: {
            color: 'transparent'
        }
    }
};

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

function Dashboard() {
  const { id } = useParams()
  const { setShowLoader } = useAuth()
  const navigate = useNavigate();
  const axios = useAxiosPrivate();
  const [showModal, setShowModal] = useState(false)
  const [modalTitle, setModalTitle] = useState('')
  const [addTask, setAddTask] = useState(false)
  const [addDiaryItem, setAddDiaryItem] = useState(false)
  const [policies, setPolicies] = useState([])
  const [display, setDisplay] = useState('All')

  useEffect(() => {
    getPolicies()
  }, [])

  const getPolicies = async () => {
    try {
      setShowLoader(true)
      const response = await axios.get(`/brokers/${id}/policies`)
      setPolicies(response.data.result)
      setShowLoader(false)
    } catch (error) {}
  }

  const ShowDisplay = () => {
    switch (display) {
      case 'All': 
        return  <Tasks/> 
      case 'Due': 
        return  <DueTasks/> 
      case 'Overdue': 
        return  <OverdueTasks/>
      case 'Important': 
          return  <ImportantTasks/>
      default:
          return <Tasks/>
    }
  }

  const addTaskClicked = () => {
    setAddTask(true)
    setModalTitle(`Add Task`)
    setShowModal(true)
  }

  const addDiaryItemClicked = () => {
    setAddDiaryItem(true)
    setModalTitle(`Add Diary Item`)
    setShowModal(true)
  }

  const ModalBody = () => {
    if(addTask) {
      return <AddTaskElement />
    }

    if(addDiaryItem) {
      return <AddDiaryElement />
    }
  }

  const AddTaskElement = () => {
    return (
        <form >
          <div className="mb-3">
            <label
              htmlFor="reference"
              className="block font-medium text-gray-600 text-sm"
            >
              Title
            </label>
            <div className="mt-1 relative rounded-md">
              <input
                required
                type="text"
                name="reference"
                id="reference"
                className="px-4 py-2 block w-full border border-gray-300 rounded-md shadow-md"
              />
            </div>
          </div>
          <div className="mb-3">
            <label
              htmlFor="reference"
              className="block font-medium text-gray-600 text-sm"
            >
              Due Date
            </label>
            <div className="mt-1 relative rounded-md">
              <input
                required
                type="date"
                name="reference"
                id="reference"
                className="px-4 py-2 block w-full border border-gray-300 rounded-md shadow-md"
              />
            </div>
          </div>
          <div className="mb-3">
            <label
              htmlFor="name"
              className="block mb-2 font-medium text-gray-600 text-sm"
            >
              Important
            </label>
            <input
              type="checkbox"
              className="bg-blue-500 focus:accent-blue-500 h-4 w-4"
            />
          </div>

          <div className="flex mt-2 w-full space-x-4">
          <button
              type="button"
              className="border-2 border-backdrop-600 text-gray-800 bg-backdrop hover:bg-white hover:text-backdrop-600 font-medium rounded-lg p-2 mt-4 w-1/2"
              onClick={resetModal}
            >
              Cancel
            </button>
            <button className="border-2 border-moss-darker text-white bg-moss-darker hover:bg-transparent hover:text-moss-darker font-medium rounded-lg p-2 mt-4 mr-4 w-1/2">Add Task</button>
          </div>
        </form>
    )
  }

  const AddDiaryElement = () => {
    return (
        <form >
          <div className="mb-3">
            <label
              htmlFor="reference"
              className="block font-medium text-gray-600 text-sm"
            >
              Title
            </label>
            <div className="mt-1 relative rounded-md">
              <input
                required
                type="text"
                name="reference"
                id="reference"
                className="px-4 py-2 block w-full border border-gray-300 rounded-md shadow-md"
              />
            </div>
          </div>
          <div className="mb-3">
            <label
              htmlFor="description"
              className="block font-medium text-gray-600 text-sm"
            >
              Description
            </label>
            <div className="mt-1 relative rounded-md">
              <textarea
                required
                name="description"
                id="description"
                className="px-4 py-2 block w-full border border-gray-300 rounded-md shadow-md"
              ></textarea>
            </div>
          </div>
          <div className="mb-3">
            <label
              htmlFor="reference"
              className="block font-medium text-gray-600 text-sm"
            >
              Due Date
            </label>
            <div className="mt-1 relative rounded-md">
              <input
                required
                type="date"
                name="reference"
                id="reference"
                className="px-4 py-2 block w-full border border-gray-300 rounded-md shadow-md"
              />
            </div>
          </div>
          <div className="flex mt-2 w-full space-x-4">
            <button
                type="button"
                className="border-2 border-backdrop-600 text-gray-800 bg-backdrop hover:bg-white hover:text-backdrop-600 font-medium rounded-lg p-2 mt-4 w-1/2"
                onClick={resetModal}
              >
                Cancel
            </button>
            <button className="border-2 border-moss-darker text-white bg-moss-darker hover:bg-transparent hover:text-moss-darker font-medium rounded-lg p-2 mt-4 mr-4 w-1/2">Add Task</button>
          </div>
        </form>
    )
  }

  const resetModal = () => {
    setAddTask(false)
    setShowModal(false)
  }

  return (
    <section>
      {showModal && (
          <Modal title={modalTitle} body={<ModalBody />} show={resetModal} />
      )}
      <div className="min-h-full font-montserrat">
        <div>
          <main className="flex-1 pb-8 mt-32">
            <div className="mt-8">
              <div className="px-4 sm:px-6 lg:px-8">
                <div className="mt-2 grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-3">
                  {/* Card */}
                  {cards.map((card) => (
                    <div key={card.name} className="overflow-hidden rounded-3xl bg-moss-green shadow">
                      <div className="p-8">
                        <div className="flex items-center">
                          <div className="">
                            <FontAwesomeIcon icon={faScaleBalanced} className="mr-4 h-10 w-10 text-yellow-darker" />
                          </div>
                          <div className="ml-5 w-0 flex-1">
                            <dl>
                              <div className='flex items-center justify-between'>
                                <dt className="truncate text-lg font-medium text-yellow-darker">{card.name}</dt>
                               
                              </div>
                              <dd>
                                <div className="text-2xl font-medium text-gray-900">{card.amount}</div>
                              </dd>
                            </dl>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                  <div className="overflow-hidden rounded-3xl bg-bluey shadow-md">
                      <div className="p-8">
                        <div className="flex items-center">
                          <div className="flex-shrink-0">
                            <FontAwesomeIcon icon={faFolderOpen} className="h-10 w-10 text-yellow-darker" />
                           </div>
                          <div className="ml-5 w-0 flex-1">
                            <dl>
                              <div className='flex items-center justify-between'>
                                <dt className="truncate text-lg font-medium text-yellow-darker">Quotes Today</dt>
                              </div>
                              <dd>
                                <div className="text-2xl font-medium text-gray-900">£23,000</div>
                              </dd>
                            </dl>
                          </div>
                        </div>
                      </div>
                  </div>
                  <div className="overflow-hidden rounded-3xl bg-bluey shadow-md ">
                      <div className="p-8">
                        <div className="flex items-center">
                          <div className="flex-shrink-0">
                            <FontAwesomeIcon icon={faFileContract} className="h-10 w-10 text-yellow-darker" />
                          </div>
                          <div className="ml-5 w-0 flex-1">
                            <div>
                              <div className='flex items-center justify-between'>
                                <div className="truncate text-lg font-medium text-yellow-darker">Policies Today</div>
                              </div>
                              <dd>
                                <div className="text-2xl font-medium text-gray-900">32</div>
                              </dd>
                            </div>
                          </div>
                        </div>
                      </div>
                  </div>
                </div>
              </div>
       
             {/* graph && Recent sales */}
             <div className='flex mt-16 px-8'>
              <div className='w-2/3'>
                {/* TASK LIST */}
                <div className="mt-2">
                  <div>
                      <h2 className="text-2xl font-medium leading-6 text-gray-900">Task List</h2>
                      <p className="mb-8 text-gray-600 text-lg"></p>
                  </div>
                  <div className="shadow p-6 rounded-3xl bg-white">
                  <div className=''>
                      <div className='flex justify-between border-b border-backdrop-600'>
                          <div className='flex items-center space-x-4'>
                            <div
                              onClick={() => setDisplay('All')}
                              className={display === 'All' ? 'cursor-pointer border-b-2 font-medium text-moss-darker border-moss-darker' : 'cursor-pointer hover:border-b-2 border-backdrop-600'}>All (4)</div>
                            <div onClick={() => setDisplay('Due')}
                              className={display === 'Due' ? 'cursor-pointer border-b-2 font-medium text-moss-darker border-moss-darker' : 'cursor-pointer hover:border-b-2 border-backdrop-600'}>Due (2)</div>
                            <div onClick={() => setDisplay('Overdue')}
                              className={display === 'Overdue' ? 'cursor-pointer border-b-2 font-medium text-moss-darker border-moss-darker' : 'cursor-pointer hover:border-b-2 border-backdrop-600'}>Overdue (1)</div>
                            <div onClick={() => setDisplay('Important')}
                              className={display === 'Important' ? 'cursor-pointer border-b-2 font-medium text-moss-darker border-moss-darker' : 'cursor-pointer hover:border-b-2 border-backdrop-600'}>Important (1)</div>
                          </div>
                          <div onClick={addTaskClicked} title='Add Task'>
                            <FontAwesomeIcon icon={faPlusCircle} className="text-blue-500 cursor-pointer h-5 w-5" />
                          </div>
                      </div>
                      <ShowDisplay />
                    </div>
                  </div>
                </div>

                {/* DIARY */}
                <div className="mt-10">
                  <div>
                      <h2 className="text-2xl font-medium leading-6 text-gray-900">Diary List</h2>
                      <p className="mb-4 text-gray-600 text-lg"></p>
                  </div>
                  <div className="shadow p-6 rounded-3xl bg-white">
                  <div className=''>
                      <div className='flex items-center justify-end space-x-4'>
                        <div onClick={addDiaryItemClicked} title='Add Diary Event'>
                          <FontAwesomeIcon icon={faPlusCircle} className="text-blue-500 cursor-pointer h-5 w-5" />
                        </div>
                      </div>
                      <DiaryList />
                    </div>
                  </div>
                </div>
              </div>
            
              {/* New Clients */}
                <div className='pl-6 w-1/3'>
                    <div className="flex items-center justify-between pb-10 px-2">
                      <h2 className=" text-2xl font-medium leading-6 text-gray-900 ">Recent Policies</h2>
                      {/* <a href="/policies" className="text-sm underline">View All</a> */}
                    </div>
                      <div className='space-y-4 cursor-pointer'>
                        {policies?.map((policy, index)  => {
                          return (
                            <div key={index} className='bg-backdrop-200 hover:shadow-md hover:bg-gray-50 w-full flex items-center p-4 rounded-3xl text-sm'>
                              <div className='bg-eggplant-light rounded-full h-6 w-7 ml-2'></div>
                              <div className='pl-4 flex items-center justify-between w-full'>
                                <div>
                                  <div className='font-medium'>{policy.policy_owner}</div>
                                  <div className='text-xs text-gray-600'>{moment(policy.created_at).format( 'DD/MM/YY')}</div>
                                </div>
                                <div className='font-semibold text-xs pr-4'>£ {policy.policy_premium}</div>
                              </div>
                            </div>
                          )}
                        )}
                      </div>

                      <div className='mt-16'>
                        <Calendar />
                      </div>
                </div>
              </div>
             
              <div className='flex mt-16 px-8'>
                {/* New Clients */}
                <div className='pr-6 w-1/3'>
                    <div className="flex items-center justify-between pb-10 px-2">
                      <h2 className="pb-4 text-2xl font-medium leading-6 text-gray-900 ">Latest Emails</h2>
        
                    </div>
                    <div className='bg-white rounded-xl shadow-sm p-4 space-y-2'>
                      <div className='bg-backdrop rounded-xl px-4 py-2 flex items-center space-x-2 shadow-md'>
                        <div><FontAwesomeIcon icon={faEnvelope} className=" text-yellow-darker" /></div>
                        <div className='w-full'>
                          <div className='flex justify-between items-center w-full'>
                            <p className='font-medium text-sm'>Stacey Murphey</p>
                            <p className='text-xs'>16 Nov 2023</p>
                          </div>
                          <p className='text-xs'>Following on from my previous email...</p>
                        </div>
                      </div>
                      <div className='bg-backdrop rounded-xl px-4 py-2 flex items-center space-x-2 shadow-md'>
                        <div><FontAwesomeIcon icon={faEnvelope} className=" text-yellow-darker" /></div>
                        <div className='w-full'>
                          <div className='flex justify-between items-center w-full'>
                            <p className='font-medium text-sm'>Hannah Taylor</p>
                            <p className='text-xs'>16 Nov 2023</p>
                          </div>
                          <p className='text-xs'>Client Drinks at O2...</p>
                        </div>
                      </div>
                      <div className='bg-backdrop rounded-xl px-4 py-2 flex items-center space-x-2 shadow-md'>
                        <div><FontAwesomeIcon icon={faEnvelope} className=" text-yellow-darker" /></div>
                        <div className='w-full'>
                          <div className='flex justify-between items-center w-full'>
                            <p className='font-medium text-sm'>David Dodds</p>
                            <p className='text-xs'>15 Nov 2023</p>
                          </div>
                          <p className='text-xs'>Re: Meeting Notes...</p>
                        </div>
                      </div>
                    </div>
                </div>
                <div className='w-2/3'>
                  <div>
                      <h2 className="text-2xl font-medium leading-6 text-gray-900">Insurance Sales</h2>
                      <p className="mb-6 text-gray-600 text-lg">Monthly Average</p>
                  </div>
                  <div className="shadow p-6 rounded-3xl bg-white">
                    <div className="md:flex md:justify-between md:items-center">
                      <div className="mb-4">
                        <div className="flex items-center">
                          <div className="w-3 h-3 bg-moss-green mr-2 rounded-full"></div>
                          <div className="text-base text-gray-700">Sales</div>
                        </div>
                      </div>
                    </div>
                    <div className="line my-8 relative">
                        <div className="flex -mx-2 items-end mb-2">
                            <div className="px-2 w-1/6">
                              <div className="transition ease-in duration-200 bg-blue-600 hover:bg-blue-400 relative">
                                <div className="text-center absolute top-0 left-0 right-0 -mt-6 text-gray-800 text-sm"></div>
                              </div>
                            </div>
                        </div>
                        <div className="flex -mx-2 items-end">
                          <div className="px-2 w-1/6">
                            <div className="flex space-x-4 items-end">
                              <div className="text-center bg-moss-green hover:bg-moss-darker mx-auto h-32 w-4 p-8 rounded-t-lg"></div>
                              <div className="text-center bg-moss-green hover:bg-moss-darker mx-auto h-52 w-4 p-8 rounded-t-lg"></div>
                              <div className="text-center bg-moss-green hover:bg-moss-darker mx-auto h-24 w-4 p-8 rounded-t-lg"></div>
                              <div className="text-center bg-moss-green hover:bg-moss-darker mx-auto h-72 w-4 p-8 rounded-t-lg"></div>
                              <div className="text-center hidden lg:block bg-moss-green hover:bg-moss-darker mx-auto h-32 w-4 p-8 rounded-t-lg"></div>
                              <div className="text-center hidden lg:block bg-moss-green hover:bg-moss-darker mx-auto h-52 w-4 p-8 rounded-t-lg"></div>
                              <div className="text-center hidden lg:block bg-moss-green hover:bg-moss-darker mx-auto h-24 w-4 p-8 rounded-t-lg"></div>
                              <div className="text-center hidden lg:block bg-moss-green hover:bg-moss-darker mx-auto h-64 w-4 p-8 rounded-t-lg"></div>
                            </div>  
                          </div>
                        </div>
                        <div className="border-t border-gray-400 mx-auto flex space-x-4 ">
                          <div>January</div>
                          <div>February</div>
                          <div>March</div>
                          <div className='pl-4'>April</div>
                          <div className='pl-8 hidden lg:block'>May</div>
                          <div className='pl-6 hidden lg:block'>June</div>
                          <div className='pl-8 hidden lg:block'>July</div>
                          <div className='pl-5 hidden lg:block'>August</div>
                        </div>
                    </div>
                  </div>
                </div>
              
              </div>

              {/* Cards */}
              {/* <div className="grid grid-cols-3 lg:grid-cols-5 gap-4 px-8 pt-16">
                {deals.map((deal) => (
                    <div className="bg-bluey rounded-3xl hover:bg-backdrop-200 p-4">
                      <div className="text-bluey-dark hover:text-backdrop-600 font-medium">{deal.name}</div>
                      <div className="pb-2">{deal.amount}</div>
                      <div><span className="font-medium">Last month:</span> <span>{deal.month}</span></div>
                    </div>
                ))}
              </div>

              <div className="flex items-center justify-between pt-10">
                <Chart
                  chartType="AreaChart"
                  data={[["Number", "Claims"], ['Jan', 7.5], ['Feb', 5], ['March', 7], ['April', 15], ['May', 12], ['June', 12]]}
                  options={options1}
                  width="100%"
                  height="500px"
                  legendToggle
                />

                <Chart
                      chartType="PieChart"
                      data={[["Task", "Hours per Day"], ["New Sales", 11], ["Upgrade", 4], ["Extras", 2], ["Renewals", 7],]}
                      options={options}
                      width={"100%"}
                      height={"500px"}
                />
              </div> */}

               {/* Activity list*/}
              {/* <div className="shadow sm:hidden">
                <ul className="mt-2 divide-y divide-gray-200 overflow-hidden shadow sm:hidden">
                  {transactions.map((transaction) => (
                    <li key={transaction.id}>
                      <a href={transaction.href} className="block bg-white px-4 py-4 hover:bg-gray-50">
                        <span className="flex items-center space-x-4">
                          <span className="flex flex-1 space-x-2 truncate">
                            <FontAwesomeIcon icon={faMoneyBill1Wave} className="h-5 w-5 flex-shrink-0 text-gray-400" />
                            <span className="flex flex-col truncate text-sm text-gray-500">
                              <span className="truncate">{transaction.name}</span>
                              <span>
                                <span className="font-medium text-gray-900">{transaction.amount}</span>{' '}
                                {transaction.currency}
                              </span>
                              <time dateTime={transaction.datetime}>{transaction.date}</time>
                            </span>
                          </span>
                          {/* <ChevronRightIcon className="h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" /> */}
                        {/* </span>
                      </a>
                    </li>
                  ))}
                </ul> */}

                {/* <nav
                  className="flex items-center justify-between border-t border-gray-200 bg-white px-4 py-3"
                  aria-label="Pagination"
                >
                  <div className="flex flex-1 justify-between">
                    <div className="relative inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
                      Previous
                    </div>
                    <div className="relative ml-3 inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
                      Next
                    </div>
                  </div>
                </nav>
              </div> */}


              {/* Activity table (small breakpoint and up) */}
              {/* <div className="hidden sm:block">
                <div className=" px-4 sm:px-6 lg:px-8">
                  <div className="mt-2 flex flex-col">
                    <div className="min-w-full overflow-hidden overflow-x-auto align-middle sm:rounded-lg">
                      <table className="min-w-full divide-y divide-peach">
                        <thead className=''>
                          <tr className=''>
                            <th
                              className="px-6 pb-6 text-left text-lg font-semibold text-gray-900"
                              scope="col"
                            >
                              Transaction
                            </th>
                            <th
                              className="px-6 pb-6 text-lg font-semibold text-gray-900"
                              scope="col"
                            >
                              Amount
                            </th>
                            <th
                              className="hidden px-6 pb-6 text-left text-lg font-semibold text-gray-900 md:block"
                              scope="col"
                            >
                              Status
                            </th>
                            <th
                              className=" px-6 pb-6 text-right text-lg font-semibold text-gray-900"
                              scope="col"
                            >
                              Date
                            </th>
                          </tr>
                        </thead>
                        <tbody className="divide-y divide-peach">
                          {transactions.map((transaction) => (
                            <tr key={transaction.id} className="">
                              <td className="w-full max-w-0 whitespace-nowrap px-6 py-4 text-lg text-gray-900">
                                <div className="flex">
                                  <a href={transaction.href} className="group inline-flex space-x-2 truncate text-lg">
                                     <FontAwesomeIcon icon={faMoneyBill1Wave} className="h-5 w-5 flex-shrink-0 text-gray-400 group-hover:text-gray-500" />
                                    <p className="truncate text-gray-500 group-hover:text-gray-900">
                                      {transaction.name}
                                    </p>
                                  </a>
                                </div>
                              </td>
                              <td className="whitespace-nowrap px-6 py-4 text-right text-lg text-gray-500">
                                <span className="font-medium text-gray-900">{transaction.amount}</span>
                                {transaction.currency}
                              </td>
                              <td className="hidden whitespace-nowrap px-6 py-8 text-lg text-gray-500 md:block">
                                <span
                                  className={classNames(
                                    statusStyles[transaction.status],
                                    'inline-flex items-center rounded-full px-2.5 py-0.5 text-base font-medium capitalize'
                                  )}
                                >
                                  {transaction.status}
                                </span>
                              </td>
                              <td className="whitespace-nowrap px-6 py-4 text-right text-lg text-gray-500">
                                <time dateTime={transaction.datetime}>{transaction.date}</time>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                      {/* Pagination */}
                      {/* <nav
                        className="flex items-center justify-between border-t border-peach px-4 py-3 sm:px-6"
                        aria-label="Pagination"
                      >
                        <div className="hidden sm:block">
                          <p className="text-base text-gray-700">
                            Showing <span className="font-medium">1</span> to <span className="font-medium">10</span> of{' '}
                            <span className="font-medium">20</span> results
                          </p>
                        </div>
                        <div className="flex flex-1 justify-between gap-x-3 sm:justify-end">
                          <div className="relative inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:ring-gray-400">
                            Previous
                          </div>
                          <div className="relative inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:ring-gray-400">
                            Next
                          </div>
                        </div>
                      </nav>
                    </div>
                  </div>
                </div>
              </div>  */}
            </div>
          </main>
        </div>
      </div>
    </section>
  )
}

export default Dashboard
