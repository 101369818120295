import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

function CardButton({ icon, title }) {
  return (
    <div className="p-6 bg-blue-500 border border-gray-200 rounded-md shadow flex items-center cursor-pointer hover:bg-blue-600">
      <FontAwesomeIcon icon={icon} className="text-3xl text-white mr-4" />
      <h5 className="text-xl font-semibold tracking-tight text-white">
        {title}
      </h5>
    </div>
  )
}

export default CardButton
