import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faCalendarDays,
  faCircleExclamation,
  faClock,
  faTimes,
  faUser,
} from '@fortawesome/free-solid-svg-icons'

import { useState } from 'react'
import useAuth from '../hooks/useAuth'
import useAxiosPrivate from '../hooks/useAxiosPrivate'
import moment from 'moment'
import { useEffect } from 'react'
import Modal from '../components/Modal'

function ClaimAssessment({
  assessmentServices = [],
  assessmentData = [],
  claimData = null,
  clientId = null,
  confirmOptions,
  insurerHistory
}) {
  const { auth, setShowLoader, createInfo } = useAuth()
  const axios = useAxiosPrivate()
  const [availabilities, setAvailabilities] = useState([])
  const [selectedService, setSelectedService] = useState(0)
  const [selectedTimeSlot, setSelectedTimeSlot] = useState({})
  const [timeSlotsDefault, setTimeSlotsDefault] = useState([])
  const [selectedPractitioner, setSelectedPractitioner] = useState(0)
  const [availabilitiesLoaded, setAvailabilitiesLoaded] = useState(false)
  const [showAllAvailable, setShowAllAvailable] = useState(false)
  const [isInsured, setIsInsured] = useState(true)

  const [reserveData, setReserveData] = useState({})

  // Modal
  const [showModal, setShowModal] = useState(false)
  const [modalTitle, setModalTitle] = useState('')

  

  const [option, setOption] = useState({
    date: moment().format('YYYY-MM-DD'),
    service: 0,
  })

  const [options, setOptions] = useState([])
  const [assessmentOptionsData, setAssessmentOptionsData] = useState([])

  const removeOption = (key) => {
    setOptions(
      options.filter((option, index) => {
        return index !== key
      })
    )
  }

  const checkTimeSlot = (option) => 
  {
      let exists = options.some(item => {
        return item.date + ' ' + item.start + ':00'  === option;
       })
       return exists;
  }

  const reserveTimeClicked = () => {
    setModalTitle(`Not Insured`);
    setShowModal(true);
  }

  const resetModal = () => {
    setIsInsured(true);
    setShowModal(false);
  }

  const ModalBody = () => {
    if (!isInsured) {
      return <NotInsuredElement />
    }
  }

  useEffect(() => {
    setAssessmentOptionsData(assessmentData)
  }, [assessmentData])

  const NotInsuredElement = () => {
    const add = async (e)=> {
      e.preventDefault();
      resetModal()
      setShowLoader(true)
      try {
          setOptions([
      ...options,
      {
        date: moment(reserveData.day, 'DD/MM/YYYY').format('YYYY-MM-DD'),
        start: moment(reserveData.time).format('HH:mm'),
        end: moment(reserveData.time)
          .add(selectedService.default_length, 'minutes')
          .format('HH:mm'),
        user: reserveData.user,
      },
    ])
        setReserveData({})
      } catch (error) {}
      //set timeout to make sure it shows a loader when adding an option to prevent multip click
      setTimeout(() => {
        setShowLoader(false)
      }, 500)
      createInfo('success', 'Option Added!')
      groupAvailabilitiesByDay(timeSlotsDefault)
    }
    return (
      <form onSubmit={add} className="justify-center flex">
        <div className="mb-3">
          <p className="text-lg font-bold my-8 text-center">
            The client is not currently insured, do you wish to proceed?
          </p>
          <div className="flex mt-2 w-full">
            <button className="btn red mt-4 mr-4 w-1/2">
              Add Option
            </button>

            <button
              type="button"
              className="btn mt-4 w-1/2"
              onClick={resetModal}
            >
              Cancel
            </button>
          </div>
        </div>
      </form>
    )
  }

  const loadAvailability = async () => {
    setAvailabilitiesLoaded(false)
    setSelectedPractitioner(0)
    setShowAllAvailable(false)
    setSelectedTimeSlot([])
    setShowLoader(true)
    setOptions([])
    try {
      const response = await axios.get(
        `/availabilities/${option.date}/${selectedService.id}/${option.allUsers}`
      )
      if (!response.data.error) {
        setAvailabilitiesLoaded(true)
        setTimeSlotsDefault(response.data.result)
        groupAvailabilitiesByDay(response.data.result)
      }
    } catch (error) {}
    setShowLoader(false)
  }

  const groupAvailabilitiesByDay = (availabilities) => {
    let arr = {}
    let practitioners = []
    availabilities.forEach((a, index) => {
      let days = {}
      let timeSlots = {}
      a.slots.forEach((slot) => {
        if (days[moment(slot).format('DD/MM/YYYY')]) {
          days[moment(slot).format('DD/MM/YYYY')].push(slot)
        } else {
          days[moment(slot).format('DD/MM/YYYY')] = [slot]
        }

        if (!timeSlots[moment(slot).format('DD/MM/YYYY')]) {
          timeSlots[moment(slot).format('DD/MM/YYYY')] = 0
        }
      })
      arr[a.user.name] = timeSlots
      a.days = days
      practitioners.push(a)
    })
    setAvailabilities(practitioners)
    setSelectedTimeSlot(arr)
  }

  const addOption = (e, user, day, time) => {
    e.preventDefault();
    if (!time) {
      createInfo('error', `Please select a time slot`)
      return
    }

    setShowLoader(true)
    let data = {
      day: day,
      user: user,
      time: time
    }
    setReserveData(data)
    if(insurerHistory.length > 0)
    {
      let policyStart = moment(insurerHistory[0].policy_start_date)
      let policyEnd = moment(insurerHistory[0].policy_end_date)
      let reserveTime = moment(time);
      //if this is false it wills how a modal
      if(!reserveTime.isBetween(policyStart, policyEnd))
      {
        reserveTimeClicked();
        setIsInsured(reserveTime.isBetween(policyStart, policyEnd)); 
        return;
      }
  
      if(reserveTime.isBetween(policyStart, policyEnd))
      {
          setOptions([
            ...options,
            {
              date: moment(day, 'DD/MM/YYYY').format('YYYY-MM-DD'),
              start: moment(time).format('HH:mm'),
              end: moment(time)
                .add(selectedService.default_length, 'minutes')
                .format('HH:mm'),
              user: user,
            },
          ])
        createInfo('success', 'Option Added!')
        groupAvailabilitiesByDay(timeSlotsDefault)
      }
    }
    else 
    {
      reserveTimeClicked();
      setIsInsured(false)
    }
    setShowLoader(false)
  }

  const reserveOptions = async () => {
    setShowLoader(true)
    const data = {
      options: options,
      service: selectedService,
    }

    try {
      const response = await axios.post(
        `/claims/${claimData.id}/reserve-options`,
        data
      )
      if (!response.data.error) {
        assessmentOptionsData.options = response.data.result

        setAssessmentOptionsData(assessmentOptionsData)
      }
    } catch (error) {}
    setShowLoader(false)
  }

  const confirmOption = async (option) => {
    setShowLoader(true)

    try {
      const response = await axios.get(
        `/claims/${claimData.id}/confirm-option/${option.id}`
      )
      if (!response.data.error) {
        confirmOptions()
      }
    } catch (error) {}
    setShowLoader(false)
  }

  const resetOptions = () => {
    setAvailabilities([])
    setSelectedTimeSlot([])
    setAvailabilitiesLoaded(false)
    setOptions([])
    setOption({
      date: moment().format('YYYY-MM-DD'),
      service: 0,
    })
  }

  const resetAssessment = () => {
    setAssessmentOptionsData([])
    setAvailabilities([])
    setSelectedTimeSlot([])
    setAvailabilitiesLoaded(false)
    setOptions([])
    setOption({
      date: moment().format('YYYY-MM-DD'),
      service: 0,
    })
  }

  const ShowAvailability = ({ data }) => {
    return (
      <div className="p-4 rounded-md border border-slate-300 mt-4">
        <p className="font-bold text-lg mb-2">
          {data.user.name}
          {/* <span className="text-sm">(90%)</span> */}
        </p>
        <div className="flex">
          {' '}
          {Object.keys(data.days).map((key, index) => {
            return (
              <form key={index} onSubmit={(e) =>
                addOption(
                  e,
                  data.user,
                  key,
                  selectedTimeSlot[data.user.name][key]
                )} className="w-[300px] relative mr-4">
                <h3 className="font-medium mb-1">
                  {moment(key, 'DD/MM/YYYY').format('dddd Do MMMM')}
                </h3>
                <div>
                  <label
                    htmlFor="service"
                    className="block font-medium text-gray-600 text-sm"
                  >
                    Time Slot
                  </label>
                  <select
                    defaultValue={selectedTimeSlot[data.user.name][key]}
                    className="px-2 py-2 block w-full border border-gray-300 rounded-md shadow-md"
                    required
                    onChange={(e) => {
                      selectedTimeSlot[data.user.name][key] = e.target.value
                    }}
                    id="service"
                  >
                    <option disabled value="0">
                      Select a Time
                    </option>
                    {data.days[key].map((slot) => {
                      return (
                        <option key={slot} value={slot} disabled={checkTimeSlot(slot)}>
                          {moment(slot).format('HH:mm a')}
                        </option>
                      )
                    })}
                  </select>
                </div>
                <button
                  className="btn mt-4"
                >
                  Add Option
                </button>
              </form>
            )
          })}
        </div>
      </div>
    )
  }

  return (
    <div>
      {showModal && (
        <Modal title={modalTitle} body={<ModalBody />} show={resetModal} />
      )}
      {assessmentOptionsData?.options?.length > 0 ? (
        <>
          <div
            className="bg-blue-100 rounded-lg border text-blue-600 p-2 border-blue-300 flex items-center mb-4"
            role="alert"
          >
            <FontAwesomeIcon
              icon={faCircleExclamation}
              className="mr-2 text-xl"
            />
            <p>
              The below options have been sent to the client and we are awaiting
              a response.
            </p>
          </div>
          <div className="mb-4 flex">
            {assessmentOptionsData?.options.map((option, key) => {
             if(option != null){
              return (
                <div
                  key={key}
                  className="border rounded-xl shadow-md p-4 w-[300px] relative mr-4"
                >
                  <p className="font-bold">Option {key + 1}</p>
                  <div>
                    <p>
                      <FontAwesomeIcon
                        icon={faCalendarDays}
                        className="text-slate-400 mr-2"
                      />
                      <span>
                        {moment(option.start_time).format('ddd Do MMMM')}
                      </span>
                    </p>
                    <p>
                      <FontAwesomeIcon
                        icon={faClock}
                        className="text-slate-400 mr-2"
                      />
                      {moment(option.start_time).format('h:mm a')} -{' '}
                      {moment(option.end_time).format('h:mm a')}
                    </p>
                    <p>
                      <FontAwesomeIcon
                        icon={faUser}
                        className="text-slate-400 mr-2"
                      />
                      {option.user.name}
                    </p>
                  </div>
                  <p
                    className="text-blue-500 text-right cursor-pointer hover:text-blue-600"
                    onClick={() => {
                      confirmOption(option)
                    }}
                  >
                    Confirm
                  </p>
                </div>
              )
             }else {
              return (
                <div></div>
              )
             }
            })}
          </div>
          <button className="btn" onClick={resetAssessment}>
            Find New Times
          </button>
        </>
      ) : (
        <>
          <div
            className="bg-blue-100 rounded-lg border text-blue-600 p-2 border-blue-300 flex items-center mb-4"
            role="alert"
          >
            <FontAwesomeIcon
              icon={faCircleExclamation}
              className="mr-2 text-xl"
            />
            <p>
              This claim requires an initial assessment before any appointments
              can be made
            </p>
          </div>

          <div className="mb-4 flex">
            {options.map((option, key) => {
              return (
                <div
                  key={key}
                  className="border rounded-xl shadow-md p-4 w-[300px] relative mr-4"
                >
                  <FontAwesomeIcon
                    icon={faTimes}
                    className="absolute top-2 right-4 text-red-500 text-xl cursor-pointer hover:text-red-600"
                    onClick={() => removeOption(key)}
                  />
                  <p className="font-bold mb-1">Option {key + 1}</p>
                  <div>
                    <p>
                      <FontAwesomeIcon
                        icon={faCalendarDays}
                        className="text-slate-400 mr-2"
                      />
                      <span>{moment(option.date).format('ddd Do MMMM')}</span>
                    </p>
                    <p>
                      <FontAwesomeIcon
                        icon={faClock}
                        className="text-slate-400 mr-2"
                      />
                      {moment(option.start, 'HH:mm').format('h:mm a')} -{' '}
                      {moment(option.end, 'HH:mm').format('h:mm a')}
                    </p>
                    <p>
                      <FontAwesomeIcon
                        icon={faUser}
                        className="text-slate-400 mr-2"
                      />
                      {option.user.name}
                    </p>
                  </div>
                </div>
              )
            })}
          </div>

          <div className="max-w-lg">
            <div className="mb-3">
              <label
                htmlFor="service"
                className="block font-medium text-gray-600 text-sm"
              >
                Service
              </label>
              <select
                defaultValue={selectedService}
                className="px-2 py-2 block w-full border border-gray-300 rounded-md shadow-md"
                required
                onChange={(e) => {
                  setAvailabilitiesLoaded(false)
                  setSelectedPractitioner(0)
                  setShowAllAvailable(false)
                  setSelectedService(JSON.parse(e.target.value))
                }}
                id="service"
                disabled={options.length > 0}
              >
                <option disabled value="0">
                  Select a Service
                </option>
                {assessmentServices.map((service) => {
                  return (
                    <option key={service.id} value={JSON.stringify(service)}>
                      {service.title}
                    </option>
                  )
                })}
              </select>
            </div>
          </div>
          {options.length < 2 ? (
            <div>
              <div>
                <h3 className="font-bold text-lg mb-2">Add an Option</h3>
                <div className="max-w-lg">
                  <div className="mb-3">
                    <label
                      htmlFor="date"
                      className="block font-medium text-gray-600 text-sm"
                    >
                      Week Start
                    </label>
                    <div className="mt-1 relative rounded-md">
                      <input
                        required
                        type="date"
                        name="date"
                        id="date"
                        defaultValue={option.date}
                        onChange={(e) => {
                          setAvailabilitiesLoaded(false)
                          setSelectedPractitioner(0)
                          setShowAllAvailable(false)
                          option.date = e.target.value
                        }}
                        min={moment().format('YYYY-MM-DD')}
                        className="px-4 py-2 block w-full border border-gray-300 rounded-md shadow-md"
                      />
                    </div>
                  </div>

                  <div className=" w-full mr-2 flex items-center mb-2">
                    <div className="relative mr-2">
                      <input
                        type="checkbox"
                        id="get_all"
                        onChange={(e) => {
                          setAvailabilitiesLoaded(false)
                          setSelectedPractitioner(0)
                          setShowAllAvailable(false)
                          setOption({
                            date: option.date,
                            start: option.start,
                            end: option.end,
                            user: option.user,
                            allUsers: e.target.checked,
                          })
                        }}
                        placeholder="All Practitioners"
                        className="field"
                        checked={option.allUsers}
                      />
                    </div>
                    <label
                      htmlFor="get_all"
                      className="block font-medium text-gray-600 text-sm"
                    >
                      All Practitioners
                    </label>
                  </div>
                  {selectedService !== 0 && (
                    <button className="btn" onClick={loadAvailability}>
                      Load Availability
                    </button>
                  )}
                </div>
              </div>
              {availabilities.length > 0 && availabilitiesLoaded && (
                <div className="border-t border-slate-200 mt-4">
                  <h3 className="font-bold text-lg mb-2 mt-4">
                    Available Practitioners ({availabilities.length})
                  </h3>
                  <div className="flex flex-col">
                    <div className=" w-full mr-2 flex items-center mb-2">
                      <div className="relative mr-2">
                        <input
                          type="checkbox"
                          id="show_all"
                          onChange={(e) => {
                            setSelectedPractitioner(0)
                            setShowAllAvailable(e.target.checked)
                          }}
                          placeholder="Show all Available"
                          className="field"
                          checked={showAllAvailable}
                        />
                      </div>
                      <label
                        htmlFor="show_all"
                        className="block font-medium text-gray-600 text-sm"
                      >
                        Show All Available Practitioners
                      </label>
                    </div>
                    {!showAllAvailable && (
                      <div className="w-[300px] relative mr-4">
                        <div className="mb-3">
                          <label
                            htmlFor="service"
                            className="block font-medium text-gray-600 text-sm"
                          >
                            Practitioner
                          </label>
                          <select
                            defaultValue={selectedPractitioner}
                            className="px-2 py-2 block w-full border border-gray-300 rounded-md shadow-md"
                            required
                            onChange={(e) => {
                              setSelectedPractitioner(
                                JSON.parse(e.target.value)
                              )
                            }}
                            id="service"
                          >
                            <option disabled value="0">
                              Select a Practitioner
                            </option>
                            {availabilities.map((availability) => {
                              return (
                                <option
                                  key={availability.user.id}
                                  value={JSON.stringify(availability)}
                                >
                                  {availability.user.name}
                                </option>
                              )
                            })}
                          </select>
                        </div>
                      </div>
                    )}

                    {selectedPractitioner !== 0 && (
                      <ShowAvailability
                        data={selectedPractitioner}
                        key={selectedPractitioner.user.id}
                      />
                    )}

                    {showAllAvailable &&
                      availabilities.map((availability) => {
                        return (
                          <ShowAvailability
                            key={availability.user.id}
                            data={availability}
                          />
                        )
                      })}
                  </div>
                </div>
              )}
              {availabilities.length <= 0 && availabilitiesLoaded && (
                <h3 className="font-bold text-lg mb-2 mt-4">
                  No Available Practitioners
                </h3>
              )}
            </div>
          ) : (
            <div className="flex">
              <button className="btn mr-4 green" onClick={reserveOptions}>
                Reserve Options
              </button>

              <button className="btn" onClick={resetOptions}>
                Find New Times
              </button>
            </div>
          )}
        </>
      )}
    </div>
  )
}

export default ClaimAssessment
