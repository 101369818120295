function Loader() {
  return (
    <div
      className="fixed top-0 left-0 w-screen h-screen flex justify-center items-center"
      style={{ zIndex: 999 }}
    >
      <div className="bg-slate-700/50 w-full h-full">&nbsp;</div>
      <div className="flex flex-col items-center justify-center absolute bg-white w-[400px] h-[200px] rounded-xl shadow-md">
        <div className="loader ease-linear rounded-full border-8 border-t-8 border-gray-200 h-20 w-20 mb-2"></div>
        <h2 className="text-center text-sovereign text-xl font-semibold">
          Loading...
        </h2>
      </div>
    </div>
  )
}
export default Loader
