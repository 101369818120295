import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPenToSquare, faTrash, faEye } from '@fortawesome/free-solid-svg-icons'

const Team = () => {
  const people = [{name: 'Taylor Stocks', role: 'CTO', imageUrl: 'https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80'}]
  return (
    <div className="py-16 max-w-7xl mx-auto font-montserrat">
      <div className="flex items-start justify-between">
        <div className="max-w-2xl">
          <h2 className="text-3xl font-bold tracking-tight text-slate-800 sm:text-4xl">Manage Offices</h2>
          <p className="mt-3 text-base leading-8 text-gray-600">
          Create and manage different offices to segment access and record visibility.
          </p>
        </div>
        <div>
          <button className='bg-peach rounded-lg shadow px-4 py-2 text-slate-50 font-semibold hover:bg-peach-300 hover:text-white'>Create New Team</button>
        </div>  
      </div>
      <div className='grid grid-cols-3 gap-4'>
            <div className="mt-16 bg-white rounded-xl p-4 shadow-md">
                <div className="mx-auto max-w-2xl lg:mx-0">
                  <h2 className="text-3xl font-bold tracking-tight text-slate-800 sm:text-2xl">Belfast</h2>
                </div>
                <ul className="pt-6 "
                >
                  <li className='pb-2 text-slate-700'>Managers</li>
                  {people.map((person) => (
                    <li key={person.name} className='flex'>
                      <img className="h-12 w-12 rounded-full" src={person.imageUrl} alt="" />
                      <img className="h-12 w-12 rounded-full" src="https://images.unsplash.com/photo-1417325384643-aac51acc9e5d?q=75&fm=jpg&w=400&fit=max" alt="" />
                    </li>
                  ))}
                </ul>
                <ul className="pt-6 "
                >
                  <li className='pb-2 text-slate-700'>Team Members</li>
                  {people.map((person) => (
                    <li key={person.name} className='flex'>
                      <img className="h-10 w-10 rounded-full" src="https://ui-avatars.com/api/?name=John+Doe" alt="" />
                      <img className="h-10 w-10 rounded-full" src="https://ui-avatars.com/api/?name=Gohn+Foe&background=0D8ABC&color=fff" alt="" />
                      <img className="h-10 w-10 rounded-full" src="https://ui-avatars.com/api/?name=Tohn+Soe&background=361C3B&color=fff" alt="" />
                    </li>
                  ))}
                </ul>
                <div className="flex items-center justify-end pt-4 space-x-2">
                  <button>
                    <FontAwesomeIcon
                      title='Edit Client'
                      icon={faTrash}
                      className='text-red-800 hover:text-red-500 h-5 w-5 cursor-pointer'
                    />
                  </button>
                  <button>
                    <FontAwesomeIcon
                        title='Edit Client'
                        icon={faPenToSquare}
                        className='text-green-700 hover:text-green-500 h-5 w-5 cursor-pointer'
                      />
                  </button>
                  <button>
                    <FontAwesomeIcon
                        title='Edit Client'
                        icon={faEye}
                        className='text-blue-700 hover:text-blue-500 h-6 w-6 cursor-pointer'
                      />
                  </button>
                </div>
            </div>
            <div className="mt-16 bg-white rounded-xl p-4 shadow-md">
                <div className="mx-auto max-w-2xl lg:mx-0">
                  <h2 className="text-3xl font-bold tracking-tight text-slate-800 sm:text-2xl">Birmingham</h2>
                </div>
                <ul className="pt-6">
                  <li className='pb-2 text-slate-700'>Managers</li>
                  {people.map((person) => (
                    <li key={person.name}>
                      <img className="h-12 w-12 rounded-full" src="https://images.unsplash.com/photo-1417325384643-aac51acc9e5d?q=75&fm=jpg&w=400&fit=max" alt="" />
                    </li>
                  ))}
                </ul>
                <ul className="pt-6">
                  <li className='pb-2 text-slate-700'>Team Members</li>
                  {people.map((person) => (
                    <li key={person.name} className='flex'>
                      <img className="h-10 w-10 rounded-full" src="https://ui-avatars.com/api/?name=Kohn+Koe" alt="" />
                      <img className="h-10 w-10 rounded-full" src="https://ui-avatars.com/api/?name=Tohn+Hoe&background=805B87&color=fff" alt="" />
                      <img className="h-10 w-10 rounded-full" src="https://ui-avatars.com/api/?name=Bohn+Koe&background=D6E3E3" alt="" />
                    </li>
                  ))}
                </ul>
                <div className="flex items-center justify-end pt-4 space-x-2">
                  <button>
                    <FontAwesomeIcon
                      title='Edit Client'
                      icon={faTrash}
                      className='text-red-800 hover:text-red-500 h-5 w-5 cursor-pointer'
                    />
                  </button>
                  <button>
                    <FontAwesomeIcon
                        title='Edit Client'
                        icon={faPenToSquare}
                        className='text-green-700 hover:text-green-500 h-5 w-5 cursor-pointer'
                      />
                  </button>
                  <button>
                    <FontAwesomeIcon
                        title='Edit Client'
                        icon={faEye}
                        className='text-blue-700 hover:text-blue-500 h-6 w-6 cursor-pointer'
                      />
                  </button>
                </div>
            </div>
            <div className="mt-16 bg-white rounded-xl p-4 shadow-md">
                <div className="mx-auto max-w-2xl lg:mx-0">
                  <h2 className="text-3xl font-bold tracking-tight text-slate-800 sm:text-2xl">Bournemouth</h2>
                </div>
                <ul className="pt-6">
                  <li className='pb-2 text-slate-700'>Managers</li>
                  {people.map((person) => (
                    <li key={person.name}>
                      <img className="h-12 w-12 rounded-full" src="https://images.unsplash.com/profile-1446404465118-3a53b909cc82?ixlib=rb-0.3.5&q=80&fm=jpg&crop=faces&cs=tinysrgb&fit=crop&h=32&w=32&s=a2f8c40e39b8dfee1534eb32acfa6bc7" alt="" />
                    </li>
                  ))}
                </ul>
                <ul className="pt-6">
                  <li className='pb-2 text-slate-700'>Team Members</li>
                  {people.map((person) => (
                    <li key={person.name} className='flex'>
                      <img className="h-10 w-10 rounded-full" src="https://ui-avatars.com/api/?name=Gohn+Roe&background=A3A7FC&color=fff" alt="" />
                      <img className="h-10 w-10 rounded-full" src="https://ui-avatars.com/api/?name=Hohn+Goe&background=F6D78B&color=fff" alt="" />
                      <img className="h-10 w-10 rounded-full" src="https://ui-avatars.com/api/?name=Oohn+Woe&background=361C3B&color=fff" alt="" />
                    </li>
                  ))}
                </ul>
                <div className="flex items-center justify-end pt-4 space-x-2">
                  <button>
                    <FontAwesomeIcon
                      title='Edit Client'
                      icon={faTrash}
                      className='text-red-800 hover:text-red-500 h-5 w-5 cursor-pointer'
                    />
                  </button>
                  <button>
                    <FontAwesomeIcon
                        title='Edit Client'
                        icon={faPenToSquare}
                        className='text-green-700 hover:text-green-500 h-5 w-5 cursor-pointer'
                      />
                  </button>
                  <button>
                    <FontAwesomeIcon
                        title='Edit Client'
                        icon={faEye}
                        className='text-blue-700 hover:text-blue-500 h-6 w-6 cursor-pointer'
                      />
                  </button>
                </div>
            </div>
        </div>
          <div className='grid grid-cols-3 gap-4'>
            <div className="mt-4 bg-white rounded-xl p-4 shadow-md">
                <div className="mx-auto max-w-2xl lg:mx-0">
                  <h2 className="text-3xl font-bold tracking-tight text-slate-800 sm:text-2xl">Bristol</h2>
                </div>
                <ul className="pt-6">
                  <li className='pb-2 text-slate-700'>Managers</li>
                  {people.map((person) => (
                    <li key={person.name}>
                      <img className="h-12 w-12 rounded-full" src="https://images.unsplash.com/profile-1446404465118-3a53b909cc82?ixlib=rb-0.3.5&q=80&fm=jpg&crop=faces&cs=tinysrgb&fit=crop&h=32&w=32&s=a2f8c40e39b8dfee1534eb32acfa6bc7" alt="" />
                    </li>
                  ))}
                </ul>
                <ul className="pt-6">
                  <li className='pb-2 text-slate-700'>Team Members</li>
                  {people.map((person) => (
                    <li key={person.name} className='flex'>
                      <img className="h-10 w-10 rounded-full" src="https://ui-avatars.com/api/?name=Gohn+Roe&background=A3A7FC&color=fff" alt="" />
                      <img className="h-10 w-10 rounded-full" src="https://ui-avatars.com/api/?name=Hohn+Goe&background=F6D78B&color=fff" alt="" />
                      <img className="h-10 w-10 rounded-full" src="https://ui-avatars.com/api/?name=Oohn+Woe&background=361C3B&color=fff" alt="" />
                    </li>
                  ))}
                </ul>
                <div className="flex items-center justify-end pt-4 space-x-2">
                  <button>
                    <FontAwesomeIcon
                      title='Edit Client'
                      icon={faTrash}
                      className='text-red-800 hover:text-red-500 h-5 w-5 cursor-pointer'
                    />
                  </button>
                  <button>
                    <FontAwesomeIcon
                        title='Edit Client'
                        icon={faPenToSquare}
                        className='text-green-700 hover:text-green-500 h-5 w-5 cursor-pointer'
                      />
                  </button>
                  <button>
                    <FontAwesomeIcon
                        title='Edit Client'
                        icon={faEye}
                        className='text-blue-700 hover:text-blue-500 h-6 w-6 cursor-pointer'
                      />
                  </button>
                </div>
            </div>
            <div className="mt-4 bg-white rounded-xl p-4 shadow-md">
                <div className="mx-auto max-w-2xl lg:mx-0">
                  <h2 className="text-3xl font-bold tracking-tight text-slate-800 sm:text-2xl">Chichester</h2>
                </div>
                <ul className="pt-6">
                  <li className='pb-2 text-slate-700'>Managers</li>
                  {people.map((person) => (
                    <li key={person.name}>
                      <img className="h-12 w-12 rounded-full" src="https://images.unsplash.com/photo-1463453091185-61582044d556?ixlib=rb-0.3.5&q=80&fm=jpg&crop=faces&cs=tinysrgb&fit=crop&h=32&w=32&s=a2f8c40e39b8dfee1534eb32acfa6bc7" alt="" />
                    </li>
                  ))}
                </ul>
                <ul className="pt-6">
                  <li className='pb-2 text-slate-700'>Team Members</li>
                  {people.map((person) => (
                    <li key={person.name} className='flex'>
                      <img className="h-10 w-10 rounded-full" src="https://ui-avatars.com/api/?name=Zohn+Ooe&background=7dd3fc&color=fff" alt="" />
                      <img className="h-10 w-10 rounded-full" src="https://ui-avatars.com/api/?name=Iohn+Noe&background=fb7185&color=fff" alt="" />
                      <img className="h-10 w-10 rounded-full" src="https://ui-avatars.com/api/?name=Cohn+Doe&background=a3e635&color=fff" alt="" />
                    </li>
                  ))}
                </ul>
                <div className="flex items-center justify-end pt-4 space-x-2">
                  <button>
                    <FontAwesomeIcon
                      title='Edit Client'
                      icon={faTrash}
                      className='text-red-800 hover:text-red-500 h-5 w-5 cursor-pointer'
                    />
                  </button>
                  <button>
                    <FontAwesomeIcon
                        title='Edit Client'
                        icon={faPenToSquare}
                        className='text-green-700 hover:text-green-500 h-5 w-5 cursor-pointer'
                      />
                  </button>
                  <button>
                    <FontAwesomeIcon
                        title='Edit Client'
                        icon={faEye}
                        className='text-blue-700 hover:text-blue-500 h-6 w-6 cursor-pointer'
                      />
                  </button>
                </div>
            </div>
            <div className="mt-4 bg-white rounded-xl p-4 shadow-md">
                <div className="mx-auto max-w-2xl lg:mx-0">
                  <h2 className="text-3xl font-bold tracking-tight text-slate-800 sm:text-2xl">Edinburgh Lochside</h2>
                </div>
                <ul className="pt-6">
                  <li className='pb-2 text-slate-700'>Managers</li>
                  {people.map((person) => (
                    <li key={person.name}>
                      <img className="h-12 w-12 rounded-full" src="https://images.unsplash.com/photo-1463453091185-61582044d556?ixlib=rb-0.3.5&q=80&fm=jpg&crop=faces&cs=tinysrgb&fit=crop&h=32&w=32&s=a2f8c40e39b8dfee1534eb32acfa6bc7" alt="" />
                    </li>
                  ))}
                </ul>
                <ul className="pt-6">
                  <li className='pb-2 text-slate-700'>Team Members</li>
                  {people.map((person) => (
                    <li key={person.name} className='flex'>
                      <img className="h-10 w-10 rounded-full" src="https://ui-avatars.com/api/?name=Zohn+Ooe&background=7dd3fc&color=fff" alt="" />
                      <img className="h-10 w-10 rounded-full" src="https://ui-avatars.com/api/?name=Iohn+Noe&background=fb7185&color=fff" alt="" />
                      <img className="h-10 w-10 rounded-full" src="https://ui-avatars.com/api/?name=Cohn+Doe&background=a3e635&color=fff" alt="" />
                    </li>
                  ))}
                </ul>
                <div className="flex items-center justify-end pt-4 space-x-2">
                  <button>
                    <FontAwesomeIcon
                      title='Edit Client'
                      icon={faTrash}
                      className='text-red-800 hover:text-red-500 h-5 w-5 cursor-pointer'
                    />
                  </button>
                  <button>
                    <FontAwesomeIcon
                        title='Edit Client'
                        icon={faPenToSquare}
                        className='text-green-700 hover:text-green-500 h-5 w-5 cursor-pointer'
                      />
                  </button>
                  <button>
                    <FontAwesomeIcon
                        title='Edit Client'
                        icon={faEye}
                        className='text-blue-700 hover:text-blue-500 h-6 w-6 cursor-pointer'
                      />
                  </button>
                </div>
            </div>
          </div>
          <div className='grid grid-cols-3 gap-4'>
          <div className="mt-4 bg-white rounded-xl p-4 shadow-md">
              <div className="mx-auto max-w-2xl lg:mx-0">
                <h2 className="text-3xl font-bold tracking-tight text-slate-800 sm:text-2xl">Exeter</h2>
              </div>
              <ul className="pt-6">
                <li className='pb-2 text-slate-700'>Managers</li>
                {people.map((person) => (
                  <li key={person.name}>
                    <img className="h-12 w-12 rounded-full" src="https://images.unsplash.com/photo-1463453091185-61582044d556?ixlib=rb-0.3.5&q=80&fm=jpg&crop=faces&cs=tinysrgb&fit=crop&h=32&w=32&s=a2f8c40e39b8dfee1534eb32acfa6bc7" alt="" />
                  </li>
                ))}
              </ul>
              <ul className="pt-6">
                <li className='pb-2 text-slate-700'>Team Members</li>
                {people.map((person) => (
                  <li key={person.name} className='flex'>
                    <img className="h-10 w-10 rounded-full" src="https://ui-avatars.com/api/?name=Zohn+Ooe&background=7dd3fc&color=fff" alt="" />
                    <img className="h-10 w-10 rounded-full" src="https://ui-avatars.com/api/?name=Iohn+Noe&background=fb7185&color=fff" alt="" />
                    <img className="h-10 w-10 rounded-full" src="https://ui-avatars.com/api/?name=Cohn+Doe&background=a3e635&color=fff" alt="" />
                  </li>
                ))}
              </ul>
              <div className="flex items-center justify-end pt-4 space-x-2">
                <button>
                  <FontAwesomeIcon
                    title='Edit Client'
                    icon={faTrash}
                    className='text-red-800 hover:text-red-500 h-5 w-5 cursor-pointer'
                  />
                </button>
                <button>
                  <FontAwesomeIcon
                      title='Edit Client'
                      icon={faPenToSquare}
                      className='text-green-700 hover:text-green-500 h-5 w-5 cursor-pointer'
                    />
                </button>
                <button>
                  <FontAwesomeIcon
                      title='Edit Client'
                      icon={faEye}
                      className='text-blue-700 hover:text-blue-500 h-6 w-6 cursor-pointer'
                    />
                </button>
              </div>
          </div>
          <div className="mt-4 bg-white rounded-xl p-4 shadow-md">
              <div className="mx-auto max-w-2xl lg:mx-0">
                <h2 className="text-3xl font-bold tracking-tight text-slate-800 sm:text-2xl">Glasgow</h2>
              </div>
              <ul className="pt-6">
                <li className='pb-2 text-slate-700'>Managers</li>
                {people.map((person) => (
                  <li key={person.name}>
                    <img className="h-12 w-12 rounded-full" src="https://images.unsplash.com/photo-1463453091185-61582044d556?ixlib=rb-0.3.5&q=80&fm=jpg&crop=faces&cs=tinysrgb&fit=crop&h=32&w=32&s=a2f8c40e39b8dfee1534eb32acfa6bc7" alt="" />
                  </li>
                ))}
              </ul>
              <ul className="pt-6">
                <li className='pb-2 text-slate-700'>Team Members</li>
                {people.map((person) => (
                  <li key={person.name} className='flex'>
                    <img className="h-10 w-10 rounded-full" src="https://ui-avatars.com/api/?name=Zohn+Ooe&background=7dd3fc&color=fff" alt="" />
                    <img className="h-10 w-10 rounded-full" src="https://ui-avatars.com/api/?name=Iohn+Noe&background=fb7185&color=fff" alt="" />
                    <img className="h-10 w-10 rounded-full" src="https://ui-avatars.com/api/?name=Cohn+Doe&background=a3e635&color=fff" alt="" />
                  </li>
                ))}
              </ul>
              <div className="flex items-center justify-end pt-4 space-x-2">
                <button>
                  <FontAwesomeIcon
                    title='Edit Client'
                    icon={faTrash}
                    className='text-red-800 hover:text-red-500 h-5 w-5 cursor-pointer'
                  />
                </button>
                <button>
                  <FontAwesomeIcon
                      title='Edit Client'
                      icon={faPenToSquare}
                      className='text-green-700 hover:text-green-500 h-5 w-5 cursor-pointer'
                    />
                </button>
                <button>
                  <FontAwesomeIcon
                      title='Edit Client'
                      icon={faEye}
                      className='text-blue-700 hover:text-blue-500 h-6 w-6 cursor-pointer'
                    />
                </button>
              </div>
          </div>
          <div className="mt-4 bg-white rounded-xl p-4 shadow-md">
              <div className="mx-auto max-w-2xl lg:mx-0">
                <h2 className="text-3xl font-bold tracking-tight text-slate-800 sm:text-2xl">Leatherhead</h2>
              </div>
              <ul className="pt-6">
                <li className='pb-2 text-slate-700'>Managers</li>
                {people.map((person) => (
                  <li key={person.name}>
                    <img className="h-12 w-12 rounded-full" src="https://images.unsplash.com/photo-1463453091185-61582044d556?ixlib=rb-0.3.5&q=80&fm=jpg&crop=faces&cs=tinysrgb&fit=crop&h=32&w=32&s=a2f8c40e39b8dfee1534eb32acfa6bc7" alt="" />
                  </li>
                ))}
              </ul>
              <ul className="pt-6">
                <li className='pb-2 text-slate-700'>Team Members</li>
                {people.map((person) => (
                  <li key={person.name} className='flex'>
                    <img className="h-10 w-10 rounded-full" src="https://ui-avatars.com/api/?name=Zohn+Ooe&background=7dd3fc&color=fff" alt="" />
                    <img className="h-10 w-10 rounded-full" src="https://ui-avatars.com/api/?name=Iohn+Noe&background=fb7185&color=fff" alt="" />
                    <img className="h-10 w-10 rounded-full" src="https://ui-avatars.com/api/?name=Cohn+Doe&background=a3e635&color=fff" alt="" />
                  </li>
                ))}
              </ul>
              <div className="flex items-center justify-end pt-4 space-x-2">
                <button>
                  <FontAwesomeIcon
                    title='Edit Client'
                    icon={faTrash}
                    className='text-red-800 hover:text-red-500 h-5 w-5 cursor-pointer'
                  />
                </button>
                <button>
                  <FontAwesomeIcon
                      title='Edit Client'
                      icon={faPenToSquare}
                      className='text-green-700 hover:text-green-500 h-5 w-5 cursor-pointer'
                    />
                </button>
                <button>
                  <FontAwesomeIcon
                      title='Edit Client'
                      icon={faEye}
                      className='text-blue-700 hover:text-blue-500 h-6 w-6 cursor-pointer'
                    />
                </button>
              </div>
          </div>
          </div>
          <div className='grid grid-cols-3 gap-4'>
          <div className="mt-4 bg-white rounded-xl p-4 shadow-md">
              <div className="mx-auto max-w-2xl lg:mx-0">
                <h2 className="text-3xl font-bold tracking-tight text-slate-800 sm:text-2xl">Liverpool</h2>
              </div>
              <ul className="pt-6">
                <li className='pb-2 text-slate-700'>Managers</li>
                {people.map((person) => (
                  <li key={person.name}>
                    <img className="h-12 w-12 rounded-full" src="https://images.unsplash.com/photo-1463453091185-61582044d556?ixlib=rb-0.3.5&q=80&fm=jpg&crop=faces&cs=tinysrgb&fit=crop&h=32&w=32&s=a2f8c40e39b8dfee1534eb32acfa6bc7" alt="" />
                  </li>
                ))}
              </ul>
              <ul className="pt-6">
                <li className='pb-2 text-slate-700'>Team Members</li>
                {people.map((person) => (
                  <li key={person.name} className='flex'>
                    <img className="h-10 w-10 rounded-full" src="https://ui-avatars.com/api/?name=Zohn+Ooe&background=7dd3fc&color=fff" alt="" />
                    <img className="h-10 w-10 rounded-full" src="https://ui-avatars.com/api/?name=Iohn+Noe&background=fb7185&color=fff" alt="" />
                    <img className="h-10 w-10 rounded-full" src="https://ui-avatars.com/api/?name=Cohn+Doe&background=a3e635&color=fff" alt="" />
                  </li>
                ))}
              </ul>
              <div className="flex items-center justify-end pt-4 space-x-2">
                <button>
                  <FontAwesomeIcon
                    title='Edit Client'
                    icon={faTrash}
                    className='text-red-800 hover:text-red-500 h-5 w-5 cursor-pointer'
                  />
                </button>
                <button>
                  <FontAwesomeIcon
                      title='Edit Client'
                      icon={faPenToSquare}
                      className='text-green-700 hover:text-green-500 h-5 w-5 cursor-pointer'
                    />
                </button>
                <button>
                  <FontAwesomeIcon
                      title='Edit Client'
                      icon={faEye}
                      className='text-blue-700 hover:text-blue-500 h-6 w-6 cursor-pointer'
                    />
                </button>
              </div>
          </div>
          <div className="mt-4 bg-white rounded-xl p-4 shadow-md">
              <div className="mx-auto max-w-2xl lg:mx-0">
                <h2 className="text-3xl font-bold tracking-tight text-slate-800 sm:text-2xl">London</h2>
              </div>
              <ul className="pt-6">
                <li className='pb-2 text-slate-700'>Managers</li>
                {people.map((person) => (
                  <li key={person.name}>
                    <img className="h-12 w-12 rounded-full" src="https://images.unsplash.com/photo-1463453091185-61582044d556?ixlib=rb-0.3.5&q=80&fm=jpg&crop=faces&cs=tinysrgb&fit=crop&h=32&w=32&s=a2f8c40e39b8dfee1534eb32acfa6bc7" alt="" />
                  </li>
                ))}
              </ul>
              <ul className="pt-6">
                <li className='pb-2 text-slate-700'>Team Members</li>
                {people.map((person) => (
                  <li key={person.name} className='flex'>
                    <img className="h-10 w-10 rounded-full" src="https://ui-avatars.com/api/?name=Zohn+Ooe&background=7dd3fc&color=fff" alt="" />
                    <img className="h-10 w-10 rounded-full" src="https://ui-avatars.com/api/?name=Iohn+Noe&background=fb7185&color=fff" alt="" />
                    <img className="h-10 w-10 rounded-full" src="https://ui-avatars.com/api/?name=Cohn+Doe&background=a3e635&color=fff" alt="" />
                  </li>
                ))}
              </ul>
              <div className="flex items-center justify-end pt-4 space-x-2">
                <button>
                  <FontAwesomeIcon
                    title='Edit Client'
                    icon={faTrash}
                    className='text-red-800 hover:text-red-500 h-5 w-5 cursor-pointer'
                  />
                </button>
                <button>
                  <FontAwesomeIcon
                      title='Edit Client'
                      icon={faPenToSquare}
                      className='text-green-700 hover:text-green-500 h-5 w-5 cursor-pointer'
                    />
                </button>
                <button>
                  <FontAwesomeIcon
                      title='Edit Client'
                      icon={faEye}
                      className='text-blue-700 hover:text-blue-500 h-6 w-6 cursor-pointer'
                    />
                </button>
              </div>
          </div>
          <div className="mt-4 bg-white rounded-xl p-4 shadow-md">
              <div className="mx-auto max-w-2xl lg:mx-0">
                <h2 className="text-3xl font-bold tracking-tight text-slate-800 sm:text-2xl">Manchester</h2>
              </div>
              <ul className="pt-6">
                <li className='pb-2 text-slate-700'>Managers</li>
                {people.map((person) => (
                  <li key={person.name}>
                    <img className="h-12 w-12 rounded-full" src="https://images.unsplash.com/photo-1463453091185-61582044d556?ixlib=rb-0.3.5&q=80&fm=jpg&crop=faces&cs=tinysrgb&fit=crop&h=32&w=32&s=a2f8c40e39b8dfee1534eb32acfa6bc7" alt="" />
                  </li>
                ))}
              </ul>
              <ul className="pt-6">
                <li className='pb-2 text-slate-700'>Team Members</li>
                {people.map((person) => (
                  <li key={person.name} className='flex'>
                    <img className="h-10 w-10 rounded-full" src="https://ui-avatars.com/api/?name=Zohn+Ooe&background=7dd3fc&color=fff" alt="" />
                    <img className="h-10 w-10 rounded-full" src="https://ui-avatars.com/api/?name=Iohn+Noe&background=fb7185&color=fff" alt="" />
                    <img className="h-10 w-10 rounded-full" src="https://ui-avatars.com/api/?name=Cohn+Doe&background=a3e635&color=fff" alt="" />
                  </li>
                ))}
              </ul>
              <div className="flex items-center justify-end pt-4 space-x-2">
                <button>
                  <FontAwesomeIcon
                    title='Edit Client'
                    icon={faTrash}
                    className='text-red-800 hover:text-red-500 h-5 w-5 cursor-pointer'
                  />
                </button>
                <button>
                  <FontAwesomeIcon
                      title='Edit Client'
                      icon={faPenToSquare}
                      className='text-green-700 hover:text-green-500 h-5 w-5 cursor-pointer'
                    />
                </button>
                <button>
                  <FontAwesomeIcon
                      title='Edit Client'
                      icon={faEye}
                      className='text-blue-700 hover:text-blue-500 h-6 w-6 cursor-pointer'
                    />
                </button>
              </div>
          </div>
          </div>
      </div>
  )

}

export default Team