import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons'
import { useState } from 'react'

function TriageDetails({ claimData, clientData, referrableServices = [] }) {
  const [toggle, setToggle] = useState(true)
  return (
    <div>
      {claimData.triage_options && claimData.triage_options.reason && (
        <div>
          <hr className="mt-4" />
          <button
            onClick={() => setToggle(!toggle)}
            className="flex items-center my-4 justify-between w-1/4"
          >
            <h2 className="text-lg ">Triage Details</h2>{' '}
            <FontAwesomeIcon
              icon={toggle ? faChevronDown : faChevronUp}
              className="text-gray-400"
            />
          </button>
          {!toggle && (
            <>
              <div className="grid grid-cols-3 gap-4">
                <div>
                  <span className="font-medium">Name:</span> {clientData.name}
                </div>
                <div>
                  <span className="font-medium">DOB:</span>{' '}
                  {clientData.client_details.date_of_birth}
                </div>
                <div>
                  <span className="font-medium">Address:</span>{' '}
                  {clientData.client_details.home_address}
                </div>
                <div>
                  <span className="font-medium">Email:</span> {clientData.email}
                </div>
                <div>
                  <span className="font-medium">Tel No:</span>{' '}
                  {clientData.client_details.phone_number}
                </div>
                <div className="break-all">
                  <span className="font-medium">Reason for call:</span>
                  {claimData.triage_options?.reason ?? ''}
                </div>
                <div className="break-all">
                  <span className="font-medium">Other Services accessed:</span>{' '}
                  {claimData.triage_options?.services ?? ''}
                </div>
                <div className="break-all">
                  <span className="font-medium">Outcomes:</span>{' '}
                  {claimData.triage_options?.outcomes ?? ''}
                </div>
              </div>
              <hr className="mt-4" />
              <div className="mt-4">
                <h2 className="text-lg font-bold">Can be referred to:</h2>
                {clientData.is_insured && referrableServices.length > 0 ? (
                  referrableServices.map((service, index) => {
                    if (referrableServices.length === index + 1) {
                      return <span>{service.title}</span>
                    }
                    return <span>{service.title}, </span>
                  })
                ) : (
                  <span>None</span>
                )}
              </div>
            </>
          )}
          <hr className="bg-gray-300 w-full my-4" />
        </div>
      )}
    </div>
  )
}

export default TriageDetails
