/* eslint-disable */
import { useState, useEffect, useRef } from 'react'
import useAxiosPrivate from '../../hooks/useAxiosPrivate'
import MainTitle from '../../components/MainTitle'
import { Link, useParams } from 'react-router-dom'
import useAuth from '../../hooks/useAuth'
import ClientNav from '../../components/ClientNav'
import { faBookmark, faPenToSquare, faPercent, faSterlingSign, faThumbsUp } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { PhoneNumberUtil } from 'google-libphonenumber'

const Client = () => {
  const { id } = useParams()
  const axios = useAxiosPrivate()
  const [editMode, setEditMode] = useState(false)
  const [editClientData, setEditClientData] = useState({})
  const [editClientDetails, setEditClientDetails] = useState({})
  const [phoneNumber, setPhoneNumber] = useState('')
  const [clientData, setClientData] = useState({
    name: '',
    reference: '123456',
  })
  const [isValid, setIsValid] = useState(false);
  const { setShowLoader } = useAuth();

  const handlePhoneNumberChange = (event) => {
    const { value } = event.target;
    setPhoneNumber(value);
    const isValidNumber = validatePhoneNumber(value);
    setIsValid(isValidNumber);
  }

  const validatePhoneNumber = (phoneNumber) => {
    const phoneNumberUtil = PhoneNumberUtil.getInstance()
    try {
      const parsedNumber = phoneNumberUtil.parse(phoneNumber, '+44')
      const isValidNumber = phoneNumberUtil.isValidNumber(parsedNumber)
      return isValidNumber
    } catch (e) {
      console.error('Error parsing phone number:', e)
      return false
    }
  }

  const handleInputChange = (fieldName, e) => {
    setEditClientData({ ...editClientData, [fieldName]: e.target.value })
  }

  const handleDetailsChange = (fieldName, e) => {
    setEditClientDetails({ ...editClientDetails, [fieldName]: e.target.value })
  }

  const handleEditClick = () => {
    setEditMode((prevState) => !prevState)
    setEditClientData({ ...clientData })
    if (clientData.academy_information) {
      setEditClientDetails({ ...JSON.parse(clientData.academy_information) });
    }

    if (editClientDetails.academy && editClientDetails.academy.general) {
      setPhoneNumber(editClientDetails.academy.general.telphone);
    } 
    setIsValid(true)
  }

  useEffect(() => {
    if (clientData.academy_information && clientData.academy_information.academy && clientData.academy_information.academy.general && clientData.academy_information.academy.general.telphone) {
      setPhoneNumber(editClientDetails.academy_information.academy.general.telphone);
    }
  }, [clientData.academy_information]);

  const handleSaveClick = async () => {
    let data = editClientData
    data.client_details = editClientDetails

    try {
      setShowLoader(true)
      const response = await axios.post(`/clients/${id}/edit-details`, data)
      setClientData(response.data.result)
      setEditMode(false)
    } catch (error) {
      console.error(error)
    }
    setShowLoader(false)
  }

  const getClientById = async () => {
    try {
      setShowLoader(true)
      const response = await axios.get(`/brokers/clients/${id}`)
      await setClientData(response.data.result)
      setShowLoader(false)
    } catch (error) {}
  }

  useEffect(() => {
    getClientById(id)
  }, [id])

  return (
    <div>
      <MainTitle
        title={`Client: ${clientData.academy_name} (${clientData.academy_urn})`}
        backButton
      />
      <ClientNav active="details" client={clientData} />

      {clientData.academy_id && (
        <section>
          <div>
            <div className='flex items-center justify-between space-x-6'>
                <div className="overflow-hidden rounded-2xl py-1 bg-moss-green shadow-md w-1/2">
                    <div className="p-6">
                      <div className="flex items-center justify-end">
                        <div className="flex-shrink-0">
                          <FontAwesomeIcon
                            icon={faBookmark}
                            className="h-10 w-10 text-moss-darker"
                          />
                        </div>
                        <div className="ml-5 w-0 flex-1">
                          <dl className='flex items-center justify-between'>
                            <dt className="truncate text-lg font-medium text-moss-darker">Reference: {clientData.academy_urn}</dt>
                            <div className='flex items-center justify-end'>
                              <div className="flex items-center justify-end text-sm font-medium text-white bg-moss-darker rounded-full py-1 px-4 text-center">
                                <FontAwesomeIcon
                                  icon={faThumbsUp}
                                  className="h-4 w-4"
                                />
                                <span className='pl-2 '>Live</span>
                              </div>
                            </div>
                          </dl>
                        </div>
                      </div>
                    </div>
                </div>
                <div className="overflow-hidden rounded-2xl bg-moss-green shadow-md w-1/2">
                  <div className="p-6">
                    <div className="flex items-center">
                      <div className="flex-shrink-0">
                        <FontAwesomeIcon
                          icon={faSterlingSign}
                          className="h-10 w-10 text-moss-darker"
                        />
                      </div>
                      <div className="ml-5 w-0 flex-1">
                        <dl>
                          <dt className="truncate text-lg font-medium text-moss-darker">Premium: £120,000</dt>
                          <dd>
                            <div className="text-sm font-medium text-slate-800 ">01/12/2020 - 12/12/2023</div>
                          </dd>
                        </dl>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="overflow-hidden rounded-2xl py-1 bg-moss-green shadow-md w-1/2">
                    <div className="p-6">
                      <div className="flex items-center justify-end">
                        <div className="flex-shrink-0">
                          <FontAwesomeIcon
                            icon={faPercent}
                            className="h-10 w-10 text-moss-darker"
                          />
                        </div>
                        <div className="ml-5 w-0 flex-1">
                          <dl className='flex items-center justify-between'>
                            <dt className="truncate text-lg font-medium text-moss-darker">GLR: 23%</dt>
                          </dl>
                        </div>
                      </div>
                    </div>
                </div>
              </div>
              <div className="bg-white rounded-2xl shadow mb-6 mt-10">
                <div className='flex text-white font-medium  justify-between space-x-2 bg-sovereign py-4 px-8 rounded-t-2xl'>
                    <div>Client Details</div>
                    <FontAwesomeIcon
                      title="Edit Client"
                      onClick={handleEditClick}
                      icon={faPenToSquare}
                      className={`text-xl hover:text-blue-500 cursor-pointer ${
                        editMode ? 'text-blue-500' : ''
                      }`}
                    />
                </div>
                <dl className="p-4 sm:p-6 lg:p-8 grid grid-cols-1 gap-x-4 gap-y-4 sm:grid-cols-2">
                    <div  className="sm:col-span-1">
                      <dt className="text-base font-semibold text-yellow-darker">Name: </dt>
                      <dd className="mt-1 text-sm text-gray-900 w-full">
                        {editMode ? (
                          <input
                            type="text"
                            className="border-2 border-slate-400 rounded-lg px-2 py-1 w-3/4"
                            defaultValue={editClientData.academy_name}
                            onChange={(e) => handleInputChange('name', e)}
                          />
                        ) : (
                          <span>{clientData.academy_name}</span>
                        )}
                      </dd>
                    </div>
                    <div  className="sm:col-span-1">
                      <dt className="text-base font-semibold text-yellow-darker">Main Contact Number: </dt>
                      <dd className="mt-1 text-sm text-gray-900 w-full">
                        {editMode ? (
                          <input
                            type="text"
                            className="border-2 border-slate-400 rounded-lg px-2 py-1 w-3/4"
                            value={phoneNumber}
                            onChange={(e) => {
                              handlePhoneNumberChange(e);
                              handleDetailsChange('phone_number', e);
                            }}
                          />
                        ) : (
                          <span>{JSON.parse(clientData.academy_information).academy.general.telphone}</span>
                        )}
                      </dd>
                    </div>
                    <div  className="sm:col-span-1">
                      <dt className="text-base font-semibold text-yellow-darker">Address: </dt>
                      <dd className="mt-1 text-sm text-gray-900 w-full">
                        {editMode ? (
                          <input
                            type="text"
                            className="border-2 border-slate-400 rounded-lg px-2 py-1 w-3/4"
                            defaultValue={
                              editClientDetails.academy?.address?.academy_fulladdress || ''
                            }
                            onChange={(e) => handleDetailsChange('address', e)}
                          />
                        ) : (
                          <span>{JSON.parse(clientData.academy_information).academy.address.academy_fulladdress}</span>

                        )}
                      </dd>
                    </div>
                </dl>
              </div>
          </div>
        </section>
      )}
    </div>
  )
}

export default Client
