import { createContext, useState } from 'react'

const AuthContext = createContext({})

export const AuthProvider = ({ children }) => {
  const [auth, setAuth] = useState({})
  const [showLoader, setShowLoader] = useState(false)
  const [showInfo, setShowInfo] = useState()
  const [showUpdate, setShowUpdate] = useState(false)
  const [notNow, setNotNow] = useState(false)
  const [showReportConfirmation, setShowReportConfirmation] = useState(false)

  const createInfo = (type, message, timeout = 6000) => {
    setShowInfo({ type, message, show: 'show' })
    setTimeout(() => {
      setShowInfo({})
    }, timeout)
  }

  const adminUsers = ['Super User', 'Admin']

  // const user = auth.user;
  const can = (permission) =>
    (auth.user?.permissions || []).find(
      (p) => p.toLowerCase() === permission.toLowerCase()
    )
      ? true
      : false
  // const admin = adminUsers.includes(auth?.user?.type)
  const admin = auth?.user ? true : false;
  // const [persist, setPersist] = useState(JSON.parse(localStorage.getItem('persist')) || true)

  return (
    <AuthContext.Provider
      value={{
        auth,
        setAuth,
        can,
        admin,
        showLoader,
        setShowLoader,
        showInfo,
        createInfo,
        showUpdate,
        setShowUpdate,
        showReportConfirmation,
        setShowReportConfirmation,
        notNow,
        setNotNow,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

export default AuthContext
