import React from 'react'
import { useState, useEffect, useRef } from 'react'
import { NavLink } from 'react-router-dom'
import useAxiosPrivate from '../hooks/useAxiosPrivate'
import useAuth from '../hooks/useAuth'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Pagination from '../components/Pagination'
import Modal from '../components/Modal'
import {
  faTrashCan,
  faMagnifyingGlass,
  faEye,
  faPlus,
  faSortDown,
  faSortUp,
  faSort
} from '@fortawesome/free-solid-svg-icons'
import { useParams } from 'react-router-dom'

function ClinicQuestionnaires({ questionnairesData }) {
  // Auth
  const { setShowLoader, can, createInfo, admin} = useAuth()
  const axios = useAxiosPrivate()

  //questionnaire
  const [addQuestionnaire, setAddQuestionnaire] = useState(false)
  const [newQuestionnaire, setNewQuestionnaire] = useState({})
  const [removeQuestionnaire, setRemoveQuestionnaire] = useState({})
  const [questionnaires, setQuestionnaires] = useState({})
  
  const {id} = useParams();
  //questionnaires
  const [currentRecords, setCurrentRecords] = useState([])

  // search
  const [search, setSearch] = useState('')
  const [filteredRecords, setFilteredRecords] = useState([])
  const [nPages, setNumberOfPages] = useState(0)

  //questionnaire
  const [selectedQuestionnaire, setSelectedQuestionnaire] = useState(0)

  const [showModal, setShowModal] = useState(false)
  const [modalTitle, setModalTitle] = useState('')

  const [currentPage, setCurrentPage] = useState(1)
  // No of Records to be displayed on each page
  const [recordsPerPage] = useState(10)

  const [indexOfLastRecord, setIndexOfLastRecord] = useState(10)
  const [indexOfFirstRecord, setIndexOfFirstRecord] = useState(0)

  const [originalRecords, setOriginalRecords] = useState([]);
  const [columnClickCounts, setColumnClickCounts] = useState({});
  const [sortConfig, setSortConfig] = useState({
    key: null,
    direction: 'ascending',
  });

  useEffect(() => {
    setQuestionnaires(questionnairesData)
    setFilteredRecords(questionnairesData)
    setCurrentRecords(questionnairesData)
    setNumberOfPages(Math.ceil(questionnairesData.length / recordsPerPage))
    getQuestionnaires()

  }, [])

  const removeQuestionnaireClicked = (item) => {
    setRemoveQuestionnaire({ ...item })
    setModalTitle(`Remove Questionnaire: ${item.title}`)
    setShowModal(true)
  }

  const handleSearch = (handle) => {
    setIndexOfFirstRecord(0)
    setIndexOfLastRecord(10)
    setCurrentPage(0)
    setCurrentRecords(filteredRecords.slice(0, 10))
    let searchValue = handle.target.value
    let searchString = searchValue.trim().toLowerCase()
    if (searchString.length > 0) {
      let records = questionnaires.filter((e) => {
        return e.title.toLowerCase().match(searchString)
      })
      setFilteredRecords(records)
      setCurrentRecords(records.slice(indexOfFirstRecord, indexOfLastRecord))
      setNumberOfPages(Math.ceil(records.length / recordsPerPage))
    } else {
      setFilteredRecords(questionnaires)
      setCurrentRecords(
        questionnaires.slice(indexOfFirstRecord, indexOfLastRecord)
      )
      setNumberOfPages(Math.ceil(questionnaires.length / recordsPerPage))
    }
  }

  const sortArray = (array, key, direction) => {
    return [...array].sort((a, b) => {
      if (a[key] < b[key]) return direction === 'ascending' ? -1 : 1;
      if (a[key] > b[key]) return direction === 'ascending' ? 1 : -1;
      return 0;
    });
  };

  const renderSortArrow = (columnKey) => {
    if (sortConfig.key === columnKey) {
      if (sortConfig.direction === 'ascending') {
        return <FontAwesomeIcon icon={faSortUp} />;
      } else {
        return <FontAwesomeIcon icon={faSortDown} />;
      }
    }
    return <FontAwesomeIcon icon={faSort} />;
  };

  const handleSort = (key) => {
    let newColumnClickCounts = { ...columnClickCounts };
    if (!newColumnClickCounts[key]) {
      newColumnClickCounts[key] = 1;
    } else {
      newColumnClickCounts[key]++;
    }
  
    if (newColumnClickCounts[key] === 3) {
      setSortConfig({
        key: null,
        direction: 'descending',
      });
      newColumnClickCounts[key] = 0;
      setCurrentRecords(originalRecords.slice(
        indexOfFirstRecord,
        indexOfLastRecord
      ));
    } else {
      let direction =
        sortConfig.direction === 'ascending'
          ? 'descending'
          : 'ascending';
      setSortConfig({ key, direction });
  
      const sortedRecords = sortArray(
        originalRecords,
        key,
        direction
      );
      setCurrentRecords(sortedRecords.slice(
        indexOfFirstRecord,
        indexOfLastRecord
      ));
    }
    setColumnClickCounts(newColumnClickCounts);
  };

  const nextPage = () => {
    if (currentPage < nPages) {
      const newPage = currentPage + 1;
      const indexFirst = newPage * recordsPerPage - recordsPerPage;
      const indexLast = newPage * recordsPerPage;
  
      setIndexOfFirstRecord(indexFirst);
      setIndexOfLastRecord(indexLast);
      setCurrentPage(newPage);

      const sortedRecords = sortArray(originalRecords, sortConfig.key, sortConfig.direction);
      setCurrentRecords(sortedRecords.slice(indexFirst, indexLast));
    }
  };
  
  const previousPage = () => {
    if (currentPage > 1) {
      const newPage = currentPage - 1;
      const indexFirst = (newPage - 1) * recordsPerPage;
      const indexLast = newPage * recordsPerPage;
  
      setIndexOfFirstRecord(indexFirst);
      setIndexOfLastRecord(indexLast);
      setCurrentPage(newPage);
  
      const sortedRecords = sortArray(originalRecords, sortConfig.key, sortConfig.direction);
      setCurrentRecords(sortedRecords.slice(indexFirst, indexLast));
    }
  };

  const ModalBody = () => {
    if (addQuestionnaire) {
      return <AddQuestionnaireElement />
    }
    if(removeQuestionnaire.id)
    {
      return <RemoveQuestionnaireElement />
    }
  }

  const AddQuestionnaireElement = () => {
    const addNewQuestionnaire = async () => {
      setShowLoader(true)
      const data = {
        company_id: id,
        questionnaire_id: JSON.parse(selectedQuestionnaire).id
      }
      const response = await axios.post(`/companies/questionnaires/add`, data)
        setCurrentRecords(response.data.result)
        resetModal()
        setShowLoader(false)
        createInfo('success','Questionnaire Added')

      
    }

    return (
      <div>
        <div className="mb-3">
          <label
            htmlFor="name"
            className="block font-medium text-gray-600 text-sm"
          >
            Select a Questionnaire
          </label>
          <select
            defaultValue={selectedQuestionnaire ?? 0}
            onChange={(e) => setSelectedQuestionnaire(e.target.value)}
            className="px-2 py-2 block w-full border border-gray-300 rounded-md shadow-md"
          >
            <option value={0} disabled>
              Select a Questionnaire
            </option>
            {questionnaires?.map((item, index) => {
              if(!currentRecords.some((currentItem) => {
                return currentItem.title === item.title
              }))
              {return (
                <option key={item.id} value={JSON.stringify(item)}>
                  {item.title}
                </option>
              )}
              return null
            })}
          </select>
        </div>

        <div className="flex mt-2 w-full">
          <button
            className="btn bg-green-500 mt-4 mr-4 w-1/2"
            onClick={addNewQuestionnaire}
          >
            Add Questionnaire
          </button>

          <button
            type="button"
            className="btn red mt-4 w-1/2"
            onClick={resetModal}
          >
            Cancel
          </button>
        </div>
      </div>
    )
  }

  const RemoveQuestionnaireElement = () => {
    const removeQuestionnaireFn = async (e) => {
      e.preventDefault()
      setShowLoader(true)
      resetModal(false)
      const data = {
        company_id: id,
        questionnaire_id: removeQuestionnaire.id
      }
      try {
        const response = await axios.post(
          '/companies/questionnaires/remove', data
        )
        createInfo(
          'error',
          `Removed: ${removeQuestionnaire.title}`
        )
        setCurrentRecords(response.data.result)
        setShowLoader(false)
      } catch (error) {
        setShowLoader(false)
      }
    }

    return (
      <form onSubmit={removeQuestionnaireFn}>
        <div className="mb-3">
          <p className="text-lg font-bold my-8 text-center">
            Are you sure you want to remove this Questionnaire?
          </p>
          <div className="flex mt-2 w-full">
            <button className="btn red mt-4 mr-4 w-1/2">Remove</button>

            <button
              type="button"
              className="btn mt-4 w-1/2"
              onClick={resetModal}
            >
              Cancel
            </button>
          </div>
        </div>
      </form>
    )
  }

  const resetModal = () => {
    setAddQuestionnaire(false)
    setShowModal(false)
    setModalTitle('')
  }
  

  const getQuestionnaires = async () => {
    try {
      const response = await axios.get(`/questionnaires/all`)
      if (response.data.result) {
        await setQuestionnaires(response.data.result)
        await setOriginalRecords(response.data.result);
        await setNumberOfPages(
          Math.ceil(response.data.result.length / recordsPerPage)
        );
      }
    } catch (error) {}
  }

  const addQuestionnaireClicked = async () => {
    setAddQuestionnaire(true)
    setModalTitle(`Add a Questionnaire`)
    setShowModal(true)
  }

  return (
    <section className="w-full">
      {showModal && (
        <Modal title={modalTitle} body={<ModalBody />} show={resetModal} />
      )}

      {/* {can("view clinic questionnaires") && ( */}
      <div>
        <div className="pb-4 flex justify-between">
          <div className="relative mt-1 justify-start">
            <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
              <FontAwesomeIcon
                icon={faMagnifyingGlass}
                onClick={handleSearch}
              />
            </div>

            <input
              type="text"
              id="table-search"
              onChange={handleSearch}
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-80 pl-10 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="Search Questionnaires"
            />
          </div>
          <div className='reltive mt-1 justify-end'>
              <div className="cursor-pointer ml-4 bg-green-500 font-bold items-center btn">
                    <NavLink to="questionnaire/new">
                    <span>Create Questionnaire</span>
                  </NavLink>
              </div>
              <div onClick={() => {addQuestionnaireClicked()}}className="cursor-pointer ml-4 bg-green-500 font-bold items-center btn">
                    <span>Add Questionnaire</span>
              </div>
                   
          </div>
        </div>
      </div>
      <table className="table-main">
        <thead>
          <tr>
            <th className='cursor-pointer' scope="col" onClick={() => handleSort('id')}>ID {renderSortArrow('id')}</th>
            <th className='cursor-pointer' scope="col" onClick={() => handleSort('title')}>Title {renderSortArrow('title')}</th>
            <th scope="col">Actions</th>
            {/* {can("remove clinic questionnaires") && <th scope="col">Actions</th>} */}
          </tr>
        </thead>
        <tbody>
          {currentRecords.length > 0 ? (
            currentRecords?.map((item) => {
              return (
                <tr key={item.id}>
                  <td>{item.id}</td>
                  <td>{item.title}</td>
                  <td>
                    <div className="flex justify-center">
                      {admin && (
                        <span
                          title="Remove Questionnaire"
                          onClick={() => removeQuestionnaireClicked(item)}
                          className="flex justify-center items-center bg-red-400 rounded-md text-red-800 h-9 w-12 mx-2  hover:bg-red-500 cursor-pointer"
                        >
                          <FontAwesomeIcon icon={faTrashCan} />
                        </span>
                      )}
                    </div>
                  </td>
                </tr>
              )
            })
          ) : (
            <tr>
              <td colSpan={6}>
                <p className="no-records">No questionnaires found</p>
              </td>
            </tr>
          )}
        </tbody>
      </table>
      <Pagination
        next={nextPage}
        prev={previousPage}
        first={indexOfFirstRecord}
        last={indexOfLastRecord}
        total={filteredRecords.length}
      />
      {/* </div>
      )} */}
    </section>
  )
}

export default ClinicQuestionnaires
