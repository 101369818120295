import fileDefault from '../img/file-blank-solid-240.png'
import filePdf from '../img/file-pdf-solid-240.png'
import filePng from '../img/file-png-solid-240.png'
import fileJpg from '../img/file-jpg-solid-240.png'
import fileCsv from '../img/file-csv-solid-240.png'

export const ImageConfig = {
  default: fileDefault,
  pdf: filePdf,
  png: filePng,
  jpg: fileJpg,
  csv: fileCsv,
}
