import { Outlet } from 'react-router-dom'
import { useState } from 'react'
import Header from '../components/Header'
import LeftBar from '../components/LeftBar'
import Loader from '../components/Loader'
import useAuth from '../hooks/useAuth'
import InfoMessage from '../components/InfoMessage'
import AppUpdate from '../components/AppUpdate'

function MainLayout() {
  const { showLoader, showInfo, showUpdate } = useAuth()
  const [collapsed, setCollapsed] = useState(false)

  const toggleSidebar = () => {
    setCollapsed(!collapsed)
  }

  const mainStyle = {
    marginLeft: collapsed ? 0 : '20rem',
    margin: collapsed ? '2rem' : 0,
  }

  return (
    <div className="relative flex min-h-screen bg-slate-100">
      <LeftBar collapsed={collapsed} toggleSidebar={toggleSidebar} />
      <main
        className={
          collapsed ? 'mx-8 p-4 md:pl-0 w-full' : 'lg:ml-80 p-4 md:pl-0 w-full '
        }
      >
        <Header collapsed={collapsed} />
        <div className="mt-20">
          <Outlet context={[collapsed, setCollapsed]} />
        </div>
      </main>
      {collapsed && (
        <div className="absolute bottom-0 mb-4 ml-4">
          <button
            onClick={toggleSidebar}
            className="text-white p-2 rounded-md bg-slate-700 hover:bg-slate-600"
          >
            Open Sidebar
          </button>
        </div>
      )}
      {showLoader && <Loader />}
      {showInfo?.message && <InfoMessage />}
      {showUpdate && <AppUpdate />}
    </div>
  )
}

export default MainLayout
