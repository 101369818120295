import React from "react"
import moment from "moment"

function NotificationItem({ notification, deleteNotification, admin = false }) {
  return (
    <article className={`article-item ${notification.type.toLowerCase()}`}>
      <h3 className="text-lg font-semibold leading-5">
        {notification.type}: {notification.title}
      </h3>
      <hr className="mt-2" />
      <p className="text-md my-2">{notification.body}</p>
      <hr className="pb-2 block" />
      <div className="flex justify-between">
        <ul className="mt-2 flex text-sm font-normal leading-4 text-gray-500 items-center">
          <li>{moment(notification.created_at).format("DD/MM/YYYY HH:mm")}</li>
        </ul>
        {admin && (
          <ul className="mt-4 flex font-normal leading-4 text-gray-500 items-center h-full">
            <li
              className="hover:text-red-500 cursor-pointer"
              onClick={() => deleteNotification(notification)}
            >
              <i className="fa-solid fa-trash mr-1"></i>Delete
            </li>
          </ul>
        )}
      </div>
    </article>
  )
}

export default NotificationItem
