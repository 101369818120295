/* eslint-disable */
import MainTitle from '../../components/MainTitle'
import { NavLink } from 'react-router-dom'
import CardButton from '../../components/CardButton'
import useAuth from '../../hooks/useAuth'
import moment from 'moment'
import { useState, useEffect } from 'react'
import useAxiosPrivate from '../../hooks/useAxiosPrivate'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Pagination from '../../components/Pagination'
import {
  faMagnifyingGlass,
  faEye,
  faPlus,
  faSortDown,
  faSortUp,
  faSort
} from '@fortawesome/free-solid-svg-icons'
import Modal from '../../components/Modal'
import { Link } from 'react-router-dom'

function Clients() {
  const axios = useAxiosPrivate()
  const { setShowLoader, can, createInfo, auth } = useAuth()

  const [clients, setClients] = useState([])
  const [deleteClient, setDeleteClient] = useState({})
  const [showModal, setShowModal] = useState(false)

  const [search, setSearch] = useState('')

  const [currentRecords, setCurrentRecords] = useState([])
  const [filteredRecords, setFilteredRecords] = useState([])
  const [nPages, setNumberOfPages] = useState(0)

  const [currentPage, setCurrentPage] = useState(1)
  // No of Records to be displayed on each page
  const [recordsPerPage] = useState(10)

  const [indexOfLastRecord, setIndexOfLastRecord] = useState(10)
  const [indexOfFirstRecord, setIndexOfFirstRecord] = useState(0)
  const [modalTitle, setModalTitle] = useState('')
  const [addClient, setAddClient] = useState(false)

  const [originalRecords, setOriginalRecords] = useState([]);
  const [columnClickCounts, setColumnClickCounts] = useState({});
  const [sortConfig, setSortConfig] = useState({
    key: null,
    direction: 'ascending',
  });

  useEffect(() => {
    init()
  }, [])

  const init = async () => {
    setShowLoader(true)
    getClients()
  }

  const handleSearch = (handle) => {
    setIndexOfFirstRecord(0)
    setIndexOfLastRecord(10)
    setCurrentPage(0)
    setCurrentRecords(filteredRecords.slice(0, 10))

    let searchValue = handle.target.value
    setSearch(searchValue)
    let searchString = searchValue.trim().toLowerCase()
    if (searchString.length > 0) {
      let records = clients.filter((e) => {
        return e.name.toLowerCase().match(searchString)
      })
      setFilteredRecords(records)
      setCurrentRecords(records.slice(indexOfFirstRecord, indexOfLastRecord))
      setNumberOfPages(Math.ceil(records.length / recordsPerPage))
    } else {
      setFilteredRecords(clients)
      setCurrentRecords(clients.slice(indexOfFirstRecord, indexOfLastRecord))
      setNumberOfPages(Math.ceil(clients.length / recordsPerPage))
    }
  }

  const sortArray = (array, key, direction) => {
    return [...array].sort((a, b) => {
      if (a[key] < b[key]) return direction === 'ascending' ? -1 : 1;
      if (a[key] > b[key]) return direction === 'ascending' ? 1 : -1;
      return 0;
    });
  };

  const renderSortArrow = (columnKey) => {
    if (sortConfig.key === columnKey) {
      if (sortConfig.direction === 'ascending') {
        return <FontAwesomeIcon icon={faSortUp} />;
      } else {
        return <FontAwesomeIcon icon={faSortDown} />;
      }
    }
    return <FontAwesomeIcon icon={faSort} />;
  };

  const handleSort = (key) => {
    let newColumnClickCounts = { ...columnClickCounts };
    if (!newColumnClickCounts[key]) {
      newColumnClickCounts[key] = 1;
    } else {
      newColumnClickCounts[key]++;
    }
  
    if (newColumnClickCounts[key] === 3) {
      setSortConfig({
        key: null,
        direction: 'descending',
      });
      newColumnClickCounts[key] = 0;
      setCurrentRecords(originalRecords.slice(
        indexOfFirstRecord,
        indexOfLastRecord
      ));
    } else {
      let direction =
        sortConfig.direction === 'ascending'
          ? 'descending'
          : 'ascending';
      setSortConfig({ key, direction });
  
      const sortedRecords = sortArray(
        originalRecords,
        key,
        direction
      );
      setCurrentRecords(sortedRecords.slice(
        indexOfFirstRecord,
        indexOfLastRecord
      ));
    }
    setColumnClickCounts(newColumnClickCounts);
  };

  const nextPage = () => {
    if (currentPage < nPages) {
      const newPage = currentPage + 1;
      const indexFirst = newPage * recordsPerPage - recordsPerPage;
      const indexLast = newPage * recordsPerPage;
  
      setIndexOfFirstRecord(indexFirst);
      setIndexOfLastRecord(indexLast);
      setCurrentPage(newPage);

      const sortedRecords = sortArray(originalRecords, sortConfig.key, sortConfig.direction);
      setCurrentRecords(sortedRecords.slice(indexFirst, indexLast));
    }
  };
  
  const previousPage = () => {
    if (currentPage > 1) {
      const newPage = currentPage - 1;
      const indexFirst = (newPage - 1) * recordsPerPage;
      const indexLast = newPage * recordsPerPage;
  
      setIndexOfFirstRecord(indexFirst);
      setIndexOfLastRecord(indexLast);
      setCurrentPage(newPage);
  
      const sortedRecords = sortArray(originalRecords, sortConfig.key, sortConfig.direction);
      setCurrentRecords(sortedRecords.slice(indexFirst, indexLast));
    }
  };

  const getClients = async () => {
    try {
      const response = await axios.get(`/brokers/clients`)
      setClients(response.data.result)
      setFilteredRecords(response.data.result)
      setCurrentRecords(
        response.data.result.slice(indexOfFirstRecord, indexOfLastRecord)
      )
      setNumberOfPages(Math.ceil(response.data.result.length / recordsPerPage))
    } catch (error) {}
    setShowLoader(false)
  }

  const addClientClicked = () => {
    setAddClient(true)
    setModalTitle(`Add Client`)
    setShowModal(true)
  }

  const ModalBody = () => {
    if(addClient) {
      return <AddClientElement />
    }
    
  }

  const AddClientElement = () => {
    return (
      <form >
         <div className="mb-3">
          <label
            htmlFor="reference"
            className="block font-medium text-gray-600 text-sm"
          >
            Name
          </label>
          <div className="mt-1 relative rounded-md">
            <input
              required
              type="text"
              name="reference"
              id="reference"
              className="px-4 py-2 block w-full border border-gray-300 rounded-md shadow-md"
            />
          </div>
        </div>
        <div className="mb-3">
          <label
            htmlFor="reference"
            className="block font-medium text-gray-600 text-sm"
          >
            Email
          </label>
          <div className="mt-1 relative rounded-md">
            <input
              required
              type="text"
              name="reference"
              id="reference"
              className="px-4 py-2 block w-full border border-gray-300 rounded-md shadow-md"
            />
          </div>
        </div>
        <div className="mb-3">
          <label
            htmlFor="name"
            className="block font-medium text-gray-600 text-sm"
          >
            Policy Type
          </label>

          <select
            className="px-2 py-2 block w-full border border-gray-300 rounded-md shadow-md"
            required
          >
            <option>Select option</option>
            <option>Basic</option>
            <option>Multi</option>
            <option>Premium</option>
          </select>
        </div>
        <div className="mb-3">
          <label
            htmlFor="reference"
            className="block font-medium text-gray-600 text-sm"
          >
            External Reference
          </label>
          <div className="mt-1 relative rounded-md">
            <input
              required
              type="text"
              name="reference"
              id="reference"
              className="px-4 py-2 block w-full border border-gray-300 rounded-md shadow-md"
            />
          </div>
        </div>

        <div className="flex mt-2 w-full space-x-4">
        <button
            type="button"
            className="border-2 border-backdrop-600 text-gray-800 bg-backdrop hover:bg-white hover:text-backdrop-600 font-medium rounded-lg p-2 mt-4 w-1/2"
            onClick={resetModal}
          >
            Cancel
          </button>
          <button className="border-2 border-moss-darker text-white bg-moss-darker hover:bg-transparent hover:text-moss-darker font-medium rounded-lg p-2 mt-4 mr-4 w-1/2">Add Client</button>
        </div>
      </form>
    )
  }

  const resetModal = (resetClients = true) => {
    setDeleteClient({})
    setAddClient(false)
    setShowModal(false)
    if (resetClients) {
      setClients(clients)
    }
  }

  return (
    <section>
      <MainTitle title="My Clients" />

      <section>
        {showModal && (
          <Modal title={modalTitle} body={<ModalBody />} show={resetModal} />
        )}
        <ul className="flex just-between flex-wrap">
          {can('create clients') && (
            <li className="w-1/4 mb-6 mr-3">
              <NavLink to="new">
                <CardButton icon={faPlus} title="Create New Client" />
              </NavLink>
            </li>
          )}
          <li className="rounded-md bg-white shadow h-20 w-1/4 px-4 mb-6 flex flex-col justify-center mr-3">
            <h2 className="font-bold">Total Clients</h2>
            <p className="text-3xl font-black">{clients.length}</p>
          </li>
        </ul>
      </section>

      <div className='flex justify-end'>
        <button
          onClick={addClientClicked}
          className='bg-moss-green rounded-lg px-4 py-2 shadow font-semibold text-moss-darker'>Create New Client</button>
      </div>

      <div className="py-4">
        <div className="relative mt-1">
          <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
            <FontAwesomeIcon icon={faMagnifyingGlass} onClick={handleSearch} />
          </div>
          <input
            type="text"
            id="table-search"
            onChange={handleSearch}
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-80 pl-10 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Search Clients"
          />
        </div>
      </div>

      <table className="table-main">
        <thead>
          <tr>
            <th className='cursor-pointer' scope="col" onClick={() => handleSort('name')}>Name {renderSortArrow('name')}</th>
            {/* <th className='cursor-pointer' scope="col" onClick={() => handleSort('email')}>Email {renderSortArrow('email')}</th> */}
            <th className='cursor-pointer' scope="col" onClick={() => handleSort('reference')}>Company Number {renderSortArrow('reference')}</th>
            <th className='cursor-pointer' scope="col" onClick={() => handleSort('status')}>Status {renderSortArrow('status')}</th>
            <th className='cursor-pointer' scope="col" onClick={() => handleSort('created_at')}>Created At {renderSortArrow('created_at')}</th>
            <th scope="col">Actions</th>
          </tr>
        </thead>
        <tbody className="table-main">
          {currentRecords.length > 0 ? (
            currentRecords.map((client) => {
              return (
                <tr key={client.academy_id}>
                  <td>{client.academy_name}</td>
                  {/* <td>{client.email}</td> */}
                  <td>{client.academy_urn}</td>
                  <td>
                    <div
                      className={`status ${client.insurance_history
                        .replace(/\s+/g, '-')
                        .toLowerCase()}`}
                    >
                      {client.status}
                    </div>
                  </td>
                  <td>{moment(client.created_at).format('DD/MM/YYYY')}</td>
                  <td>
                    <div className="flex justify-center">
                      <Link to={`/clients/${client.academy_id}`}>
                        <span className="flex justify-center items-center bg-blue-400 rounded-md text-blue-800 h-9 w-12 mx-2  hover:bg-blue-500 cursor-pointer">
                          <FontAwesomeIcon icon={faEye} />
                        </span>
                      </Link>
                    </div>
                  </td>
                </tr>
              )
            })
          ) : (
            <tr>
              <td colSpan={7}>
                <p className="no-records">No clients found</p>
              </td>
            </tr>
          )}
        </tbody>
      </table>
      <Pagination
        next={nextPage}
        prev={previousPage}
        first={indexOfFirstRecord}
        last={indexOfLastRecord}
        total={filteredRecords.length}
        resultsPerPage={recordsPerPage}
      />
    </section>
  )
}

export default Clients
