/* eslint-disable */
import { useState } from 'react'
import Modal from '../components/Modal'

function ClinicProfile({ clinic }) {
  const [showModal, setShowModal] = useState(false)
  const [modalTitle, setModalTitle] = useState('')

  return (
    <section>
      {showModal && (
        <Modal title={modalTitle} body={<ModalBody />} show={resetModal} />
      )}
      <section style={{ paddingBottom: 25 }}>
        <div className="relative mt-28">
          <div className="bg-blue-700/30 absolute rounded-2xl top-0 left-0"></div>
        </div>
        <div className="flex">
          <div className="rounded-full overflow-hidden relative w-48 border-4 border-white h-48 block shadow ml-10 -mt-20 z-10 bg-white">
            <span
              className="profile-pic"
              style={{
                backgroundImage: `url(${clinic.logo})`,
                PointerEvent: 'none',
              }}
            ></span>
          </div>
          <div className="block ml-8 mt-5 pr-6">
            <div>
              <h3 className="text-blue-500 font-bold text-lg">
                Main Clinician:
              </h3>
              <h4 className="text-gray-800 font-bold">{clinic.name}</h4>
            </div>
          </div>
          <div className="block pl-6 mt-5 border-gray-200 border-l pr-6">
            <div>
              <h3 className="text-blue-500 font-bold text-lg">Email:</h3>
              <h4 className="text-gray-800 font-bold">
                {clinic.main_contact_details &&
                  JSON.parse(clinic.main_contact_details).email}
              </h4>
            </div>
          </div>
          <div className="block pl-6 mt-5 border-gray-200 border-l pr-6">
            <div>
              <h3 className="text-blue-500 font-bold text-lg">
                Contact Number:
              </h3>
              <h4 className="text-gray-800 font-bold">
                {clinic.main_contact_details &&
                  JSON.parse(clinic.main_contact_details).phone_number}
              </h4>
            </div>
          </div>
        </div>
      </section>
    </section>
  )
}

export default ClinicProfile
