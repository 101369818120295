import { faChevronLeft } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { useNavigate } from 'react-router-dom'

function MainTitle({ title, backButton = false,}) {
  const navigate = useNavigate()

  const goBack = () => {
    navigate(-1)
  }

  return (
    <div className="font-bold py-2 mt-2 text-xl w-full border-b border-b-gray-300 mb-4 ml-6 lg:ml-0">
      <h1 className='flex items-center space-x-2'>
        <span>{title}</span> 
      </h1>

      {backButton && (
        <span
          className="inline-flex items-center text-slate-500 mt-1 cursor-pointer hover:text-slate-600"
          onClick={goBack}
        >
          <FontAwesomeIcon icon={faChevronLeft} className="text-sm" />
          <span className="text-sm ml-2">Go Back</span>
        </span>
      )}
    </div>
  )
}

export default MainTitle
