import { Routes, Route } from 'react-router-dom'

import Login from './pages/Login'
import TwoFa from './pages/TwoFactor'
// import Register from './pages/Register'
import ForgotPassword from './pages/ForgotPassword'
import ResetPassword from './pages/ResetPassword'

import Missing from './pages/Missing'

// Admin pages
import AdminCreateQuote from './pages/Admin/CreateQuote'
import Client from './pages/Clients/Client'
import ClientClaims from './pages/Clients/Claims'
import ClientQuotes from './pages/Clients/Quotes'
import ClientMessages from './pages/Clients/Messages'
import ClientNotes from './pages/Clients/Notes'
import ClientClaim from './pages/Clients/Claim'
import ClientSession from './pages/Clients/Session'
import AdminUser from './pages/Admin/User'
import Team from './components/Team'
import ViewQuote from './pages/Clients/ViewQuote'

// Clinic pages
import ClinicDashboard from './pages/Clinic/Dashboard'
import Reports from './components/Reports'
import MyClinic from './pages/Clinic/MyClinic'
import Clients from './pages/Clinic/Clients'

import Notifications from './pages/Notifications'

import Layout from './components/Layout'

import MainLayout from './layouts/MainLayout'
import AuthLayout from './layouts/AuthLayout'

import RequireAuth from './components/RequireAuth'
import PersistLogin from './components/PersistLogin'
import CheckLogin from './components/CheckLogin'

import useAuth from './hooks/useAuth'
import SharedDocuments from './pages/Clients/SharedDocuments'
import Policies from './pages/Clients/Policies'

function App() {
  const { admin } = useAuth()

  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        {/* Public Routes */}

        {/* Protected Routes */}
        <Route element={<PersistLogin />}>
          <Route element={<RequireAuth />}>
            <Route element={<MainLayout />}>
                <Route>
                  <Route path="/" element={<ClinicDashboard />} />
                  <Route path="/clinic" element={<MyClinic />} />
                  <Route path="/clients" element={<Clients />} />
                  <Route path="/clients/:id" element={<Client />} />
                  <Route
                    path="/clients/:clientId/claims/:claimId"
                    element={<ClientClaim />}
                  />
                  <Route path="/clients/:id/quotes/new" element={<AdminCreateQuote />} />
                  <Route path="/clients/:id/quotes/:quoteId" element={<ViewQuote />} />
                  <Route
                    path="/clients/:clientId/claims/:claimId/sessions/:sessionId"
                    element={<ClientSession />}
                  />
                  <Route
                    path="/clients/:id/claims"
                    element={<ClientClaims />}
                  />
                  <Route
                    path="/clients/:id/policies"
                    element={<Policies />}
                  />
                   <Route
                    path="/clients/:id/quotes"
                    element={<ClientQuotes />}
                  />
                  <Route
                    path="/clients/:id/messages"
                    element={<ClientMessages />}
                  />
                  <Route path="/clients/:id/notes" element={<ClientNotes />} />

                  <Route
                    path="/clients/:id/shared-documents"
                    element={<SharedDocuments />}
                  />

                  <Route path="/reports" element={<Reports />} />
                  <Route path="/teams" element={<Team />} />
                  <Route path="/users/:id" element={<AdminUser />} />
                </Route>
              {/* )} */}
              <Route path="/notifications" element={<Notifications />} />
            </Route>

            {/* Page without using main layout */}
            {/* <Route path='/appointments/new' element={<NewAppointment />} /> */}
            <Route path="*" element={<Missing />} />
          </Route>

          <Route element={<CheckLogin />}>
            <Route element={<AuthLayout />}>
              <Route path="login" element={<Login />} />
              <Route path="2fa" element={<TwoFa />} />
              {/* <Route path='register' element={<Register />} /> */}
              <Route path="forgot-password" element={<ForgotPassword />} />
              <Route path="reset-password" element={<ResetPassword />} />
            </Route>
          </Route>

          {/* Catch All */}
          <Route path="*" element={<Missing />} />
        </Route>
      </Route>
    </Routes>
  )
}

export default App
