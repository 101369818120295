import React from 'react'
import { useState, useEffect, useRef } from 'react'
import useAxiosPrivate from '../hooks/useAxiosPrivate'
import useAuth from '../hooks/useAuth'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Pagination from '../components/Pagination'
import Modal from '../components/Modal'
import {
  faTrashCan,
  faMagnifyingGlass,
  faSliders,
  faPoundSign,
  faSortDown,
  faSortUp,
  faSort
} from '@fortawesome/free-solid-svg-icons'

function ClinicServices({ clinicId }) {
  // Auth
  const { setShowLoader, can, createInfo } = useAuth()
  const axios = useAxiosPrivate()

  // Service
  const [addService, setAddService] = useState(false)
  const [services, setServices] = useState([])
  const [removeService, setRemoveService] = useState(false)
  const [newService, setNewService] = useState({ parent_id: {} })
  const [deleteService, setDeleteService] = useState({})
  const [currentRecords, setCurrentRecords] = useState([])

  const [toggleService, setToggleService] = useState(false)
  const [toggledService, setToggledService] = useState({})

  const errRef = useRef()

  // search
  const [filteredRecords, setFilteredRecords] = useState([])
  const [nPages, setNumberOfPages] = useState(0)

  const [showModal, setShowModal] = useState(false)
  const [modalTitle, setModalTitle] = useState('')

  const [currentPage, setCurrentPage] = useState(1)
  // No of Records to be displayed on each page
  const [recordsPerPage] = useState(10)

  const [indexOfLastRecord, setIndexOfLastRecord] = useState(10)
  const [indexOfFirstRecord, setIndexOfFirstRecord] = useState(0)
  const [search, setSearch] = useState('')

  const [originalRecords, setOriginalRecords] = useState([]);
  const [columnClickCounts, setColumnClickCounts] = useState({});
  const [sortConfig, setSortConfig] = useState({
    key: null,
    direction: 'ascending',
  });

  useEffect(() => {
    if (clinicId) {
      getServices()
    }
  }, [clinicId])

  const handleSearch = (handle) => {
    setIndexOfFirstRecord(0)
    setIndexOfLastRecord(10)
    setCurrentPage(0)
    setCurrentRecords(filteredRecords.slice(0, 10))

    let searchValue = handle.target.value
    setSearch(searchValue)
    let searchString = searchValue.trim().toLowerCase()
    if (searchString.length > 0) {
      let records = services.filter((e) => {
        return e.title.toLowerCase().match(searchString)
      })
      setFilteredRecords(records)
      setCurrentRecords(records.slice(indexOfFirstRecord, indexOfLastRecord))
      setNumberOfPages(Math.ceil(records.length / recordsPerPage))
    } else {
      setFilteredRecords(services)
      setCurrentRecords(services.slice(indexOfFirstRecord, indexOfLastRecord))
      setNumberOfPages(Math.ceil(services.length / recordsPerPage))
    }
  }

  const sortArray = (array, key, direction) => {
    return [...array].sort((a, b) => {
      if (a[key] < b[key]) return direction === 'ascending' ? -1 : 1;
      if (a[key] > b[key]) return direction === 'ascending' ? 1 : -1;
      return 0;
    });
  };

  const renderSortArrow = (columnKey) => {
    if (sortConfig.key === columnKey) {
      if (sortConfig.direction === 'ascending') {
        return <FontAwesomeIcon icon={faSortUp} />;
      } else {
        return <FontAwesomeIcon icon={faSortDown} />;
      }
    }
    return <FontAwesomeIcon icon={faSort} />;
  };

  const handleSort = (key) => {
    let newColumnClickCounts = { ...columnClickCounts };
    if (!newColumnClickCounts[key]) {
      newColumnClickCounts[key] = 1;
    } else {
      newColumnClickCounts[key]++;
    }
  
    if (newColumnClickCounts[key] === 3) {
      setSortConfig({
        key: null,
        direction: 'descending',
      });
      newColumnClickCounts[key] = 0;
      setCurrentRecords(originalRecords.slice(
        indexOfFirstRecord,
        indexOfLastRecord
      ));
    } else {
      let direction =
        sortConfig.direction === 'ascending'
          ? 'descending'
          : 'ascending';
      setSortConfig({ key, direction });
  
      const sortedRecords = sortArray(
        originalRecords,
        key,
        direction
      );
      setCurrentRecords(sortedRecords.slice(
        indexOfFirstRecord,
        indexOfLastRecord
      ));
    }
    setColumnClickCounts(newColumnClickCounts);
  };

  const nextPage = () => {
    if (currentPage < nPages) {
      const newPage = currentPage + 1;
      const indexFirst = newPage * recordsPerPage - recordsPerPage;
      const indexLast = newPage * recordsPerPage;
  
      setIndexOfFirstRecord(indexFirst);
      setIndexOfLastRecord(indexLast);
      setCurrentPage(newPage);

      const sortedRecords = sortArray(originalRecords, sortConfig.key, sortConfig.direction);
      setCurrentRecords(sortedRecords.slice(indexFirst, indexLast));
    }
  };
  
  const previousPage = () => {
    if (currentPage > 1) {
      const newPage = currentPage - 1;
      const indexFirst = (newPage - 1) * recordsPerPage;
      const indexLast = newPage * recordsPerPage;
  
      setIndexOfFirstRecord(indexFirst);
      setIndexOfLastRecord(indexLast);
      setCurrentPage(newPage);
  
      const sortedRecords = sortArray(originalRecords, sortConfig.key, sortConfig.direction);
      setCurrentRecords(sortedRecords.slice(indexFirst, indexLast));
    }
  };

  const getServices = async () => {
    try {
      const response = await axios.get(`/companies/${clinicId}/services/all`)
      if (response.data.result) {
        await setServices(response.data.result)
        await setFilteredRecords(response.data.result)
        await setOriginalRecords(response.data.result);
        await setCurrentRecords(response.data.result.slice(
          indexOfFirstRecord,
          indexOfLastRecord
        ))
        await setNumberOfPages(
          Math.ceil(response.data.result.length / recordsPerPage)
        );
      }
    } catch (error) {}
  }

  const ModalBody = () => {
    if (addService) {
      return <AddServiceElement />
    }

    if (removeService) {
      return <RemoveServiceElement />
    }

    if (toggleService) {
      return <ToggleServiceElement />
    }
  }

  const AddServiceElement = () => {
    const addService = async (e) => {
      e.preventDefault()
      setShowLoader(true)
      try {
        const response = await axios.post('/services/add', {
          companyId: clinicId,
          title: newService.title,
          description: newService.description,
          assessment: newService.assessment,
          parent_id: newService.parent_id,
          length: newService.length,
          cost: newService.cost,
          insurer_cost: newService.insurer_cost,
        })
        if (response.data.error === false) {
          createInfo('success', `Created Service: ${newService.title}`)
          setCurrentRecords([...currentRecords, response.data.result])
          setServices([...services, response.data.result])
          setAddService(false)
          setNewService({ parent_id: {} })
          resetModal()
        }
      } catch (error) {
        createInfo('error', `Failed to create service`)
      }
      setShowLoader(false)
    }
    return (
      <form onSubmit={addService}>
        <div className="mb-3">
          <label
            htmlFor="name"
            className="block font-medium text-gray-600 text-sm"
          >
            Name
          </label>
          <div className="mt-1 relative rounded-md">
            <input
              defaultValue={newService.title}
              required
              type="text"
              name="title"
              id="title"
              onChange={(e) => (newService.title = e.target.value)}
              className="px-4 py-2 block w-full border border-gray-300 rounded-md shadow-md"
            />
          </div>
        </div>
        <div className="mb-3">
          <label
            htmlFor="name"
            className="block font-medium text-gray-600 text-sm"
          >
            Description
          </label>
          <div className="mt-1 relative rounded-md">
            <input
              defaultValue={newService.description}
              required
              type="text"
              name="description"
              id="description"
              onChange={(e) => (newService.description = e.target.value)}
              className="px-4 py-2 block w-full border border-gray-300 rounded-md shadow-md"
            />
          </div>
        </div>
        <div className="mb-3">
          <label
            htmlFor="name"
            className="block font-medium text-gray-600 text-sm"
          >
            Duration (mins)
          </label>
          <div className="mt-1 relative rounded-md">
            <input
              defaultValue={newService.length}
              required
              type="number"
              name="length"
              id="length"
              onChange={(e) => (newService.length = e.target.value)}
              className="px-4 py-2 block w-full border border-gray-300 rounded-md shadow-md"
            />
          </div>
        </div>
        <div className="mb-3">
          <label
            htmlFor="name"
            className="block font-medium text-gray-600 text-sm"
          >
            Cost
          </label>
          <div className="mt-1 relative rounded-md currency-wrap">
            <span className="currency-code">&pound;</span>
            <input
              defaultValue={newService.cost}
              required
              type="number"
              name="cost"
              id="cost"
              onChange={(e) => (newService.cost = e.target.value)}
              className="px-4 py-2 block w-full border border-gray-300 rounded-md shadow-md"
            />
          </div>
        </div>
        <div className="mb-3">
          <label
            htmlFor="name"
            className="block font-medium text-gray-600 text-sm"
          >
            Insurer Cost
          </label>
          <div className="mt-1 relative rounded-md currency-wrap">
            <span className="currency-code">&pound;</span>
            <input
              defaultValue={newService.insurer_cost}
              required
              type="number"
              name="insurer_cost"
              id="insurercost"
              onChange={(e) => (newService.insurer_cost = e.target.value)}
              className="px-4 py-2 block w-full border border-gray-300 rounded-md shadow-md"
            />
          </div>
        </div>
        <div className=" w-full mr-2 flex items-center mb-2">
          <div className="relative mr-2">
            <input
              type="checkbox"
              id="get_all"
              onChange={(e) => {
                setNewService({ ...newService, assessment: e.target.checked })
              }}
              placeholder="Assessment"
              className="field"
              checked={newService.assessment}
            />
          </div>
          <label
            htmlFor="get_all"
            className="block font-medium text-gray-600 text-sm"
          >
            Assessment?
          </label>
        </div>
        {newService.assessment && (
          <div className="mb-3">
            <label
              htmlFor="name"
              className="block font-medium text-gray-600 text-sm"
            >
              Parent Service
            </label>
            <select
              defaultValue={newService.parent_id}
              className="px-2 py-2 block w-full border border-gray-300 rounded-md shadow-md"
              required
              onChange={(e) => (newService.parent_id = e.target.value)}
            >
              <option disabled value={{}}>
                Select Service
              </option>
              {services?.map((service) => {
                if (!service.assessment) {
                  return (
                    <option key={service.id} value={service.id}>
                      {service.title}
                    </option>
                  )
                }

                return null
              })}
            </select>
          </div>
        )}

        <div className="flex mt-2 w-full">
          <button className="btn mt-4 mr-4 w-1/2">Add Service</button>

          <button
            type="button"
            className="btn red mt-4 w-1/2"
            onClick={resetModal}
          >
            Cancel
          </button>
        </div>
      </form>
    )
  }

  const RemoveServiceElement = () => {
    const removeService = async (e) => {
      setShowModal(false)
      setShowLoader(true)
      await axios.post(`/services/${deleteService.id}/remove`)
      createInfo('error', `Deleted Service: ${deleteService.title}`)
      setCurrentRecords(
        currentRecords.filter((service) => {
          return service.id !== deleteService.id
        })
      )
      setServices(
        services.filter((service) => {
          return service.id !== deleteService.id
        })
      )
      setDeleteService({})
      setShowLoader(false)
    }
    return (
      <form onSubmit={removeService} className="justify-center flex">
        <div className="mb-3">
          <p className="text-lg font-bold my-8 text-center">
            Are you sure you want to remove this service?
          </p>
          <div className="flex mt-2 w-full">
            <button className="btn red mt-4 mr-4 w-1/2">Delete Service</button>

            <button
              type="button"
              className="btn mt-4 w-1/2"
              onClick={resetModal}
            >
              Cancel
            </button>
          </div>
        </div>
      </form>
    )
  }

  const ToggleServiceElement = () => {
    let status = 1
    let statusText = 'Enable'
    if (toggledService.status_id === 1) {
      status = 2
      statusText = 'Disable'
    }

    const toggleService = async (e) => {
      setShowModal(false)
      setShowLoader(true)
      await axios.post(`/services/${toggledService.id}/toggle-status`, {
        status,
      })
      if (toggledService.status_id === 1) {
        toggledService.status_id = 2
        toggledService.status = 'Disabled'
      } else {
        toggledService.status = 'Active'
        toggledService.status_id = 1
      }
      // setCurrentRecords(response.data.result.services)
      setToggledService({})
      setShowLoader(false)
    }
    return (
      <form onSubmit={toggleService} className="justify-center flex">
        <div className="mb-3">
          <p className="text-lg font-bold my-8 text-center">
            Are you sure you want to {statusText.toLowerCase()} this service?
          </p>
          <div className="flex mt-2 w-full">
            <button className="btn mt-4 mr-4 w-1/2">
              {statusText} Service
            </button>

            <button
              type="button"
              className="btn red mt-4 w-1/2"
              onClick={resetModal}
            >
              Cancel
            </button>
          </div>
        </div>
      </form>
    )
  }

  const removeServiceClicked = (service) => {
    setRemoveService(true)
    setDeleteService({ ...service })
    setModalTitle(`Remove Service: ${service.title}`)
    setShowModal(true)
  }

  const toggleServiceClicked = (service) => {
    setToggleService(true)
    setToggledService(service)
    if (service.status_id === 1) {
      setModalTitle(`Disable Service: ${service.title}`)
    } else {
      setModalTitle(`Enable Service: ${service.title}`)
    }
    setShowModal(true)
  }

  const addServiceClicked = () => {
    setAddService({})
    setModalTitle(`Add Service`)
    setShowModal(true)
  }

  const resetModal = () => {
    setAddService(false)
    setNewService({ parent_id: {} })

    setRemoveService(false)
    setDeleteService({})

    setToggleService(false)
    setToggledService({})

    setShowModal(false)
    setModalTitle('')
  }

  return (
    <section className="w-full">
      {showModal && (
        <Modal title={modalTitle} body={<ModalBody />} show={resetModal} />
      )}
      {can('view clinic services') && (
        <div>
          <div className="pb-4 flex justify-between">
            <div className="relative mt-1">
              <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                <FontAwesomeIcon
                  icon={faMagnifyingGlass}
                  onClick={handleSearch}
                />
              </div>
              <input
                type="text"
                id="table-search"
                onChange={handleSearch}
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-80 pl-10 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="Search Services"
              />
            </div>
            {can('add clinic services') && (
              <div
                className="cursor-pointer ml-4 text-blue-500 font-bold items-center flex btn"
                onClick={addServiceClicked}
              >
                <span>Add Service</span>
              </div>
            )}
          </div>

          <table className="table-main">
            <thead>
              <tr>
                <th className='cursor-pointer' scope="col" onClick={() => handleSort('title')}>Title {renderSortArrow('title')}</th>
                <th className='cursor-pointer' scope="col" onClick={() => handleSort('description')}>Description {renderSortArrow('description')}</th>
                <th className='cursor-pointer' scope="col" onClick={() => handleSort('assessment')}>Type {renderSortArrow('assessment')}</th>
                <th className='cursor-pointer' scope="col" onClick={() => handleSort('default_cost')}>Cost {renderSortArrow('default_cost')}</th>
                <th className='cursor-pointer' scope="col" onClick={() => handleSort('status')}>Status {renderSortArrow('status')}</th>
                {can('remove clinic services') && <th scope="col">Actions</th>}
              </tr>
            </thead>
            <tbody>
              {currentRecords.length > 0 ? (
                currentRecords?.map((service) => {
                  return (
                    <tr key={service.id}>
                      <td>{service.title}</td>
                      <td>{service.description}</td>
                      <td>{service.assessment ? 'Assessment' : 'Session'}</td>
                      <td>&pound;{service.default_cost}</td>
                      <td>
                        <div
                          className={`status ${service.status.toLowerCase()}`}
                        >
                          {service.status}
                        </div>
                      </td>

                      <td>
                        <div className="flex justify-center">
                          {can('toggle clinic services') && (
                            <span
                              title="Toggle Service"
                              onClick={() => toggleServiceClicked(service)}
                              className="flex justify-center items-center bg-orange-300 rounded-md text-orange-800 h-9 w-12 mx-2  hover:bg-orange-400 cursor-pointer"
                            >
                              <FontAwesomeIcon icon={faSliders} />
                            </span>
                          )}
                          {can('remove clinic services') && (
                            <span
                              title="Delete Service"
                              onClick={() => removeServiceClicked(service)}
                              className="flex justify-center items-center bg-red-400 rounded-md text-red-800 h-9 w-12 mx-2  hover:bg-red-500 cursor-pointer"
                            >
                              <FontAwesomeIcon icon={faTrashCan} />
                            </span>
                          )}
                        </div>
                      </td>
                    </tr>
                  )
                })
              ) : (
                <tr>
                  <td colSpan={6}>
                    <p className="no-records">No services found</p>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
          <Pagination
            next={nextPage}
            prev={previousPage}
            first={indexOfFirstRecord}
            last={indexOfLastRecord}
            total={filteredRecords.length}
          />
        </div>
      )}
    </section>
  )
}

export default ClinicServices
