/* eslint-disable */
import { useState, useEffect, useRef, setState } from 'react'
import useAxiosPrivate from '../../hooks/useAxiosPrivate'
import MainTitle from '../../components/MainTitle'
import { Link, useNavigate, useParams } from 'react-router-dom'
import useAuth from '../../hooks/useAuth'
import ClientNav from '../../components/ClientNav'
import ActiveQuestionnaire from '../../components/ActiveQuestionnaire'

import Modal from '../../components/Modal'
import TriageDetails from '../../components/TriageDetails'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faChevronLeft,
  faPlus,
  faTrash,
} from '@fortawesome/free-solid-svg-icons'

const Session = () => {
  const { clientId, claimId, sessionId } = useParams()
  const axios = useAxiosPrivate()
  const [clientData, setClientData] = useState({
    name: '',
    reference: '',
  })
  const [claimData, setClaimData] = useState({
    reference: '',
    external_reference: '',
  })
  const [sessionData, setSessionData] = useState({ notes: '' })
  const { setShowLoader, auth, createInfo, admin, can } = useAuth()

  const [assignRisk, setAssignRisk] = useState({})
  const [riskOptions, setRiskOptions] = useState([])
  const [riskReasons, setRiskReasons] = useState([])
  const [currentRisk, setCurrentRisk] = useState({})
  const [cancelReasons, setCancelReasons] = useState([])
  const [newQuestionnaire, setNewQuestionnaire] = useState(false)

  const [deleteQResponse, setDeleteQResponse] = useState({})

  const [showModal, setShowModal] = useState(false)
  const [modalTitle, setModalTitle] = useState('')
  const [clinicId, setClinicId] = useState('')
  const [questionnaires, setQuestionnaires] = useState([])
  const [selectedQuestionnaire, setSelectedQuestionnaire] = useState(0)
  const [sessionQuestionnaires, setSessionQuestionnaires] = useState([])
  const [activeQuestionnaire, setActiveQuestionnaire] = useState({})
  const [updateQuestionnaire, setUpdateQuestionnaire] = useState(false)
  const [showQuestionnaire, setShowQuestionnaire] = useState(false)

  const navigate = useNavigate()

  const getSessionById = async () => {
    setShowLoader(true)
    try {
      const response = await axios.get(
        `/clients/${clientId}/claims/${claimId}/sessions/${sessionId}`
      )
      setClientData(
        response.data.result.claim_relationship[0].client_relationship
      )
      setCurrentRisk(
        response.data.result.claim_relationship[0].client_relationship.risk[0]
      )
      setSessionQuestionnaires(response.data.result.questionnaire_responses)
      setClaimData(response.data.result.claim_relationship[0])
      setSessionData(response.data.result)
      setClinicId(response.data.result.claim_relationship[0].company_id)
    } catch (error) {}
    setShowLoader(false)
  }

  const getRiskOptions = async () => {
    try {
      setShowLoader(true)
      const response = await axios.get(`/risk-options/all`)
      if (response.data.result) {
        await setRiskOptions(response.data.result)
      }
    } catch (error) {}
    setShowLoader(false)
  }

  const getRiskReasons = async () => {
    try {
      setShowLoader(true)
      const response = await axios.get(`/risk-reasons/all`)
      if (response.data.result) {
        await setRiskReasons(response.data.result)
      }
    } catch (error) {}
    setShowLoader(false)
  }

  const getCancelReasons = async () => {
    try {
      setShowLoader(true)
      const response = await axios.get(`/cancel-reasons/all`)
      if (response.data.result) {
        await setCancelReasons(response.data.result)
      }
    } catch (error) {}
    setShowLoader(false)
  }

  useEffect(() => {
    getSessionById()
    getRiskOptions()
    getRiskReasons()
    getCancelReasons()
    // getQuestionnaires()
  }, [])

  const getQuestionnaires = async () => {
    try {
      const response = await axios.get(`companies/${clinicId}/questionnaires`)
      if (response.data.result) {
        await setQuestionnaires(response.data.result)
      }
    } catch (error) {}
  }

  const updateRiskLevelClicked = (risk) => {
    setAssignRisk({})
    setModalTitle(`Update Client Risk Level`)
    setShowModal(true)
  }

  const deleteQResponseClicked = (qResponse) => {
    setDeleteQResponse({ ...qResponse })
    setModalTitle(
      `Delete Questionnaire Response: ${JSON.parse(qResponse.content).title}`
    )
    setShowModal(true)
  }

  const addQuestionnaireClicked = async () => {
    await getQuestionnaires()
    setNewQuestionnaire(true)
    setModalTitle(`Add a Questionnaire`)
    setShowModal(true)
  }

  const UpdateRiskAssignElement = () => {
    const addRisk = async (e) => {
      e.preventDefault()
      setShowLoader(true)
      try {
        const response = await axios.post(
          `/clients/${clientData.id}/risk/add`,
          {
            risk_id: assignRisk.id,
            risk_reason_id: assignRisk.reason,
            session_id: sessionId,
          }
        )
        if (!response.data.error) {
          createInfo(
            'success',
            `Updated Client Risk Level to ${response.data.result.title}`
          )
          setCurrentRisk(response.data.result)
          resetModal()
        }
      } catch (error) {
        console.log(error)
      }
      setShowLoader(false)
    }

    return (
      <form onSubmit={addRisk}>
        <div className="mb-3">
          <label
            htmlFor="name"
            className="block font-medium text-gray-600 text-sm"
          >
            Risk Level
          </label>
          <div className="mt-1 relative rounded-md">
            <select
              className="px-2 py-2 block w-full border border-gray-300 rounded-md shadow-md"
              required
              onChange={(e) => {
                assignRisk.id = e.target.value
              }}
              id="risk"
            >
              <option disabled value="0">
                Select a Risk Level
              </option>
              {riskOptions?.map((option) => {
                return (
                  <option key={option.id} value={option.id}>
                    {option.title}
                  </option>
                )
              })}
            </select>
          </div>
        </div>

        <div className="mb-3">
          <label
            htmlFor="name"
            className="block font-medium text-gray-600 text-sm"
          >
            Risk Reason
          </label>
          <div className="mt-1 relative rounded-md">
            <select
              className="px-2 py-2 block w-full border border-gray-300 rounded-md shadow-md"
              required
              onChange={(e) => {
                assignRisk.reason = e.target.value
              }}
              id="riskReason"
            >
              <option disabled value="0">
                Select a Risk Reason
              </option>
              {riskReasons?.map((reason) => {
                return (
                  <option key={reason.id} value={reason.id}>
                    {reason.title}
                  </option>
                )
              })}
            </select>
          </div>
        </div>

        <div className="flex mt-2 w-full">
          <button className="btn blue mt-4 mr-4 w-1/2">
            Update Risk Level
          </button>

          <button
            type="button"
            className="btn red mt-4 w-1/2"
            onClick={resetModal}
          >
            Cancel
          </button>
        </div>
      </form>
    )
  }

  const AddQuestionnaireElement = () => {
    const goToQuestionnaire = async () => {
      await setActiveQuestionnaire(JSON.parse(selectedQuestionnaire))
      setShowQuestionnaire(true)
      setUpdateQuestionnaire(false)
      resetModal()
    }

    return (
      <div>
        <div className="mb-3">
          <label
            htmlFor="name"
            className="block font-medium text-gray-600 text-sm"
          >
            Select a Questionnaire
          </label>
          <select
            defaultValue={selectedQuestionnaire ?? 0}
            onChange={(e) => setSelectedQuestionnaire(e.target.value)}
            className="px-2 py-2 block w-full border border-gray-300 rounded-md shadow-md"
          >
            <option value={0} disabled>
              Select a Questionnaire
            </option>
            {questionnaires?.map((item, index) => {
              return (
                <option key={item.id} value={JSON.stringify(item)}>
                  {item.title}
                </option>
              )
            })}
          </select>
        </div>

        <div className="flex mt-2 w-full">
          <button
            className="btn blue mt-4 mr-4 w-1/2"
            onClick={goToQuestionnaire}
          >
            Add Questionnaire
          </button>

          <button
            type="button"
            className="btn red mt-4 w-1/2"
            onClick={resetModal}
          >
            Cancel
          </button>
        </div>
      </div>
    )
  }

  const DeleteQuestionnaireResponseElement = () => {
    const deleteQuestionnaireResponse = async (e) => {
      e.preventDefault()
      setShowLoader(true)
      resetModal(false)
      try {
        const response = await axios.get(
          '/questionnaire-response/delete/' + deleteQResponse.id
        )
        createInfo(
          'error',
          `Deleted Questionnaire Response: ${deleteQResponse.title}`
        )
        setSessionQuestionnaires(response.data.result)
        setShowLoader(false)
      } catch (error) {
        setShowLoader(false)
      }
    }

    return (
      <form onSubmit={deleteQuestionnaireResponse}>
        <div className="mb-3">
          <p className="text-lg font-bold my-8 text-center">
            Are you sure you want to delete this Questionnaire Response?
          </p>
          <div className="flex mt-2 w-full">
            <button className="btn red mt-4 mr-4 w-1/2">Delete</button>

            <button
              type="button"
              className="btn mt-4 w-1/2"
              onClick={resetModal}
            >
              Cancel
            </button>
          </div>
        </div>
      </form>
    )
  }

  const ModalBody = () => {
    if (deleteQResponse.id) {
      return <DeleteQuestionnaireResponseElement />
    }
    if (newQuestionnaire) {
      return <AddQuestionnaireElement />
    }
    if (assignRisk) {
      return <UpdateRiskAssignElement />
    }
  }

  const resetModal = () => {
    setShowModal(false)
    setSelectedQuestionnaire(0)
    setNewQuestionnaire(false)
    setAssignRisk({})
    setDeleteQResponse({})
  }

  const updateSession = async () => {
    setShowLoader(true)

    const data = {
      client_attended: sessionData.client_attended,
      notes: sessionData.notes,
    }

    try {
      const response = await axios.post(`/sessions/update/${sessionId}`, data)
      if (!response.data.error) {
        createInfo('success', `Session has been updated`)
      }
    } catch (error) {}
    setShowLoader(false)
  }

  const goToClaim = () => {
    navigate(`/clients/${clientId}/claims/${claimId}`)
  }

  const updateAdditionalInfo = async (key, value) => {
    setShowLoader(true)

    const data = {}
    data[key] = value
    try {
      const response = await axios.post(`/sessions/update/${sessionId}`, data)
      if (!response.data.error) {
        createInfo('success', `Session has been updated`)
      }
    } catch (error) {}
    setShowLoader(false)
  }

  return (
    <div>
      <MainTitle
        title={`Client: ${clientData.name} (${clientData.reference})`}
        backButton
        child={clientData.child}
      />

      {showModal && (
        <Modal title={modalTitle} body={<ModalBody />} show={resetModal} />
      )}

      <ClientNav active="claims" client={clientData} />

      {!showQuestionnaire && sessionData.id && (
        <section>
          <span
            className="inline-flex items-center text-slate-500 mb-2 cursor-pointer hover:text-slate-600"
            onClick={goToClaim}
          >
            <FontAwesomeIcon icon={faChevronLeft} className="text-sm" />
            <span className="text-sm ml-2">View Claim</span>
          </span>
          <section className="w-full bg-white rounded-xl shadow-md py-4 px-4 mb-8">
            <div className="flex justify-between">
              <h2 className="font-bold text-lg mb-4">
                {claimData.reference} ({claimData.external_reference}) -{' '}
                {claimData.service.title}
              </h2>
              <button className="btn primary" onClick={updateSession}>
                Update Session
              </button>
            </div>
            <div>
              {can('access triage options') && (
                <>
                  <TriageDetails
                    claimData={claimData}
                    clientData={clientData}
                    referrableServices={[]}
                  />
                </>
              )}
            </div>
            <div className="mb-4">
              <div className="block mb-2">
                <h3>Questionnaires</h3>
                <div className="flex items-center">
                  <div className="flex items-center justify-between rounded-l-sm border border-r-0 w-full border-gray-200 p-2 h-12">
                    <div className=" relative rounded-md">
                      <ul className="flex">
                        {sessionQuestionnaires?.map((questionnaire) => {
                          return (
                            <li key={questionnaire.id} className="flex">
                              <button
                                className="pill-qr btn blue"
                                onClick={() => {
                                  setActiveQuestionnaire(questionnaire)
                                  setShowQuestionnaire(true)
                                  setUpdateQuestionnaire(true)
                                }}
                              >
                                {JSON.parse(questionnaire.content).title}
                              </button>
                              {/* {can('remove user services') ? ( */}
                              <span
                                title="Remove Questionnaire"
                                className="pill-button-qr"
                                onClick={() =>
                                  deleteQResponseClicked(questionnaire)
                                }
                              >
                                <FontAwesomeIcon icon={faTrash} />
                              </span>
                              {/* ) : ( */}
                              {/* <span className="mr-4"></span> */}
                              {/* )} */}
                            </li>
                          )
                        })}
                      </ul>
                    </div>
                  </div>
                  <div
                    className="flex justify-end items-center rounded-r-lg border border-gray-200 bg-gray-300 h-12 px-4 cursor-pointer text-sm"
                    onClick={addQuestionnaireClicked}
                  >
                    <FontAwesomeIcon icon={faPlus} />
                  </div>
                </div>
              </div>
            </div>

            <div className="mb-4">
              <label
                htmlFor="session_notes"
                className="text-sm font-medium text-gray-900"
              >
                Session Notes
              </label>
              <textarea
                required
                className="w-full border rounded-sm py-3 px-3 text-sm"
                defaultValue={sessionData.notes}
                onChange={(e) => {
                  sessionData.notes = e.target.value
                }}
                placeholder=""
              ></textarea>
            </div>

            <div className="items-center flex mb-8">
              <input
                type="checkbox"
                id="client_attended"
                checked={!sessionData.client_attended}
                className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded"
                onChange={(e) =>
                  (sessionData.client_attended = !e.target.checked)
                }
              />
              <label
                htmlFor="client_attended"
                className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
              >
                Client did not attend the session.
              </label>
            </div>

            <div className="mb-8">
              <div className="block mb-2">
                <span className="text-wba-primary font-semibold mr-2">
                  Current Risk Level:
                </span>
                <span
                  className="status"
                  style={{ backgroundColor: currentRisk.color }}
                >
                  {currentRisk.title || 'N/A'}
                </span>
              </div>
              <button
                className="btn warning small"
                onClick={updateRiskLevelClicked}
              >
                Update Client Risk Level
              </button>
            </div>

            <hr className="bg-gray-300 w-full my-4" />
            <div>
              <h3 className="font-bold mb-2">Additional Information:</h3>
              {sessionData.cancelled == 1 && (
                <span className="bg-orange-500 text-white text-sm py-2 px-4 rounded mb-2 inline-flex">
                  This session has been cancelled, this cannot be changed
                </span>
              )}
              <ul className="space-y-4">
                <li className="flex flex-col mb-2">
                  <span className="font-medium">Session Cancelled?</span>
                  {admin ? (
                    <div className="toggle flex items-center">
                      <span className="text-sm font-bold mr-3">No</span>
                      <label
                        className={`flex items-center relative w-max select-none ${
                          sessionData.cancelled == 1
                            ? 'pointer-events-none cursor-default'
                            : 'cursor-pointer'
                        }`}
                      >
                        <input
                          defaultChecked={sessionData.cancelled}
                          onChange={(e) => {
                            updateAdditionalInfo('cancelled', e.target.checked)
                            setSessionData({
                              ...sessionData,
                              cancelled: e.target.checked,
                            })
                          }}
                          disabled={
                            sessionData.cancelled && sessionData.cancelled === 1
                          }
                          type="checkbox"
                          className="appearance-none transition-colors cursor-pointer w-[60px] h-7 rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-blue focus:ring-blue-500 bg-gray-300 shadow-inner"
                        />
                        <span className="w-6 h-6 right-8 absolute rounded-full transform transition-transform bg-white" />
                      </label>
                      <span className="text-sm font-bold ml-3">Yes</span>
                    </div>
                  ) : (
                    <span>
                      {sessionData.session_cancelled !== true ? 'No' : 'Yes'}
                    </span>
                  )}
                </li>
                <li className="flex flex-col mb-2">
                  <span className="font-medium">Cancel Reason?</span>
                  <div>
                    {admin ? (
                      <div className="mt-1 relative rounded-md w-1/4">
                        <select
                          defaultValue={sessionData.cancel_reason_id ?? 0}
                          className="px-2 py-2 block w-full border border-gray-300 rounded-md shadow-md"
                          required
                          disabled={
                            sessionData.cancel_reason_id &&
                            sessionData.cancel_reason_id > 0
                          }
                          onChange={(e) => {
                            updateAdditionalInfo(
                              'cancel_reason_id',
                              e.target.value
                            )
                            setSessionData({
                              ...sessionData,
                              cancel_reason_id: e.target.value,
                            })
                          }}
                          id="cancel-reason"
                        >
                          <option disabled value={0}>
                            Select a cancel reason
                          </option>
                          {cancelReasons?.map((reason) => {
                            return (
                              <option key={reason.id} value={reason.id}>
                                {reason.title}
                              </option>
                            )
                          })}
                        </select>
                      </div>
                    ) : (
                      <span>
                        {sessionData.cancel_reason
                          ? sessionData.cancel_reason.title
                          : 'N/A'}
                      </span>
                    )}
                  </div>
                </li>
                {/* <li className="flex flex-col">
                  <span className="font-medium">Session Refunded?</span>

                  {admin ? (
                    <div className="toggle flex items-center">
                      <span className="text-sm font-bold mr-3">No</span>
                      <label className="flex items-center relative w-max cursor-pointer select-none">
                        <input
                          defaultChecked={sessionData.refunded}
                          onChange={(e) =>
                            updateAdditionalInfo('refunded', e.target.checked)
                          }
                          type="checkbox"
                          className="appearance-none transition-colors cursor-pointer w-[60px] h-7 rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-blue focus:ring-blue-500 bg-gray-300 shadow-inner "
                        />
                        <span className="w-6 h-6 right-8 absolute rounded-full transform transition-transform bg-white" />
                      </label>
                      <span className="text-sm font-bold ml-3">Yes</span>
                    </div>
                  ) : (
                    <span>{sessionData.refunded !== 1 ? 'No' : 'Yes'}</span>
                  )}
                </li> */}
              </ul>
            </div>
          </section>
        </section>
      )}

      {showQuestionnaire && (
        <ActiveQuestionnaire
          questionnaireData={activeQuestionnaire}
          resetQ={() => {
            setShowQuestionnaire(false)
            setActiveQuestionnaire({})
          }}
          session={sessionData}
          setList={setSessionQuestionnaires}
          list={sessionQuestionnaires}
          update={updateQuestionnaire}
        />
      )}
    </div>
  )
}

export default Session
