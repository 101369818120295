/* eslint-disable */
import { useState, useEffect, useRef } from 'react'
import useAxiosPrivate from '../../hooks/useAxiosPrivate'
import UserProfile from '../../components/UserProfile'
import MainTitle from '../../components/MainTitle'
import { Link, useParams } from 'react-router-dom'
import useAuth from '../../hooks/useAuth'
import { faTrash } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import UserNav from '../../components/UserNav'
import Modal from '../../components/Modal'

const User = () => {
  const { id } = useParams()
  const axios = useAxiosPrivate()
  const [userData, setUserData] = useState({})
  const { setShowLoader, can, createInfo } = useAuth()
  const errRef = useRef()
  const [errMsg, setErrMsg] = useState('')

  // Services
  const [services, setServices] = useState([])
  const [assignService, setAssignService] = useState({})
  const [assignedServices, setAssignedServices] = useState([])

  // Modal
  const [showModal, setShowModal] = useState(false)
  const [modalTitle, setModalTitle] = useState('')

  const getUserById = async () => {
    try {
      setShowLoader(true)
      const response = await axios.get(`brokers/user/${id}`)
      setUserData(response.data.result)
      setAssignedServices(response.data.result.services)
      setShowLoader(false)
    } catch (error) {}
  }

  const handleUpdateUser = (updatedUser) => {
    setUserData(updatedUser)
  }

  const assignServiceClicked = () => {
    getServices()
    setAssignService({})
    setModalTitle(`Assign Service`)
    setShowModal(true)
  }


  const resetModal = () => {
    setAssignService({})
    setShowModal(false)
  }

  const ModalBody = () => {
    if (assignService) {
      return <AssignServiceElement />
    }
  }

  const AssignServiceElement = () => {
    const assignServiceSubmit = async (e) => {
      e.preventDefault()
      setShowLoader(true)
      const data = JSON.parse(assignService)

      try {
        const response = await axios.post(`/users/assign-service`, {
          userId: userData.id,
          serviceId: data.id,
        })
        createInfo('success', `Assigned Service: ${data.title}`)
        setUserData(response.data.result)
        setAssignedServices(response.data.result.services)
        resetModal(false)
      } catch (error) {
        console.log(error)
      }

      setShowLoader(false)
    }

    return (
      <form onSubmit={assignServiceSubmit}>
        <div className="mb-3">
          <label
            htmlFor="name"
            className="block font-medium text-gray-600 text-sm"
          >
            Service Name
          </label>
          <select
            defaultValue={assignService}
            className="px-2 py-2 block w-full border border-gray-300 rounded-md shadow-md"
            required
            onChange={(e) => setAssignService(e.target.value)}
          >
            <option disabled value={{}}>
              Select Service
            </option>
            {services?.map((service) => {
              if (
                !assignedServices.find((assignedService) => {
                  return service.id === assignedService.id
                })
              ) {
                return (
                  <option key={service.id} value={JSON.stringify(service)}>
                    {service.title}
                  </option>
                )
              }
            })}
          </select>
        </div>
        <div className="flex mt-2 w-full">
          <button className="btn mt-4 mr-4 w-1/2">Assign Service</button>
          <button
            type="button"
            className="btn red mt-4 w-1/2"
            onClick={resetModal}
          >
            Cancel
          </button>
        </div>
      </form>
    )
  }

  useEffect(() => {
    getUserById(id)
  }, [])

  return (
    <div>
      {showModal && (
        <Modal title={modalTitle} body={<ModalBody />} show={resetModal} />
      )}

      <MainTitle title="Profile" />
      <UserNav active="profile" userId={userData.id} />
      <UserProfile user={userData} onUpdateUser={handleUpdateUser} />

      <section className="w-full bg-white rounded-xl shadow-md py-4 px-4 mb-8">
        <div className="grid lg:grid-cols-2 gap-4 px-10 py-8">
          <div>
            <span className='font-medium text-sovereign'>Office: </span>
            <span> London</span>
          </div>
          <div>
            <span className='font-medium text-sovereign'>Department: </span>
            <span> Client Management & Sales</span>
          </div>
          <div>
            <span className='font-medium text-sovereign'>Line Manager: </span>
            <a href="#" className='cursor-pointer'> Mandy Baker</a>
          </div>
          <div>
            <span className='font-medium text-sovereign'>Position: </span>
            <span> Sales and Service - Account Manager</span>
          </div>
          <div>
            <p className='font-medium text-sovereign'>Contact: </p>
            <p>Email: {userData.email}</p>
            <p>Mob: 02030055872</p>
            <p>Extension: 3490</p>
          </div>
        </div>
      </section>
    </div>
  )
}

export default User
