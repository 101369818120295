import React, { useState } from 'react';

const OverdueTasks = () => {
  const initialFinances = [
    {
      id: 1,
      name: 'Meeting with Client',
      amount: '57',
      currency: '',
      date: '1st Nov 2023',
      user: '-1.3%',
      status: 'overdue',
    },
  ];

  const statusStyles = {
    due: 'text-green-700 bg-green-200',
    overdue: 'text-red-700 bg-red-200',
    important: 'text-amber-700 bg-amber-200',
  };

  function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
  }

  const [finances, setFinances] = useState(initialFinances.map(item => ({ ...item, completed: false })));

  const handleToggleComplete = (id) => {
    setFinances(prevFinances => {
      return prevFinances.map(transaction => {
        if (transaction.id === id) {
          return { ...transaction, completed: !transaction.completed };
        }
        return transaction;
      });
    });
  };

  return (
    <div className="mt-6 space-y-4">
      {finances.map((transaction) => (
        <div
          className={`bg-backdrop rounded-xl px-4 py-2 flex items-center justify-between shadow-md ${
            transaction.completed ? 'bg-green-100' : ''
          }`}
          key={transaction.id}
        >
          <div className="flex items-center space-x-2">
            <div
              className={`w-6 h-6 border rounded-full border-gray-400 flex items-center justify-center cursor-pointer ${
                transaction.completed ? 'bg-sovereign' : 'bg-white'
              }`}
              onClick={() => handleToggleComplete(transaction.id)}
            >
              {transaction.completed && (
                <span className="text-white">&#10003;</span>
              )}
            </div>
            <div>
              <div className={`font-medium ${transaction.completed ? 'line-through' : ''}`}>
                {transaction.name}
              </div>
              <div className="text-sm">Due: {transaction.date}</div>
            </div>
          </div>
          <div className="text-end">
            <div
              className={classNames(
                statusStyles[transaction.status],
                'text-xs px-3 py-1 text-center rounded-full font-medium'
              )}
            >
              {transaction.status}
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default OverdueTasks;
