import { Link } from 'react-router-dom'
import useAuth from '../hooks/useAuth'

function MyClinicNav({ active = 'dashboard', clinicId }) {
  const { can } = useAuth()

  return (
    <div className="bg-white rounded-md shadow-md py-3 px-4 mb-8">
      <ul className="flex flex-col md:flex-row flex-wrap list-none px-0">
        <Link to={`/clinic`}>
          <li className={`tab-btn ${active === 'dashboard' ? 'active' : ''}`}>
            Dashboard
          </li>
        </Link>
        {can('view clinic availability') && (
          <Link to={`/clinic/availability`}>
            <li
              className={`tab-btn ${active === 'availability' ? 'active' : ''}`}
            >
              All Availability
            </li>
          </Link>
        )}
        {can('view clinic bookings') && (
          <Link to={`/clinic/bookings`}>
            <li className={`tab-btn ${active === 'bookings' ? 'active' : ''}`}>
              All Bookings
            </li>
          </Link>
        )}
      </ul>
    </div>
  )
}

export default MyClinicNav
