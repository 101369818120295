import { NavLink } from 'react-router-dom'
import React, { useState, useEffect } from 'react';
import {
  faUser,
  faChartLine,
  faHouse,
  faUsers,
  faMoneyBill,
  faSterlingSign,
  faBuilding,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import useAuth from '../../hooks/useAuth'

const navClasses =
  'w-full text-white py-3 px-3 flex relative rounded-md items-center'

function Nav() {
  const { can } = useAuth();
  
  const [isSmallScreen, setIsSmallScreen] = useState(false);

  useEffect(() => {
    const checkScreenSize = () => {
      if (window.innerWidth <= 870) {
        setIsSmallScreen(true);
      } else {
        setIsSmallScreen(false);
      }
    };
  
    checkScreenSize();
    window.addEventListener('resize', checkScreenSize);
  
    return () => {
      window.removeEventListener('resize', checkScreenSize);
    };
  }, []);


  return (
    <div>
      {isSmallScreen ? (
        <ul className="fixed bottom-0 left-0 right-0 bg-sovereign py-4 flex justify-between px-10 z-50">
          <li>
            <NavLink to="/" className={({ isActive }) =>
                  isActive
                    ? 'bg-blue-500 ' + navClasses
                    : navClasses + ' hover:bg-peach'
                }>
              <FontAwesomeIcon icon={faHouse} className="text-xl mb-2" />        
            </NavLink>
          </li>
          <li>
            <NavLink to="/clients" className={({ isActive }) =>
                  isActive
                    ? 'bg-blue-500 ' + navClasses
                    : navClasses + ' hover:bg-peach'
                }>
              <FontAwesomeIcon icon={faUser} className="text-xl mb-2" />          
            </NavLink>
          </li>
          <li>
            <NavLink to="/reports" className={({ isActive }) =>
                  isActive
                    ? 'bg-blue-500 ' + navClasses
                    : navClasses + ' hover:bg-peach'
                }>
              <FontAwesomeIcon icon={faChartLine} className="text-xl mb-2" />
              
            </NavLink>
          </li>
          <li>
            <NavLink to="/teams" className={({ isActive }) =>
                  isActive
                    ? 'bg-blue-500 ' + navClasses
                    : navClasses + ' hover:bg-peach'
                }>
              <FontAwesomeIcon icon={faUsers} className="text-xl mb-2" />     
            </NavLink>
          </li>
        </ul>
      ) : (
        <ul>
            <li className="mb-2">
              <NavLink
                to="/"
                className={({ isActive }) =>
                  isActive
                    ? 'bg-blue-500 ' + navClasses
                    : navClasses + ' hover:bg-peach'
                }
              >
                <span className="flex justify-start w-10">
                  <FontAwesomeIcon icon={faHouse} className="mr-4 text-xl" />
                </span>
                Home
              </NavLink>
            </li>

            <li className="mb-2">
              <NavLink
                to="/clients"
                className={({ isActive }) =>
                  isActive
                    ? 'bg-blue-500 ' + navClasses
                    : navClasses + ' hover:bg-peach'
                }
              >
                <span className="flex justify-start w-10">
                  <FontAwesomeIcon icon={faUsers} className="mr-4 text-xl" />
                </span>
                Clients
              </NavLink>
            </li>
          
            {/* <li className="mb-2">
              <NavLink
                to="/finances"
                className={({ isActive }) =>
                  isActive
                    ? 'bg-blue-500 ' + navClasses
                    : navClasses + ' hover:bg-slate-700'
                }
              >
                <span className="flex justify-start w-10">
                  <FontAwesomeIcon icon={faSterlingSign} className="mr-4 text-xl" />
                </span>
                Finances
              </NavLink>
            </li> */}
          
            <li className="mb-2">
              <NavLink
                to="/reports"
                className={({ isActive }) =>
                  isActive
                    ? 'bg-blue-500 ' + navClasses
                    : navClasses + ' hover:bg-peach'
                }
              >
                <span className="flex justify-start w-10">
                  <FontAwesomeIcon icon={faChartLine} className="mr-4 text-xl" />
                </span>
                Reports
              </NavLink>
            </li>

            <li className="mb-2">
              <NavLink
                to="/teams"
                className={({ isActive }) =>
                  isActive
                    ? 'bg-blue-500 ' + navClasses
                    : navClasses + ' hover:bg-peach'
                }
              >
                <span className="flex justify-start w-10">
                  <FontAwesomeIcon icon={faBuilding} className="mr-4 text-xl" />
                </span>
                Offices
              </NavLink>
            </li>
        </ul>
      )}
      </div>
    );
}

export default Nav
