import { useState } from 'react'
import useAuth from '../hooks/useAuth'
import useAxiosPrivate from '../hooks/useAxiosPrivate'
import moment from 'moment'
import { useEffect } from 'react'
import Modal from '../components/Modal'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

function BookSession({
  claimData = null,
  sessions = [],
  setBookSession,
  insurerHistory,
}) {
  const { auth, setShowLoader, createInfo } = useAuth()
  const axios = useAxiosPrivate()
  const [availabilities, setAvailabilities] = useState([])
  const [selectedTimeSlot, setSelectedTimeSlot] = useState({})
  const [selectedPractitioner, setSelectedPractitioner] = useState(0)
  const [availabilitiesLoaded, setAvailabilitiesLoaded] = useState(false)
  const [showAllAvailable, setShowAllAvailable] = useState(false)

  const [isInsured, setIsInsured] = useState(true)
  const [hasSessions, setHasSessions] = useState(true)

  const [bookingData, setBookingData] = useState({})

  // Modal
  const [showModal, setShowModal] = useState(false)
  const [modalTitle, setModalTitle] = useState('')

  const [session, setSession] = useState({
    date: moment().format('YYYY-MM-DD'),
    service: 0,
  })

  const loadAvailability = async () => {
    setAvailabilitiesLoaded(false)
    setSelectedPractitioner(0)
    setShowAllAvailable(false)
    setSelectedTimeSlot([])
    setShowLoader(true)
    try {
      const response = await axios.get(
        `/availabilities/${session.date}/${claimData.service.id}/${session.allUsers}`
      )
      if (!response.data.error) {
        setAvailabilitiesLoaded(true)
        groupAvailabilitiesByDay(response.data.result)
      }
    } catch (error) {}
    setShowLoader(false)
  }

  const bookSessionClicked = () => {
    setModalTitle(`Cannot Book Session`)
    setShowModal(true)
  }

  const resetModal = () => {
    setShowModal(false)
  }

  const groupAvailabilitiesByDay = (availabilities) => {
    let arr = {}
    let practitioners = []
    availabilities.forEach((a, index) => {
      let days = {}
      let timeSlots = {}
      a.slots.forEach((slot) => {
        if (days[moment(slot).format('DD/MM/YYYY')]) {
          days[moment(slot).format('DD/MM/YYYY')].push(slot)
        } else {
          days[moment(slot).format('DD/MM/YYYY')] = [slot]
        }

        if (!timeSlots[moment(slot).format('DD/MM/YYYY')]) {
          timeSlots[moment(slot).format('DD/MM/YYYY')] = 0
        }
      })
      arr[a.user.name] = timeSlots
      a.days = days
      practitioners.push(a)
    })
    setAvailabilities(practitioners)
    setSelectedTimeSlot(arr)
  }

  const checkHasSessions = () => {
    let sessions = claimData.sessions
    let default_sessions = claimData.service.default_sessions
    let session_count = 0

    sessions.forEach((item) => {
      if (item.type_id === 3) {
        session_count += 1
      }
    })

    if (session_count < default_sessions) {
      return true
    }

    return false
  }

  const NoSessionsElement = () => {
    const add = async (e) => {
      e.preventDefault()
      resetModal()
      setShowLoader(true)
      const data = {
        date: moment(bookingData.day, 'DD/MM/YYYY').format('YYYY-MM-DD'),
        start: moment(bookingData.time).format('HH:mm'),
        end: moment(bookingData.time)
          .add(claimData.service.default_length, 'minutes')
          .format('HH:mm'),
        user: bookingData.user,
        service: claimData.service,
      }

      try {
        const response = await axios.post(
          `/claims/${claimData.id}/session/add`,
          data
        )
        if (!response.data.error) {
          sessions.push(response.data.result)
          createInfo(
            'success',
            `Session booked for ${bookingData.day} ${moment(
              bookingData.time
            ).format('HH:mm')}`
          )
          setBookSession(false)
        }
      } catch (error) {
        console.log(error)
      }
      setShowLoader(false)
    }
    return (
      <form onSubmit={add} className="justify-center flex">
        <div className="mb-3">
          {isInsured && !hasSessions ? (
            <p className="text-lg font-bold my-8 text-center">
              This client has no remaining sessions.
            </p>
          ) : (
            <p className="text-lg font-bold my-8 text-center">
              This client is not currently insured.
            </p>
          )}
          <div className="flex mt-2 w-full">
            {(auth.user.type_id === 1 || auth.user.type_id === 2) && (
              <button className="btn red mt-4 mr-4 w-1/2">Book Session</button>
            )}

            <button
              type="button"
              className={
                auth.user.type_id === 1 || auth.user.type_id === 2
                  ? 'btn mt-4 w-1/2'
                  : 'btn mt-4 w-full'
              }
              onClick={resetModal}
            >
              Cancel
            </button>
          </div>
        </div>
      </form>
    )
  }

  const ModalBody = () => {
    if (!isInsured || !hasSessions) {
      return <NoSessionsElement />
    }
  }

  const addSession = async (user, day, time) => {
    if (time === 0) {
      createInfo('error', 'Please select a time slot')
      return
    }
    setShowLoader(true)
    let bookingInfo = {
      day: day,
      user: user,
      time: time,
    }
    setBookingData(bookingInfo)

    if (insurerHistory.length > 0) {
      let canBook = checkHasSessions()
      if (!canBook) {
        setShowLoader(false)
        setHasSessions(false)
        bookSessionClicked()
        return
      }
      let policyStart = moment(insurerHistory[0].policy_start_date)
      let policyEnd = moment(insurerHistory[0].policy_end_date)
      let reserveTime = moment(time)
      //if this is false it wills how a modal
      if (!reserveTime.isBetween(policyStart, policyEnd)) {
        setShowLoader(false)
        bookSessionClicked()
        setIsInsured(false) //returns true or false
        return
      }

      if (reserveTime.isBetween(policyStart, policyEnd)) {
        const data = {
          date: moment(day, 'DD/MM/YYYY').format('YYYY-MM-DD'),
          start: moment(time).format('HH:mm'),
          end: moment(time)
            .add(claimData.service.default_length, 'minutes')
            .format('HH:mm'),
          user: user,
          service: claimData.service,
        }

        try {
          const response = await axios.post(
            `/claims/${claimData.id}/session/add`,
            data
          )
          if (!response.data.error) {
            sessions.push(response.data.result)
            createInfo(
              'success',
              `Session booked for ${day} ${moment(time).format('HH:mm')}`
            )
            setBookSession(false)
          }
        } catch (error) {
          console.log(error)
        }
        setShowLoader(false)
      }
    } else {
      setShowLoader(false)
      setHasSessions(false)
      bookSessionClicked()
      return
    }
  }

  const ShowAvailability = ({ data, specificNeeds }) => {
    let hasSpeciality = []
    if (data.user.specialities.length > 0) {
      specificNeeds.forEach((need) => {
        if (
          data.user.specialities.some((speciality) => {
            return speciality.id === need.id
          })
        ) {
          hasSpeciality++
        }
      })
    }

    return (
      <div className="p-4 rounded-md border border-slate-300 mt-4">
        <div className="flex">
          {hasSpeciality > 0 && (
            <div className="group w-7 h-7 fill-yellow-500 cursor-pointer relative mr-2 z-10">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                <path d="M327.5 85.2c-4.5 1.7-7.5 6-7.5 10.8s3 9.1 7.5 10.8L384 128l21.2 56.5c1.7 4.5 6 7.5 10.8 7.5s9.1-3 10.8-7.5L448 128l56.5-21.2c4.5-1.7 7.5-6 7.5-10.8s-3-9.1-7.5-10.8L448 64 426.8 7.5C425.1 3 420.8 0 416 0s-9.1 3-10.8 7.5L384 64 327.5 85.2zM205.1 73.3c-2.6-5.7-8.3-9.3-14.5-9.3s-11.9 3.6-14.5 9.3L123.3 187.3 9.3 240C3.6 242.6 0 248.3 0 254.6s3.6 11.9 9.3 14.5l114.1 52.7L176 435.8c2.6 5.7 8.3 9.3 14.5 9.3s11.9-3.6 14.5-9.3l52.7-114.1 114.1-52.7c5.7-2.6 9.3-8.3 9.3-14.5s-3.6-11.9-9.3-14.5L257.8 187.4 205.1 73.3zM384 384l-56.5 21.2c-4.5 1.7-7.5 6-7.5 10.8s3 9.1 7.5 10.8L384 448l21.2 56.5c1.7 4.5 6 7.5 10.8 7.5s9.1-3 10.8-7.5L448 448l56.5-21.2c4.5-1.7 7.5-6 7.5-10.8s-3-9.1-7.5-10.8L448 384l-21.2-56.5c-1.7-4.5-6-7.5-10.8-7.5s-9.1 3-10.8 7.5L384 384z" />
              </svg>
              <span
                className={`absolute top-8 scale-0 transition-all font-normal rounded bg-gray-800 p-2 text-sm text-white group-hover:scale-100 -left-7 min-w-[300px]`}
              >
                This practitioner has {hasSpeciality} matching{' '}
                {hasSpeciality === 1 ? 'speciality' : 'specialities'}
              </span>
            </div>
          )}
          <p className="font-bold text-lg mb-2">
            {data.user.name}
            {/* <span className="text-sm">(90%)</span> */}
          </p>
        </div>

        <div className="flex">
          {Object.keys(data.days).map((key, index) => {
            return (
              <div key={index} className="w-[300px] relative mr-4">
                <h3 className="font-medium mb-1">
                  {moment(key, 'DD/MM/YYYY').format('dddd Do MMMM')}
                </h3>
                <div>
                  <label
                    htmlFor="service"
                    className="block font-medium text-gray-600 text-sm"
                  >
                    Time Slot
                  </label>
                  <select
                    defaultValue={selectedTimeSlot[data.user.name][key]}
                    className="px-2 py-2 block w-full border border-gray-300 rounded-md shadow-md"
                    required
                    onChange={(e) => {
                      selectedTimeSlot[data.user.name][key] = e.target.value
                    }}
                    id="service"
                  >
                    <option disabled value="0">
                      Select a Time
                    </option>
                    {data.days[key].map((slot) => {
                      return (
                        <option key={slot} value={slot}>
                          {moment(slot).format('HH:mm a')}
                        </option>
                      )
                    })}
                  </select>
                </div>
                <button
                  className="btn green mt-4"
                  onClick={() =>
                    addSession(
                      data.user,
                      key,
                      selectedTimeSlot[data.user.name][key]
                    )
                  }
                >
                  Add Session
                </button>
              </div>
            )
          })}
        </div>
      </div>
    )
  }

  return (
    <div>
      {showModal && (
        <Modal title={modalTitle} body={<ModalBody />} show={resetModal} />
      )}
      <div className="max-w-lg">
        <h3 className="font-bold text-lg mb-2">Book a Session</h3>

        <div className="mb-3">
          <label
            htmlFor="service"
            className="block font-medium text-gray-600 text-sm"
          >
            Service
          </label>
          <input
            type="text"
            disabled
            value={claimData.service.title}
            className="px-4 py-2 block w-full border border-gray-300 rounded-md shadow-md"
          />
        </div>
      </div>
      <div>
        <div>
          <div className="max-w-lg">
            <div className="mb-3">
              <label
                htmlFor="date"
                className="block font-medium text-gray-600 text-sm"
              >
                Week Start
              </label>
              <div className="mt-1 relative rounded-md">
                <input
                  required
                  type="date"
                  name="date"
                  id="date"
                  defaultValue={session.date}
                  onChange={(e) => {
                    setAvailabilitiesLoaded(false)
                    setSelectedPractitioner(0)
                    setShowAllAvailable(false)
                    session.date = e.target.value
                  }}
                  min={moment().format('YYYY-MM-DD')}
                  className="px-4 py-2 block w-full border border-gray-300 rounded-md shadow-md"
                />
              </div>
            </div>
            <div className=" w-full mr-2 flex items-center mb-2">
              <div className="relative mr-2">
                <input
                  type="checkbox"
                  id="get_all"
                  onChange={(e) => {
                    setAvailabilitiesLoaded(false)
                    setSelectedPractitioner(0)
                    setShowAllAvailable(false)
                    setSession({
                      date: session.date,
                      start: session.start,
                      end: session.end,
                      user: session.user,
                      allUsers: e.target.checked,
                    })
                  }}
                  placeholder="All Practitioners"
                  className="field"
                  checked={session.allUsers}
                />
              </div>
              <label
                htmlFor="get_all"
                className="block font-medium text-gray-600 text-sm"
              >
                All Practitioners
              </label>
            </div>

            <button className="btn" onClick={loadAvailability}>
              Load Availability
            </button>
          </div>
        </div>
        {availabilities.length > 0 && availabilitiesLoaded && (
          <div className="border-t border-slate-200 mt-4">
            <h3 className="font-bold text-lg mb-2 mt-4">
              Available Practitioners ({availabilities.length})
            </h3>
            <div className="flex flex-col">
              <div className=" w-full mr-2 flex items-center mb-2">
                <div className="relative mr-2">
                  <input
                    type="checkbox"
                    id="show_all"
                    onChange={(e) => {
                      setSelectedPractitioner(0)
                      setShowAllAvailable(e.target.checked)
                    }}
                    placeholder="Show all Available"
                    className="field"
                    checked={showAllAvailable}
                  />
                </div>
                <label
                  htmlFor="show_all"
                  className="block font-medium text-gray-600 text-sm"
                >
                  Show All Available Practitioners
                </label>
              </div>
              {!showAllAvailable && (
                <div className="w-[300px] relative mr-4">
                  <div className="mb-3">
                    <label
                      htmlFor="service"
                      className="block font-medium text-gray-600 text-sm"
                    >
                      Practitioner
                    </label>
                    <select
                      defaultValue={selectedPractitioner}
                      className="px-2 py-2 block w-full border border-gray-300 rounded-md shadow-md"
                      required
                      onChange={(e) => {
                        setSelectedPractitioner(JSON.parse(e.target.value))
                      }}
                      id="service"
                    >
                      <option disabled value="0">
                        Select a Practitioner
                      </option>
                      {availabilities.map((availability) => {
                        return (
                          <option
                            key={availability.user.id}
                            value={JSON.stringify(availability)}
                          >
                            {availability.user.name}
                          </option>
                        )
                      })}
                    </select>
                  </div>
                </div>
              )}

              {selectedPractitioner !== 0 && (
                <ShowAvailability
                  key={selectedPractitioner.user.id}
                  data={selectedPractitioner}
                  specificNeeds={claimData.specialities}
                />
              )}

              {showAllAvailable &&
                availabilities.map((availability) => {
                  return (
                    <ShowAvailability
                      key={availability.user.id}
                      data={availability}
                      specificNeeds={claimData.specialities}
                    />
                  )
                })}
            </div>
          </div>
        )}
        {availabilities.length <= 0 && availabilitiesLoaded && (
          <h3 className="font-bold text-lg mb-2 mt-4">
            No Available Practitioners
          </h3>
        )}
      </div>
    </div>
  )
}

export default BookSession
