import axios from 'axios'

export default axios.create({
  baseURL: process.env.REACT_APP_API,
  headers: {
    'X-Requested-With': 'XMLHttpRequest',
  },
})

export const axiosPrivate = axios.create({
  baseURL: process.env.REACT_APP_API,
  headers: {
    'X-Requested-With': 'XMLHttpRequest',
  },
  withCredentials: true,
})
