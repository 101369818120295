import React, { useRef, useState, useEffect } from 'react'
import useAuth from '../hooks/useAuth'
import { Link, useNavigate, useLocation } from 'react-router-dom'
import axios from '../api/axios'
const LOGIN_URL = '/brokers/login'

const Login = () => {
  const { setAuth } = useAuth()

  const navigate = useNavigate()
  const location = useLocation()
  const from = location.state?.from?.pathname || '/'

  const emailRef = useRef()
  const errRef = useRef()

  const [email, setEmail] = useState('')
  const [pwd, setPwd] = useState('')
  const [showPassword, setShowPassword] = useState(false);
  const [errMsg, setErrMsg] = useState('')

  useEffect(() => {
    emailRef.current.focus()
  }, [])

  useEffect(() => {
    setErrMsg('')
  }, [email, pwd])

  const handleSubmit = async (e) => {
    e.preventDefault()

    try {
      const response = await axios.post(LOGIN_URL, { email, password: pwd })
      // const token = response.data.result.token
      const user = response.data.result.user
      let id = user.id
      // setAuth({ email, pwd, user, token })
      localStorage.setItem('login', true)
      setEmail('')
      setPwd('')
      navigate('/2fa', { state: id })
    } catch (error) {
      if (!error?.response) {
        setErrMsg('No Server Response')
      } else if (error.response?.status === 400) {
        setErrMsg('Email or Password Missing.')
      } else if (error.response?.status === 401) {
        setErrMsg('Incorrect Email or Password entered.')
      } else {
        setErrMsg('Error Occured. Could not log you in.')
      }
      errRef.current.focus()
    }
  }

  // const togglePersist = () => {
  //   setPersist((prev) => !prev)
  // }

  // useEffect(() => {
  //   localStorage.setItem('persist', persist)
  // }, [persist])

  return (

    <div className="flex min-h-full flex-1 max-w-5xl mx-auto mt-16 font-montserrat">
        <div className="flex flex-1 flex-col justify-center px-4 py-12 sm:px-6 lg:flex-none">
          <div className="mx-auto w-full max-w-sm lg:w-96">
            <div>
              <h2 className="mt-8 text-2xl font-bold leading-9 tracking-tight text-gray-900">
                Sign in to your account
              </h2>
            </div>

            <p
              ref={errRef}
              className={errMsg ? 'errmsg' : 'offscreen'}
              aria-live="assertive"
            >
              {errMsg}
            </p>
            <div className="mt-10">
              <div>
                <form onSubmit={handleSubmit} className="space-y-6">
                  <div>
                    <div className="input-wrap">
                      <input
                        type="email"
                        ref={emailRef}
                        id="email"
                        onChange={(e) => setEmail(e.target.value)}
                        required
                        placeholder="Email"
                        className="field"
                      />
                      <label htmlFor="email">Email</label>
                    </div>
                  </div>

                  <div className="flex items-end">
                    <div className="input-wrap">
                        <input
                          type={showPassword ? 'text' : 'password'}
                          id="pwd"
                          onChange={(e) => setPwd(e.target.value)}
                          required
                          placeholder="Password"
                          className="field"
                        />
                        <label htmlFor="pwd">Password</label>
                    </div>
                  </div>

                  <div className="flex items-center justify-between">
                    <div className="flex items-center">
                      <input
                        id="remember-me"
                        name="remember-me"
                        type="checkbox"
                        className="h-4 w-4 rounded border-gray-300 text-sovereign focus:ring-sovereign"
                      />
                      <label htmlFor="remember-me" className="ml-3 block text-sm leading-6 text-gray-700">
                        Remember me
                      </label>
                    </div>

                    <div className="text-sm leading-6">
                      <div className="font-semibold text-sovereign hover:text-indigo-500">
                        <Link to="/forgot-password" className="hover:text-blue-800">
                          Forgot Password?
                        </Link>
                      </div>
                    </div>
                  </div>

                  <div className="flex w-full justify-center rounded-md bg-sovereign px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-blue-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
                      <input type="submit" value="Login" className="w-full cursor-pointer" />
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
  )
}

export default Login
